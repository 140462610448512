import React from "react";
import {
    GET_CUSTOM_TABLE_DATA_ROWS_AUDIT,
    GET_SELECT_GRID_COLUMN_XML,
} from "src/api-setup/api-endpoints";
import { POSTAPI, generateHeader } from "src/api-setup/api-helper";
import { ACTION_COLUM_OBJECT_DATA_ROWS } from "src/constants/constants";
import { transformDateTime } from "src/lib/date-time.util";
import { GridColumnDataMapper } from "src/utils/utils";

const CUSTOM_TABLE_TEXT = "customtabledatarows";

const excludeFields = {
    org_title_key: true,
    db_role_key: true,
    rate_table_tier_key: true,
    rule_config_key: true,
    user_key: true,
    kpi_mstr_key: true,
    specialty_mstr_key: true,
    custom_table_mstr_key: true,
    rate_table_mstr_key: true,
    comp_plan_mstr_key: true,
    kpi_type_mstr_key: true,
    job_mstr_key: true,
    job_type_mstr_key: true,
    system_unit_mstr_key: true,
    event_type_mstr_key: true,
    business_group_mstr_key: true,
    result_name_mstr_key: true,
    formula_mstr_key: true,
    etl_file_mstr_key: true,
    provider_mstr_key: true,
    etl_table_mstr_key: true,
    org_position_key: true,
};

export default function AuditLogs() {
    const [columns, setColumns] = React.useState([]);
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [isSearchClick, setSearchClick] = React.useState(false);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [dataCount, setDataCount] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);

    const [dataRowsColumns, setDataRowsColumns] = React.useState([]);
    const [noDataRowsFound, setNoDataRowsFound] = React.useState(false);
    const [isDataRowSearchClick, setDataRowSearchClick] = React.useState(false);
    const [initialDataRowsLoading, setDataRowsLoadingState] =
        React.useState(false);
    const [dataRowsDataCount, setDataRowsDataCount] = React.useState(0);
    const [dataRowsData, setDataRowsData] = React.useState([]);
    const [isAPIDataRowsCalling, setAPIDataRowsCallingState] =
        React.useState(false);

    const getListDataAsync = (api, reqBody, dispatch, module) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(false, api, null, generateHeader(), reqBody, dispatch).then(
                (res) => {
                    if (res.status) {
                        if (
                            module === "compplan" ||
                            module === "jobconfiguration"
                        ) {
                            setSearchClick(false);
                            setLoadingState(true);
                            setAPICallingState(true);
                            setNoDataFound(false);
                            setAPICallingState(false);
                            let resData = JSON.parse(
                                JSON.stringify(res.data.row_result)
                            );
                            setData(resData);
                            setDataCount(
                                res.data.out_parameters.out_total_rows || 0
                            );
                        } else if (
                            res.data.out_parameters.out_ret_code === 0 ||
                            res.data.out_parameters.out_ret_code === 2 ||
                            res.data.out_parameters.out_ret_code === 3
                        ) {
                            /*
                            if (module === CUSTOM_TABLE_TEXT && isSearchClick) {
                                res.data.row_result.sort(
                                    (a, b) => b.action_date - a.action_date
                                );
                            }
                                */
                            if (res.data.row_result.length === 0) {
                                setLoadingState(true);
                                setAPICallingState(false);
                                setData(res.data.row_result);
                                setDataCount(
                                    res.data.out_parameters.out_total_rows
                                );
                                if (
                                    res.data.out_parameters.out_columns_xml !==
                                    ""
                                ) {
                                    if (columns.length === 0) {
                                        let columnData = GridColumnDataMapper(
                                            res.data.out_parameters
                                                .out_columns_xml
                                        );
                                        columnData.forEach((cItem) => {
                                            cItem.show =
                                                !excludeFields[cItem.field];
                                        });
                                        if (module === CUSTOM_TABLE_TEXT) {
                                            columnData.unshift(
                                                ACTION_COLUM_OBJECT_DATA_ROWS
                                            );
                                        }
                                        setColumns(
                                            JSON.parse(
                                                JSON.stringify(columnData)
                                            )
                                        );
                                    }
                                }
                                setSearchClick(false);
                                setNoDataFound(true);
                                resolve(true);
                            } else {
                                setLoadingState(true);
                                setSearchClick(false);
                                let columnData = [];
                                if (columns.length === 0) {
                                    columnData = GridColumnDataMapper(
                                        res.data.out_parameters.out_columns_xml
                                    );
                                    columnData.forEach((cItem) => {
                                        cItem.show =
                                            !excludeFields[cItem.field];
                                    });
                                    if (module === CUSTOM_TABLE_TEXT) {
                                        columnData.unshift(
                                            ACTION_COLUM_OBJECT_DATA_ROWS
                                        );
                                    }
                                    setColumns(
                                        JSON.parse(JSON.stringify(columnData))
                                    );
                                }

                                let resData = JSON.parse(
                                    JSON.stringify(res.data.row_result)
                                );
                                setLoadingState(true);
                                setAPICallingState(true);
                                setNoDataFound(false);
                                setAPICallingState(false);
                                setData(resData);
                                setDataCount(
                                    res.data.out_parameters.out_total_rows
                                );
                                resolve(true);
                            }
                        } else {
                            setNoDataFound(true);
                            setAPICallingState(false);
                        }
                    }
                }
            );
        }).then((res) => {
            return res;
        });
    };

    const getColumListXML = (reqData, reqBody, api, module, dispatch) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_SELECT_GRID_COLUMN_XML,
                null,
                generateHeader(),
                reqData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setNoDataFound(true);
                    resolve(false);
                } else {
                    const columData = GridColumnDataMapper(
                        res.data.out_parameters.out_columns_xml
                    );
                    columData.map((cItem) => {
                        cItem.show =
                            cItem.field === "comp_plan_mstr_key" ? false : true;
                        return 0;
                    });
                    if (columns.length === 0) {
                        setColumns(JSON.parse(JSON.stringify(columData)));
                    }
                    resolve(true);
                }
            });
        }).then((res) => {
            return res;
        });
    };

    const getExportDataAsync = (api, reqData, dispatch, moduleData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(false, api, null, generateHeader(), reqData, dispatch).then(
                (res) => {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setNoDataFound(true);
                        resolve(false);
                    } else {
                        if (res.status) {
                            if (
                                moduleData === "compplan" ||
                                moduleData === "jobconfiguration"
                            ) {
                                let resData = JSON.parse(
                                    JSON.stringify(res.data.row_result)
                                );
                                resolve(resData);
                            } else if (
                                res.data.out_parameters.out_ret_code === 0 ||
                                res.data.out_parameters.out_ret_code === 2 ||
                                res.data.out_parameters_out_ret_code === 3
                            ) {
                                let resData = JSON.parse(
                                    JSON.stringify(res.data.row_result)
                                );
                                if (
                                    (moduleData =
                                        CUSTOM_TABLE_TEXT && isSearchClick)
                                ) {
                                    resData.sort(
                                        (a, b) => b.action_date - a.action_date
                                    );
                                }
                                let columnData = GridColumnDataMapper(
                                    res.data.out_parameters.out_columns_xml
                                );
                                columnData.forEach((cItem) => {
                                    cItem.show = !excludeFields[cItem.field];
                                });

                                resData = JSON.parse(
                                    JSON.stringify(res.data.row_result)
                                );

                                let colValue =
                                    columnData.length > 0
                                        ? columnData
                                        : columns;
                                colValue.map((item) => {
                                    if (item.type === "date") {
                                        resData.forEach((rItem) => {
                                            if (
                                                rItem[item.field] !== null &&
                                                rItem[item.field] !==
                                                    "0000-00-00 00:00:00"
                                            ) {
                                                rItem[item.field] =
                                                    transformDateTime({
                                                        date: rItem[item.field],
                                                        format: "MM/DD/YYYY",
                                                        includeTime: true,
                                                    });
                                            }
                                            if (
                                                rItem[item.field] ===
                                                "0000-00-00 00:00:00"
                                            ) {
                                                rItem[item.field] = "";
                                            }
                                        });
                                    }
                                    if (item.type === "boolean") {
                                        resData.forEach((rItem) => {
                                            rItem[item.field] = rItem[
                                                item.field
                                            ]
                                                ? "true"
                                                : "false";
                                        });
                                    }
                                    return 0;
                                });

                                resData.forEach((xItem) => {
                                    if (xItem.custom_table_type) {
                                        xItem.custom_table_type =
                                            xItem.custom_table_type === "S"
                                                ? "Standard"
                                                : "Hierarchical";
                                    }
                                });
                                resolve(resData);
                            }
                        }
                    }
                }
            );
        }).then((res) => {
            return res;
        });
    };

    const getCustomTableDataRowsAudit = (reqBody, dispatch) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_CUSTOM_TABLE_DATA_ROWS_AUDIT,
                null,
                generateHeader(),
                reqBody,
                dispatch
            ).then((res) => {
                if (res.status) {
                    setDataRowsLoadingState(true);
                    let columnData = [];
                    if (columns.length === 0) {
                        columnData = GridColumnDataMapper(
                            res.data.out_parameters.out_columns_xml
                        );

                        columnData.forEach((cItem) => {
                            cItem.show = !excludeFields[cItem.field];
                        });

                        setDataRowsColumns(
                            JSON.parse(JSON.stringify(columnData))
                        );
                    }

                    let resData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    resData.sort((a, b) => b.action_date - a.action_date);
                    if (columnData.length > 0) {
                        let colValue =
                            columnData.length > 0
                                ? columnData
                                : dataRowsColumns;
                        colValue.map((item) => {
                            if (item.type === "date") {
                                resData.forEach((rItem) => {
                                    if (
                                        rItem[item.field] !== null &&
                                        rItem[item.field] !==
                                            "0000-00-00 00:00:00"
                                    ) {
                                        rItem[item.field] = transformDateTime({
                                            date: rItem[item.field],
                                            format: "MM/DD/YYYY",
                                            includeTime: true,
                                        });
                                    }

                                    if (
                                        rItem[item.field] ===
                                        "0000-00-00 00:00:00"
                                    ) {
                                        rItem[item.field] = "";
                                    }
                                });
                            }
                            if (item.type === "boolean") {
                                resData.forEach((rItem) => {
                                    rItem[item.field] = rItem[item.field]
                                        ? "true"
                                        : "false";
                                });
                            }
                            return 0;
                        });
                    }
                    setDataRowsLoadingState(true);
                    setAPIDataRowsCallingState(true);
                    setNoDataRowsFound(false);
                    setAPIDataRowsCallingState(false);
                    setDataRowsData(resData);
                    setDataRowSearchClick(false);
                    setDataRowsDataCount(
                        res.data.out_parameters.out_total_rows
                    );
                    resolve(resData);
                } else {
                    setNoDataRowsFound(true);
                    setAPIDataRowsCallingState(false);
                }
            });
        }).then((res) => {
            return res;
        });
    };

    const getCustomRowExportDataAsync = (reqData, dispatch, module) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_CUSTOM_TABLE_DATA_ROWS_AUDIT,
                null,
                generateHeader(),
                reqData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setNoDataFound(true);
                    resolve(false);
                } else {
                    if (res.status) {
                        if (
                            res.data.out_parameters.out_ret_code === 0 ||
                            res.data.out_parameters.out_ret_code === 2 ||
                            res.data.out_parameters_out_ret_code === 3
                        ) {
                            let resData = JSON.parse(
                                JSON.stringify(res.data.row_result)
                            );
                            if ((module = CUSTOM_TABLE_TEXT && isSearchClick)) {
                                resData.sort(
                                    (a, b) => b.action_date - a.action_date
                                );
                            }
                            let columnData = GridColumnDataMapper(
                                res.data.out_parameters.out_columns_xml
                            );
                            columnData.forEach((cItem) => {
                                cItem.show = !excludeFields[cItem.field];
                            });

                            if (columnData.length > 0) {
                                let colValue =
                                    columnData.length > 0
                                        ? columnData
                                        : dataRowsColumns;
                                colValue.map((item) => {
                                    if (item.type === "date") {
                                        resData.forEach((rItem) => {
                                            if (
                                                rItem[item.field] !== null &&
                                                rItem[item.field] !==
                                                    "0000-00-00 00:00:00"
                                            ) {
                                                rItem[item.field] =
                                                    transformDateTime({
                                                        date: rItem[item.field],
                                                        format: "MM/DD/YYYY",
                                                        includeTime: true,
                                                    });
                                            }

                                            if (
                                                rItem[item.field] ===
                                                "0000-00-00 00:00:00"
                                            ) {
                                                rItem[item.field] = "";
                                            }
                                        });
                                    }
                                    if (item.type === "boolean") {
                                        resData.forEach((rItem) => {
                                            rItem[item.field] = rItem[
                                                item.field
                                            ]
                                                ? "true"
                                                : "false";
                                        });
                                    }
                                    return 0;
                                });
                            }
                            resolve(resData);
                        }
                    }
                }
            });
        }).then((res) => {
            return res;
        });
    };
    return [
        {
            columns,
            setColumns,
            data,
            setData,
            dataCount,
            initialLoading,
            noDataFound,
            isAPICalling,
            setAPICallingState,
            isSearchClick,
            setSearchClick,

            setDataRowsDataCount,
            setDataRowsData,
            setDataRowsColumns,
            dataRowsColumns,
            dataRowsDataCount,
            dataRowsData,
            setNoDataRowsFound,
            setAPIDataRowsCallingState,
            initialDataRowsLoading,
            isAPIDataRowsCalling,
            noDataRowsFound,
            setDataRowSearchClick,
            isDataRowSearchClick,
        },
        {
            getColumListXML,
            getListDataAsync,
            getExportDataAsync,
            getCustomTableDataRowsAudit,
            getCustomRowExportDataAsync,
        },
    ];
}
