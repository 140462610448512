import { DatePicker, ToggleButton } from "@progress/kendo-react-dateinputs";
import {
    Checkbox,
    Input,
    NumericTextBox,
    TextArea,
} from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React from "react";
import CustomDropdown from "src/components/customDropdown";
import {
    AddAccessContext,
    AddETLFieldsContext,
    CancelClickContext,
    DataListContext,
    ErrorContext,
    FormFieldsContext,
} from "./addProviderPosition";
import {
    EditFormFieldsContext,
    EditErrorContext,
    EditDataListContext,
    ETLFieldsContext,
    EffStartDateContext,
    StandAloneContext,
    AccessContext,
} from "./updateProviderPosition";
import {
    applyFormat,
    changeCancelBtnState,
    changeUpdateBtnState,
} from "src/utils/utils";
import AddSpecialty from "./addSpecialty";
import { SearchModule } from "./searchModule";
import { useDispatch } from "react-redux";
import UploadFile from "./uploadFile";
import { useHistory } from "react-router-dom";
import { getUserCredential } from "src/lib/cookies";
import { DialogComponent } from "src/components/dialog";
import { GetSPECIALTYAPI } from "src/lib/common-service";
import { transformDateTime } from "src/lib/date-time.util";

const CustomToggleButton = (props) => {
    return (
        <Tooltip
            position="bottom"
            anchorElement="target"
            style={{
                paddingTop: 10,
            }}
        >
            <ToggleButton {...props} title="Calendar">
                <span
                    className="k-icon k-font-icon k-i-calendar"
                    title="Calendar"
                />
            </ToggleButton>
        </Tooltip>
    );
};
const ProviderFields = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const cookiesData = getUserCredential();
    const [errorObj, setErrorObj] = React.useContext(
        props?.dataItem.isEditMode ? EditErrorContext : ErrorContext
    );
    const [formFields, setFormFields] = React.useContext(
        props?.dataItem.isEditMode ? EditFormFieldsContext : FormFieldsContext
    );
    const [disabledFields] = React.useContext(
        props?.dataItem?.isEditMode ? ETLFieldsContext : AddETLFieldsContext
    );
    const [selectedState, setSelectedState] = React.useState({
        groupData: {},
        userData: {},
    });
    const [showPopup, setShowPopup] = React.useState({
        showSpecialtyPopup: false,
        showGroupFilterPopup: false,
        showUserFilterPopup: false,
    });
    const [dataList, setDataList] = React.useContext(
        props?.dataItem.isEditMode ? EditDataListContext : DataListContext
    );
    const [isSpecialtySave, setSpecialtySave] = React.useState(false);
    const [openUploadPopup, setOpenUploadPopup] = React.useState(false);
    const [, setUpdateEffDate] = React.useContext(EffStartDateContext);
    const [standAlone] = React.useContext(StandAloneContext);
    const [isCancelClick, setCancelClick] =
        React.useContext(CancelClickContext);
    const [accessControl] = React.useContext(
        props?.dataItem.isEditMode ? AccessContext : AddAccessContext
    );
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Do you want to unlink the document?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });

    let { dataItem, selected, ...others } = props;

    let fItem = dataItem;

    let jsonString = fItem?.dropdown_control;
    let jsonObject = jsonString ? JSON.parse(jsonString) : "";

    React.useEffect(() => {
        if (isSpecialtySave) {
            getSpecialtyList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSpecialtySave]);

    React.useEffect(() => {
        if (isCancelClick) {
            setCancelClick(false);
            setSelectedState((prevState) => ({
                ...prevState,
                groupData: {},
                userData: {},
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCancelClick]);

    const getSpecialtyList = async () => {
        let dataObjValue = {
            in_user_key: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let resSpecialty = await GetSPECIALTYAPI(
            dataObjValue,
            history,
            dispatch
        );
        if (resSpecialty) {
            setSpecialtySave(false);
            let tempArray = [];
            resSpecialty.map((item) => {
                let obj = {};

                obj.text = item.specialty_name;
                obj.id = item.specialty_mstr_key;

                tempArray.push(obj);

                return 0;
            });
            setDataList((preVState) => ({
                ...preVState,
                specialtyList: tempArray,
            }));
            setFormFields((preVState) => ({
                ...preVState,
                specialtyList: tempArray,
                vw_org_position_specialty_name: tempArray[0],
            }));
        }
    };

    const handleNumberFieldChange = (e, fItem) => {
        let name = "";
        if (fItem.custom_fld_mstr_key !== null) {
            name =
                fItem.filter_table_name +
                "customFields" +
                fItem.custom_fld_mstr_key +
                fItem.custom_fld_col_mstr_key +
                "_" +
                fItem.custom_fld_data_type.toLowerCase();
        } else {
            name = fItem.filter_table_name + "_" + fItem.filter_column_name;
        }
        setFormFields({
            ...formFields,
            [name]:
                typeof e?.target?.value === "number"
                    ? e?.target?.value === ""
                        ? ""
                        : e?.target?.value < 0
                        ? "0.00"
                        : Number(e?.target?.value)
                    : "",
        });
        setErrorObj({
            ...errorObj,
            [fItem.filter_label]: "",
        });
        changeUpdateBtnState("enable");
        changeCancelBtnState("enable");
    };

    const handleTextAreaChange = (e, fItem) => {
        let name = "";
        if (fItem.custom_fld_mstr_key !== null) {
            name =
                fItem.filter_table_name +
                "customFields" +
                fItem.custom_fld_mstr_key +
                fItem.custom_fld_col_mstr_key +
                "_" +
                fItem.custom_fld_data_type.toLowerCase();
        } else {
            name = fItem.filter_table_name + "_" + fItem.filter_column_name;
        }
        setFormFields({
            ...formFields,
            [name]: e.value,
        });
        setErrorObj({
            ...errorObj,
            [fItem.filter_label]: "",
        });
        if (fItem.filter_column_name === "eff_start_date") {
            setUpdateEffDate(true);
            changeUpdateBtnState("enable");
            changeCancelBtnState("enable");
        } else {
            changeUpdateBtnState("enable");
            changeCancelBtnState("enable");
        }
    };

    const handleFieldChange = (e, fItem) => {
        let name = "";
        if (fItem.custom_fld_mstr_key !== null) {
            name =
                fItem.filter_table_name +
                "customFields" +
                fItem.custom_fld_mstr_key +
                fItem.custom_fld_col_mstr_key +
                "_" +
                fItem.custom_fld_data_type.toLowerCase();
        } else {
            name = fItem.filter_table_name + "_" + fItem.filter_column_name;
        }
        setFormFields({
            ...formFields,
            [name]: e.value ? e.value : e.target.value,
        });
        setErrorObj({
            ...errorObj,
            [fItem.filter_label]: "",
        });
        if (fItem.filter_column_name === "eff_start_date") {
            setUpdateEffDate(true);
            changeUpdateBtnState("enable");
            changeCancelBtnState("enable");
        } else {
            changeUpdateBtnState("enable");
            changeCancelBtnState("enable");
        }
    };

    const handleCheckBoxFieldChange = (e, fItem) => {
        let name = "";
        if (fItem.custom_fld_mstr_key !== null) {
            name =
                fItem.filter_table_name +
                "customFields" +
                fItem.custom_fld_mstr_key +
                fItem.custom_fld_col_mstr_key +
                "_" +
                fItem.custom_fld_data_type.toLowerCase();
        } else {
            name = fItem.filter_table_name + "_" + fItem.filter_column_name;
        }
        setFormFields({
            ...formFields,
            [name]: e.target.value === true ? "1" : "0",
        });
        setErrorObj({
            ...errorObj,
            [fItem.filter_label]: "",
        });
        changeUpdateBtnState("enable");
        changeCancelBtnState("enable");
    };

    const getDropdownValue = (fItem, dataList) => {
        let value = "";
        if (fItem.custom_fld_mstr_key !== null) {
            value =
                fItem.filter_table_name +
                "customFields" +
                fItem.custom_fld_mstr_key +
                fItem.custom_fld_col_mstr_key +
                "_" +
                fItem.custom_fld_data_type.toLowerCase();
        } else {
            value = fItem.filter_table_name + "_" + fItem.filter_column_name;
        }
        value = formFields[value]
            ? dataList?.find((c) => c.text === formFields[value].text)
            : "";

        return value;
    };

    const handleDropdownChange = (e, fItem) => {
        let name = "";
        if (fItem.custom_fld_mstr_key !== null) {
            name =
                fItem.filter_table_name +
                "customFields" +
                fItem.custom_fld_mstr_key +
                fItem.custom_fld_col_mstr_key +
                "_" +
                fItem.custom_fld_data_type.toLowerCase();
        } else {
            name = fItem.filter_table_name + "_" + fItem.filter_column_name;
        }
        setFormFields({
            ...formFields,
            [name]: e,
        });
        setErrorObj({
            ...errorObj,
            [fItem.filter_label]: "",
        });
        changeUpdateBtnState("enable");
        changeCancelBtnState("enable");
    };

    const handleSearch = (module) => {
        switch (module) {
            case "user_filter":
                setShowPopup({
                    ...showPopup,
                    showUserFilterPopup: true,
                });
                break;
            case "group_filter":
                setShowPopup({
                    ...showPopup,
                    showGroupFilterPopup: true,
                });
                break;
            default:
                break;
        }
    };

    const onAddSpecialtyClick = () => {
        setShowPopup({
            ...showPopup,
            showSpecialtyPopup: true,
        });
    };

    const onCancelConfirm = () => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
    };

    const getFields = (item) => {
        let disabled = false;
        if (standAlone?.provider) {
            disabled = false;
        } else {
            if (cookiesData.out_tenant_id === "osfhealthcr1") {
                if (
                    disabledFields &&
                    disabledFields.length &&
                    disabledFields.includes(item.filter_column_name) &&
                    props?.dataItem.isEditMode
                ) {
                    disabled = true;
                }
                if (
                    disabledFields &&
                    disabledFields.length &&
                    item.filter_column_name === "ACES_Name"
                ) {
                    disabled = true;
                }
            } else {
                if (
                    disabledFields &&
                    disabledFields.length &&
                    disabledFields.includes(item.filter_column_name) &&
                    props?.dataItem.isEditMode
                ) {
                    disabled = true;
                }
            }
        }
        return disabled;
    };

    const getTitleNameDisabled = () => {
        let disabled = false;
        if (standAlone?.provider) {
            disabled = false;
        } else {
            disabled =
                disabledFields &&
                disabledFields.length &&
                disabledFields.includes("title_name") &&
                props?.dataItem.isEditMode;
        }
        return disabled;
    };

    const disabledEffDate = (fItem) => {
        let isDisabled = true;
        if (standAlone?.provider) {
            isDisabled = false;
        } else {
            isDisabled =
                fItem.filter_column_name === "eff_start_date" &&
                fItem.filter_table_name === "vw_provider_master";
        }
        return accessControl?.writeAccess === 0 ? true : isDisabled;
    };

    return (
        <>
            <li
                {...others}
                selected={props.selected}
                onDragOver={props.onDragOver}
                className={props.className}
                draggable={
                    accessControl?.writeAccess === 1
                        ? fItem.filter_column_datatype === "doc" ||
                          fItem.filter_column_name === "eff_start_date" ||
                          fItem.fields.length === 1
                            ? false
                            : true
                        : false
                }
                onDragStart={props.onDragStart}
            >
                <div className="fullwidthelm">
                    <>
                        {fItem.filter_column_name === "comp_plans" &&
                        formFields[fItem.filter_column_name] ? (
                            <>
                                <label className="provider-elm">
                                    <>
                                        {`${fItem.filter_label}`}
                                        {standAlone?.provider
                                            ? fItem.filter_table_name ===
                                              "vw_provider_master"
                                                ? `${
                                                      (fItem.is_nullable ===
                                                          0 &&
                                                          fItem.filter_column_datatype !==
                                                              "boolean") ||
                                                      jsonObject?.required ===
                                                          "true"
                                                          ? "*"
                                                          : ""
                                                  }`
                                                : ""
                                            : `${
                                                  (fItem.is_nullable === 0 &&
                                                      fItem.filter_column_datatype !==
                                                          "boolean") ||
                                                  jsonObject?.required ===
                                                      "true"
                                                      ? "*"
                                                      : ""
                                              }`}
                                        {!(
                                            fItem.filter_column_name ===
                                                "eff_start_date" ||
                                            fItem.filter_column_datatype ===
                                                "doc" ||
                                            fItem.filter_column_name ===
                                                "comp_plans"
                                        ) && fItem.fields.length > 1 ? (
                                            accessControl?.writeAccess === 1 ? (
                                                <i className="fa fa-grip-lines"></i>
                                            ) : (
                                                false
                                            )
                                        ) : (
                                            false
                                        )}
                                    </>
                                </label>
                            </>
                        ) : (
                            false
                        )}

                        {fItem.filter_column_name !== "comp_plans" ? (
                            <label
                                className={
                                    fItem.filter_column_name !==
                                    "eff_start_date"
                                        ? "provider-elm"
                                        : ""
                                }
                            >
                                {`${fItem.filter_label}`}
                                {standAlone?.provider
                                    ? fItem.filter_table_name ===
                                      "vw_provider_master"
                                        ? `${
                                              (fItem.is_nullable === 0 &&
                                                  fItem.filter_column_datatype !==
                                                      "boolean") ||
                                              jsonObject?.required === "true"
                                                  ? "*"
                                                  : ""
                                          }`
                                        : ""
                                    : `${
                                          (fItem.is_nullable === 0 &&
                                              fItem.filter_column_datatype !==
                                                  "boolean") ||
                                          jsonObject?.required === "true"
                                              ? "*"
                                              : ""
                                      }`}
                                {!(
                                    fItem.filter_column_name ===
                                        "eff_start_date" ||
                                    fItem.filter_column_datatype === "doc" ||
                                    fItem.filter_column_name === "comp_plans"
                                ) && fItem.fields.length > 1 ? (
                                    accessControl?.writeAccess === 1 ? (
                                        <i className="fa fa-grip-lines"></i>
                                    ) : (
                                        false
                                    )
                                ) : (
                                    false
                                )}
                            </label>
                        ) : (
                            false
                        )}
                    </>
                    {fItem.filter_column_datatype === "date" ? (
                        <>
                            <DatePicker
                                value={(() => {
                                    let value = "";
                                    let key = "";
                                    if (fItem.custom_fld_mstr_key !== null) {
                                        key = `${
                                            fItem.filter_table_name
                                        }customFields${
                                            fItem.custom_fld_mstr_key
                                        }${
                                            fItem.custom_fld_col_mstr_key
                                        }_${fItem.custom_fld_data_type.toLowerCase()}`;
                                        value = formFields[key] || "";
                                    } else {
                                        key = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                        value = formFields[key] || "";
                                    }
                                    if (props?.dataItem.isEditMode) {
                                        if (formFields[key] !== undefined) {
                                            value = formFields[key] || "";
                                        } else {
                                            value =
                                                fItem.filter_table_name ===
                                                    "vw_provider_master" &&
                                                standAlone.provider &&
                                                fItem.filter_column_name ===
                                                    "eff_start_date"
                                                    ? formFields[
                                                          "provider_eff_start_date"
                                                      ]
                                                    : formFields[
                                                          fItem
                                                              .filter_column_name
                                                      ] || "";
                                        }
                                    }
                                    let dateValue = "";
                                    if (formFields[value]) {
                                        dateValue =
                                            typeof formFields[value] ===
                                            "object"
                                                ? formFields[value]
                                                : transformDateTime({
                                                      date: formFields[value],
                                                      includeTime: true,
                                                      defaultTime: true,
                                                      returnDate: true,
                                                  });
                                    } else if (value) {
                                        dateValue =
                                            typeof value === "object"
                                                ? value
                                                : transformDateTime({
                                                      date: value,
                                                      includeTime: true,
                                                      defaultTime: true,
                                                      returnDate: true,
                                                  });
                                    }
                                    return dateValue;
                                })()}
                                name="start_date"
                                format="yyyy-MM-dd"
                                className="provider-elm calendariconprovider"
                                onChange={(e) => {
                                    handleFieldChange(e, fItem);
                                }}
                                disabled={disabledEffDate(fItem)}
                                formatPlaceholder={{
                                    year: "yyyy",
                                    month: "mm",
                                    day: "dd",
                                }}
                                placeholder="yyyy-mm-dd"
                                toggleButton={CustomToggleButton}
                            />
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </>
                    ) : fItem.filter_column_datatype === "number" ? (
                        <>
                            <NumericTextBox
                                value={(() => {
                                    let value = "";
                                    let key = "";
                                    if (fItem.custom_fld_mstr_key !== null) {
                                        key = `${
                                            fItem.filter_table_name
                                        }customFields${
                                            fItem.custom_fld_mstr_key
                                        }${
                                            fItem.custom_fld_col_mstr_key
                                        }_${fItem.custom_fld_data_type.toLowerCase()}`;
                                    } else {
                                        key = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                    }

                                    value =
                                        formFields[key] === 0
                                            ? 0
                                            : formFields[key] || "";

                                    if (props?.dataItem.isEditMode) {
                                        if (formFields[key] !== undefined) {
                                            value =
                                                formFields[key] === 0
                                                    ? 0
                                                    : formFields[key] || "";
                                        } else {
                                            value =
                                                formFields[
                                                    fItem.filter_column_name
                                                ] === 0
                                                    ? 0
                                                    : formFields[
                                                          fItem
                                                              .filter_column_name
                                                      ] || "";
                                        }
                                    }

                                    return applyFormat(value);
                                })()}
                                className="provider-elm"
                                format={"#.########"} // Allows up to 8 decimal places
                                onChange={(e) => {
                                    if (
                                        e.target.value === "" ||
                                        e.target.value === null
                                    ) {
                                        handleNumberFieldChange("", fItem);
                                    } else {
                                        handleNumberFieldChange(e, fItem);
                                    }
                                }}
                                disabled={accessControl?.writeAccess === 0}
                            />
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </>
                    ) : fItem.filter_column_datatype === "string" &&
                      fItem.dropdown_control === "0" ? (
                        <>
                            <Input
                                type="text"
                                className="provider-elm"
                                value={(() => {
                                    let value = "";
                                    let key = "";
                                    if (fItem.custom_fld_mstr_key !== null) {
                                        key = `${
                                            fItem.filter_table_name
                                        }customFields${
                                            fItem.custom_fld_mstr_key
                                        }${
                                            fItem.custom_fld_col_mstr_key
                                        }_${fItem.custom_fld_data_type.toLowerCase()}`;
                                        value = formFields[key] || "";
                                    } else {
                                        key = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                        value = formFields[key] || "";
                                    }
                                    if (props?.dataItem.isEditMode) {
                                        if (formFields[key] !== undefined) {
                                            value = formFields[key] || "";
                                        } else {
                                            value =
                                                formFields[
                                                    fItem.filter_column_name
                                                ] || "";
                                        }
                                    }
                                    return formFields[value] || value;
                                })()}
                                onChange={(e) => {
                                    handleFieldChange(e, fItem);
                                }}
                                maxLength={fItem.validation_max}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : getFields(fItem)
                                }
                            />
                            {fItem?.is_nullable === 0 && (
                                <p className="error-msg">
                                    {errorObj[fItem.filter_label]}
                                </p>
                            )}
                        </>
                    ) : jsonObject.input_type === "textarea" ? (
                        <>
                            <TextArea
                                rows={4}
                                className="provider-elm"
                                value={(() => {
                                    let value = "";
                                    let key = "";
                                    if (fItem.custom_fld_mstr_key !== null) {
                                        key = `${
                                            fItem.filter_table_name
                                        }customFields${
                                            fItem.custom_fld_mstr_key
                                        }${
                                            fItem.custom_fld_col_mstr_key
                                        }_${fItem.custom_fld_data_type.toLowerCase()}`;
                                        value = formFields[key] || "";
                                    } else {
                                        key = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                        value = formFields[key] || "";
                                    }
                                    if (props?.dataItem.isEditMode) {
                                        if (formFields[key] !== undefined) {
                                            value = formFields[key] || "";
                                        } else {
                                            value =
                                                formFields[
                                                    fItem.filter_column_name
                                                ] || "";
                                        }
                                    }
                                    return formFields[value] || value;
                                })()}
                                onChange={(e) => {
                                    handleTextAreaChange(e, fItem);
                                }}
                                maxLength={fItem.validation_max}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : false
                                }
                            />
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </>
                    ) : jsonObject.input_type === "select" &&
                      jsonObject.object === "unitType" ? (
                        <>
                            <CustomDropdown
                                data={formFields.unitTypeDataList}
                                textField="text"
                                dataItemKey="id"
                                className="provider-elm updateprodrpdwntype "
                                onOpen={(data) => {
                                    setFormFields({
                                        ...formFields,
                                        unitTypeDataList:
                                            dataList.unitTypeDataList,
                                    });
                                }}
                                valueData={getDropdownValue(
                                    fItem,
                                    dataList.unitTypeDataList
                                )}
                                onChange={(e, name) => {
                                    handleDropdownChange(e, fItem);
                                }}
                                filterable={true}
                                onFilterChange={(dataValue) => {
                                    setFormFields({
                                        ...formFields,
                                        unitTypeDataList: dataValue,
                                    });
                                }}
                                autoClose={false}
                                autoBind={true}
                                isSelectALl={true}
                                dataList={dataList.unitTypeDataList}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : false
                                }
                            />
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </>
                    ) : jsonObject.input_type === "select" &&
                      jsonObject.object === "positionType" ? (
                        <>
                            <CustomDropdown
                                data={formFields.positionTypeList}
                                textField="text"
                                dataItemKey="id"
                                className="provider-elm updateprodrpdwntype "
                                onOpen={(data) => {
                                    setFormFields({
                                        ...formFields,
                                        positionTypeList:
                                            dataList.positionTypeList,
                                    });
                                }}
                                valueData={getDropdownValue(
                                    fItem,
                                    dataList.positionTypeList
                                )}
                                onChange={(e, name) => {
                                    handleDropdownChange(e, fItem);
                                }}
                                filterable={true}
                                onFilterChange={(dataValue) => {
                                    setFormFields({
                                        ...formFields,
                                        positionTypeList: dataValue,
                                    });
                                }}
                                autoClose={false}
                                autoBind={true}
                                isSelectALl={true}
                                dataList={dataList.positionTypeList}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : false
                                }
                            />
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </>
                    ) : jsonObject.input_type === "select" &&
                      jsonObject.object === "specialty" ? (
                        <div className="pluswidget updateprodrpdwn ">
                            <CustomDropdown
                                data={formFields.specialtyList}
                                textField="text"
                                className="provider-elm "
                                dataItemKey="id"
                                onOpen={(data) => {
                                    setFormFields({
                                        ...formFields,
                                        specialtyList: dataList.specialtyList,
                                    });
                                }}
                                valueData={getDropdownValue(
                                    fItem,
                                    dataList.specialtyList
                                )}
                                onChange={(e, name) => {
                                    handleDropdownChange(e, fItem);
                                }}
                                filterable={true}
                                onFilterChange={(dataValue) => {
                                    setFormFields({
                                        ...formFields,
                                        specialtyList: dataValue,
                                    });
                                }}
                                autoClose={false}
                                autoBind={true}
                                isSelectALl={true}
                                dataList={dataList.specialtyList}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : false
                                }
                            />
                            {accessControl?.writeAccess === 1 ? (
                                <i
                                    className="fas fa-plus"
                                    onClick={onAddSpecialtyClick}
                                ></i>
                            ) : (
                                false
                            )}
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </div>
                    ) : jsonObject.input_type === "text" &&
                      jsonObject.search_id === "group_filter" ? (
                        <div className="input searchiconprovposition">
                            <Input
                                type="text"
                                className="provider-elm "
                                value={(() => {
                                    let value = "";
                                    if (props.dataItem.isEditMode) {
                                        value = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                    } else {
                                        value =
                                            formFields[
                                                `${fItem.filter_table_name}_${fItem.filter_column_name}`
                                            ] || "";
                                    }
                                    if (formFields[value]) {
                                        value = formFields[value];
                                    } else {
                                        value = fItem.filter_column_name;
                                        value = formFields[value] || "";
                                    }
                                    return value;
                                })()}
                                disabled={true}
                            />
                            {!getTitleNameDisabled() && (
                                <>
                                    {formFields.title_name ? (
                                        <i
                                            className="fas fa-brush"
                                            onClick={() => {
                                                setFormFields({
                                                    ...formFields,
                                                    groupData: {},
                                                    title_name: "",
                                                });
                                                setSelectedState({
                                                    ...selectedState,
                                                    groupData: {},
                                                });
                                                changeUpdateBtnState("enable");
                                                changeCancelBtnState("enable");
                                            }}
                                        ></i>
                                    ) : accessControl?.writeAccess === 1 ? (
                                        <i
                                            className="fas fa-search"
                                            onClick={() =>
                                                handleSearch("group_filter")
                                            }
                                        ></i>
                                    ) : (
                                        false
                                    )}
                                </>
                            )}

                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </div>
                    ) : jsonObject.input_type === "text" &&
                      jsonObject.search_id === "user_filter" ? (
                        <div className="input searchiconprovposition">
                            <Input
                                type="text"
                                disabled={true}
                                className="provider-elm"
                                value={(() => {
                                    let value = "";
                                    if (props.dataItem.isEditMode) {
                                        value = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                    } else {
                                        value =
                                            formFields[
                                                `${fItem.filter_table_name}_${fItem.filter_column_name}`
                                            ] || "";
                                    }
                                    if (formFields[value]) {
                                        value = formFields[value];
                                    } else {
                                        value = fItem.filter_column_name;
                                        value = formFields[value] || "";
                                    }
                                    return value;
                                })()}
                            />
                            {formFields.user_details ? (
                                <i
                                    className="fas fa-brush"
                                    onClick={() => {
                                        setFormFields({
                                            ...formFields,
                                            userData: {},
                                            user_details: "",
                                            user_key: "",
                                        });
                                        setSelectedState({
                                            ...selectedState,
                                            userData: {},
                                        });
                                        changeUpdateBtnState("enable");
                                        changeCancelBtnState("enable");
                                    }}
                                ></i>
                            ) : accessControl?.writeAccess === 1 ? (
                                <i
                                    className="fas fa-search"
                                    onClick={() => handleSearch("user_filter")}
                                ></i>
                            ) : (
                                false
                            )}
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </div>
                    ) : jsonObject.input_type === "assigned_comp_plans" &&
                      formFields[fItem.filter_column_name] ? (
                        <Input
                            className="provider-elm"
                            value={formFields[fItem.filter_column_name]}
                            disabled={
                                accessControl?.writeAccess === 0 ? true : true
                            }
                        />
                    ) : fItem.filter_column_datatype === "boolean" ? (
                        <>
                            <Checkbox
                                value={(() => {
                                    let value = "";
                                    let key = "";
                                    if (fItem.custom_fld_mstr_key !== null) {
                                        key = `${
                                            fItem.filter_table_name
                                        }customFields${
                                            fItem.custom_fld_mstr_key
                                        }${
                                            fItem.custom_fld_col_mstr_key
                                        }_${fItem.custom_fld_data_type.toLowerCase()}`;
                                        value = formFields[key] || "";
                                    } else {
                                        key = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                        value = formFields[key] || "";
                                    }
                                    if (props?.dataItem.isEditMode) {
                                        if (formFields[key] !== undefined) {
                                            value = formFields[key] || "";
                                        } else {
                                            value =
                                                formFields[
                                                    fItem.filter_column_name
                                                ] || "";
                                        }
                                    }
                                    return (
                                        (formFields[value]
                                            ? formFields[value] === "0"
                                                ? false
                                                : true
                                            : false) ||
                                        (value
                                            ? value === "0"
                                                ? false
                                                : true
                                            : false)
                                    );
                                })()}
                                checked={(() => {
                                    let value = "";
                                    let key = "";
                                    if (fItem.custom_fld_mstr_key !== null) {
                                        key = `${
                                            fItem.filter_table_name
                                        }customFields${
                                            fItem.custom_fld_mstr_key
                                        }${
                                            fItem.custom_fld_col_mstr_key
                                        }_${fItem.custom_fld_data_type.toLowerCase()}`;
                                        value = formFields[key] || "";
                                    } else {
                                        key = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                        value = formFields[key] || "";
                                    }
                                    if (props?.dataItem.isEditMode) {
                                        if (formFields[key] !== undefined) {
                                            value = formFields[key] || "";
                                        } else {
                                            value =
                                                formFields[
                                                    fItem.filter_column_name
                                                ] || "";
                                        }
                                    }
                                    return (
                                        (formFields[value]
                                            ? formFields[value] === "0"
                                                ? false
                                                : true
                                            : false) ||
                                        (value
                                            ? value === "0"
                                                ? false
                                                : true
                                            : false)
                                    );
                                })()}
                                onChange={(e) => {
                                    handleCheckBoxFieldChange(e, fItem);
                                }}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : false
                                }
                            />
                        </>
                    ) : fItem.filter_column_datatype === "string" &&
                      jsonObject.required === "true" ? (
                        <>
                            <Input
                                className="provider-elm"
                                type="text"
                                value={(() => {
                                    let value = "";
                                    let key = "";
                                    if (fItem.custom_fld_mstr_key !== null) {
                                        key = `${
                                            fItem.filter_table_name
                                        }customFields${
                                            fItem.custom_fld_mstr_key
                                        }${
                                            fItem.custom_fld_col_mstr_key
                                        }_${fItem.custom_fld_data_type.toLowerCase()}`;
                                        value = formFields[key] || "";
                                    } else {
                                        key = `${fItem.filter_table_name}_${fItem.filter_column_name}`;
                                        value = formFields[key] || "";
                                    }
                                    if (props?.dataItem.isEditMode) {
                                        if (formFields[key] !== undefined) {
                                            value = formFields[key] || "";
                                        } else {
                                            value =
                                                formFields[
                                                    fItem.filter_column_name
                                                ] || "";
                                        }
                                    }
                                    return formFields[value] || value;
                                })()}
                                onChange={(e) => {
                                    handleFieldChange(e, fItem);
                                }}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : false
                                }
                            />
                            <p className="error-msg">
                                {errorObj[fItem.filter_label]}
                            </p>
                        </>
                    ) : fItem.filter_column_datatype === "doc" ? (
                        <>
                            <UploadFile
                                openUploadPopup={openUploadPopup}
                                setOpenUploadPopup={setOpenUploadPopup}
                                documentData={fItem.documentData}
                                disabled={
                                    accessControl?.writeAccess === 0
                                        ? true
                                        : false
                                }
                                pageProps={accessControl}
                            />
                        </>
                    ) : (
                        false
                    )}
                </div>
            </li>

            {showPopup.showSpecialtyPopup && (
                <AddSpecialty
                    setShowPopup={setShowPopup}
                    showPopup={showPopup}
                    setSpecialtySave={setSpecialtySave}
                />
            )}

            {showPopup.showGroupFilterPopup || showPopup.showUserFilterPopup ? (
                <SearchModule
                    showPopup={showPopup}
                    showGroupFilterPopup={showPopup.showGroupFilterPopup}
                    showUserFilterPopup={showPopup.showUserFilterPopup}
                    setShowPopup={setShowPopup}
                    setSelectedState={setSelectedState}
                    selectedState={selectedState}
                    formFields={formFields}
                    setFormFields={setFormFields}
                />
            ) : (
                false
            )}

            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                disabled={false}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};

export default React.memo(ProviderFields);
