import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import {
    COMP_PLAN_AUDIT,
    GET_AUDIT_LOGS,
    GET_BUSINESS_TABLE_AUDIT,
    GET_CALENDAR_TABLE_AUDIT,
    GET_CUSTOM_TABLE_AUDIT,
    GET_CUSTOM_TABLE_DATA,
    GET_ETL_AUDIT_LOGS,
    GET_EVENT_TYPE_AUDIT_LOGS,
    SELECT_FORMULA_MASTER_AUDIT_LOG,
    GET_JOB_AUDIT_LOG,
    GET_KPI_AUDIT,
    GET_KPI_TYPE_AUDIT_LOG,
    GET_RATE_TABLE_AUDIT,
    GET_RATE_TABLE_TIER_AUDIT,
    GET_RESULT_TYPE_MASTER_AUDIT,
    SELECT_COMP_RULE_AUDIT,
    SELECT_GROUP_AUDIT,
    SELECT_ROLE_AUDIT,
    SELECT_SPECIALTY_AUDIT,
    SELECT_UNIT_TYPE_MASTER_AUDIT,
    SELECT_POSITION_AUDIT_LOG,
    SELECT_PROVIDER_AUDIT_LOG,
    SELECT_RESULT_NAME_MASTER_AUDIT,
    SELECT_CUSTOM_FIELD_AUDIT_LOG,
    GET_CUSTOM_TABLE_EFF_DATES,
    PLAN_PROVIDER_AUDIT,
} from "../../api-setup/api-endpoints";
import {
    camelize,
    convertDateFormat,
    formatDate,
    formatDateOnly,
    formatDateOnlyMoment,
    getfilterDataString,
    replaceBlankAndMerge,
    toCamelCase,
} from "../../utils/utils";
import { columnMenuProps } from "../../components/customColumnMenu";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { HeaderPage } from "../base/headerPage";
import { Label, Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import {
    GetCompPlanDataApi,
    GetDBObjectListByAuditLog,
    getCustomFieldsMaster,
    getCustomTableListAsync,
} from "../../lib/common-service";
import { LoadingPanel } from "../../components/loadingPanel";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { expandedState, processTreeData } from "../../utils/treeDropdowndata";
import { CustomTreeDropdown } from "../../components/customTreeDropdown";
import "./style.scss";
import MessageSchema from "../../utils/messageSchema";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ToggleButton } from "@progress/kendo-react-dateinputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import useAPI from "./service";
import { PROVIDER_POSITION_DATA } from "src/constants/constants";
import CustomTableDataRow from "./customTableDataRow";
import { EffectiveVersions } from "src/components/effectiveVersions";
import EffDataRowsApi from "../../components/effectiveVesionService";
import CustomDropdown from "src/components/customDropdown";
import { transformDateTime } from "src/lib/date-time.util";

const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "id";
const textField = "text";
const subItemsField = "items";

const CUSTOM_FILED_TEXT = "managecustomfields";
const CUSTOM_TABLE_TEXT = "customtabledatarows";
const PLAN_PROVIDER_TABLE_TEXT = "planproviderassignment";

const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
};

export const CustomToggleButton = (props) => {
    return (
        <Tooltip
            position="bottom"
            anchorElement="target"
            style={{
                paddingTop: 10,
            }}
        >
            <ToggleButton {...props} title="Calendar">
                <span
                    className="k-icon k-font-icon k-i-calendar"
                    title="Calendar"
                />
            </ToggleButton>
        </Tooltip>
    );
};

const AuditLogs = (pageProps) => {
    const DEFAULT_SORT = { number: 1, order: "DESC" };
    const divRef = React.createRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const defaultPageSize = getTenantCookieData("record_per_page");

    const cookiesData = getUserCredential();

    const [columnsData, setColumnsData] = React.useState([]);
    const [isCheckColumn, setColumnCheck] = React.useState(false);
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [exportData, setExportData] = React.useState([]);

    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });
    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });
    const [filter, setFilter] = React.useState({
        value: "",
        operator: "contains",
    });
    const [customTableList, setCustomTableList] = React.useState([]);
    const [customTableFields, setCustomTableFields] = React.useState({
        customTableFields: {},
        isCustomTableFieldLoading: false,
        customTableList: [],
    });
    const [compPlanList, setCompPlanList] = React.useState([]);
    const [compPlanFields, setCompPlanFields] = React.useState({
        compPlanFields: {},
        isCompPlanLoading: false,
        compPlanList: [],
    });
    const [customTableNameFields, setCustomTableNameFields] = React.useState({
        customTableNameFieldsValue: {},
        isCustomTableNameFieldLoading: false,
        customTableNameList: [],
    });
    const [originalCustomFieldList, setOriginalCustomFieldList] =
        React.useState([]);
    const [customFields, setCustomFields] = React.useState({
        customFields: {},
        isCustomFieldLoading: false,
        customTableList: [],
    });
    const [providerFields, setProviderPosition] = React.useState({
        providerFields: PROVIDER_POSITION_DATA[0],
    });
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [dbObjTypeFields, setDataObjFields] = React.useState({
        dbObjTypeValue: {
            id: "",
            text: "",
        },
        dbObjTypeData: [],
        isDBObjDataLoading: true,
        dbTypeListLoading: true,
        screenName: "",
    });
    const [errorObj, setErrorObj] = React.useState({
        start_date: "",
        end_date: "",
        db_obj_type: "",
        commonDateValidate: "",
    });
    // const [dbObjList, setDBObjList] = React.useState([]);
    const [moduleName, setModuleName] = React.useState("");
    const [gridColumns, setGridColumns] = React.useState([]);
    const [openAuditLog, setOpenAuditLog] = React.useState({
        isOpen: false,
        data: {},
    });

    const [dateFields, setDateFields] = React.useState({
        start_date: "",
        end_date: "",
    });
    const [expanded, setExpanded] = React.useState([]);
    const [dropDownAppendTo, setDropdownAppendTo] = React.useState(null);
    const DATA_TABLE_ID = "audit-log-grid";

    const [list, Action] = useAPI();
    const [effVersionLoadingState, setEffVersionLoadingState] =
        React.useState(false);
    const [effVersionState, setEffVersionState] = React.useState({
        changeVersion: { rowId: -1, versionIdx: -1 },
    });
    const [parentDataItem, setParentDataItem] = React.useState("");
    const {
        columns,
        setColumns,
        data,
        setData,
        dataCount,
        initialLoading,
        noDataFound,
        isAPICalling,
        setAPICallingState,
        isSearchClick,
        setSearchClick,
    } = list;
    const [, ActionVersion] = EffDataRowsApi();

    React.useEffect(() => {
        setDropdownAppendTo(divRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //change eff version
    React.useEffect(() => {
        if (Object.keys(customTableFields.customTableFields).length > 0) {
            getEffVersionData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effVersionState.changeVersion]);

    const getEffVersionData = async () => {
        setEffVersionLoadingState(true);
        setPageSize(defaultPageSize);
        setPage({
            skip: 0,
            take: defaultPageSize.recordTake,
        });
        setSearchClick(true);
        setFilterData({
            isSort: false,
            isFilter: false,
            data: "",
            in_sort_number: DEFAULT_SORT.number,
            sort_order: DEFAULT_SORT.order,
        });
        const versionState = effVersionState.changeVersion;
        const mstrRow = customTableFields.customTableFields;
        let thisEffVersionDeta = mstrRow.effVersionDetails.data;

        thisEffVersionDeta
            .filter((x) => x.isSelected === 1)
            .map((item) => {
                item.isSelected = 0;
                return 0;
            });

        const newSelectedVersion = thisEffVersionDeta[versionState.versionIdx];

        newSelectedVersion.isSelected = 1;
        newSelectedVersion.endDate =
            mstrRow.effVersionDetails.currentVersionEndDate;
        let apiData = getAPIData();
        apiData.in_filter = null;
        apiData.in_sort_number = DEFAULT_SORT.number;
        apiData.in_sort_order = "DESC";
        apiData.in_page_row_offset = 0;
        apiData.in_page_rows = defaultPageSize.recordTake;
        apiData.in_custom_table_mstr_key =
            customTableFields.customTableFields.custom_table_mstr_key;
        apiData.in_date_version = newSelectedVersion.dateVersion;
        apiData.in_custom_data_order =
            customTableFields.customTableFields.custom_data_order;
        let resData = await Action.getListDataAsync(
            GET_CUSTOM_TABLE_DATA,
            apiData,
            dispatch,
            module
        );
        if (resData) {
            let effRes = customTableFields.customTableFields;
            effRes["effVersionDetails"]["data"] = thisEffVersionDeta;
            effRes.selected = true;
            setParentDataItem(effRes);
            setAPICallingState(false);
            setSearchClick(false);
            setEffVersionLoadingState(false);
        }
    };

    React.useEffect(() => {
        if (columns.length === 0 && isSearchClick) {
            let apiData = getAPIData();
            apiData.in_filter = null;
            apiData.in_sort_number = DEFAULT_SORT.number;
            apiData.in_sort_order = "DESC";
            apiData.in_page_row_offset = 0;
            apiData.in_page_rows = defaultPageSize.recordTake;
            getAuditLogsAsync(apiData, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, isSearchClick]);

    React.useEffect(() => {
        if (columns.length > 0) {
            setColumnsData(columns);
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        if (isCheckColumn) {
            setGridColumns(columnsData);
            setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckColumn]);

    React.useEffect(() => {
        getDBObjectDataList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    const getAPIData = () => {
        let apiData = {};
        apiData.in_tenant_id = cookiesData.out_tenant_id;
        apiData.in_filter = null;
        apiData.in_sort_number = 2;
        apiData.in_sort_order = "DESC";
        apiData.in_page_row_offset = 0;
        apiData.in_page_rows = defaultPageSize.recordTake;
        return apiData;
    };

    const getModule = (reqBody, isSort) => {
        const module = dbObjTypeFields.dbObjTypeValue.text
            .replace(/\s/g, "")
            .toLowerCase();
        let obj = {};
        obj.module = module;
        obj.reqBody = reqBody;
        switch (module) {
            case "users":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 15;
                }
                obj.api = GET_AUDIT_LOGS;
                break;
            case "dtuconfiguration":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 9;
                }
                obj.api = GET_ETL_AUDIT_LOGS;
                break;
            case "eventtypemaster":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 6;
                }
                obj.api = GET_EVENT_TYPE_AUDIT_LOGS;
                break;
            case "group":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 7;
                }
                obj.api = SELECT_GROUP_AUDIT;
                break;
            case "specialty":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 8;
                }
                obj.api = SELECT_SPECIALTY_AUDIT;
                break;
            case "roles":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 8;
                }
                obj.api = SELECT_ROLE_AUDIT;
                break;
            case "customtables":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 10;
                }
                obj.api = GET_CUSTOM_TABLE_AUDIT;
                break;
            case "ratetables":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 12;
                }
                obj.api = GET_RATE_TABLE_AUDIT;
                break;
            case "calendar":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 9;
                }
                obj.api = GET_CALENDAR_TABLE_AUDIT;
                break;
            case "businessgroup":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 9;
                }
                obj.api = GET_BUSINESS_TABLE_AUDIT;
                break;
            case "kpi":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 13;
                }
                obj.api = GET_KPI_AUDIT;
                break;
            case "kpitypemaster":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 6;
                }
                obj.api = GET_KPI_TYPE_AUDIT_LOG;
                break;
            case "jobconfiguration":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 10;
                }
                obj.api = GET_JOB_AUDIT_LOG;
                break;
            case CUSTOM_TABLE_TEXT:
                obj.reqBody.in_custom_table_mstr_key =
                    customTableFields.customTableFields.custom_table_mstr_key;
                obj.reqBody.in_date_version =
                    customTableFields.customTableFields.date_version;
                obj.reqBody.in_custom_data_order =
                    customTableFields.customTableFields.custom_data_order;
                obj.api = GET_CUSTOM_TABLE_DATA;
                break;
            case "ratetabletiers":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 16;
                }
                obj.api = GET_RATE_TABLE_TIER_AUDIT;
                break;
            case "systemunittype":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 8;
                }
                obj.api = SELECT_UNIT_TYPE_MASTER_AUDIT;
                break;
            case "ruleslibrary":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 12;
                }
                obj.api = SELECT_COMP_RULE_AUDIT;
                break;
            case "compplan":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 9;
                }
                obj.api = COMP_PLAN_AUDIT;
                break;
            case "formulamaster":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 10;
                }
                obj.api = SELECT_FORMULA_MASTER_AUDIT_LOG;
                break;
            case "resultname":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 12;
                }
                obj.api = SELECT_RESULT_NAME_MASTER_AUDIT;
                break;
            case "resulttypemaster":
                if (!isSort) {
                    obj.reqBody.in_sort_number = 6;
                }
                obj.api = GET_RESULT_TYPE_MASTER_AUDIT;
                break;
            case "provider-position":
                if (providerFields.id === "provider") {
                    if (!isSort) {
                        obj.reqBody.in_sort_number = 13;
                    }
                    obj.reqBody.in_acct_encrypt_key =
                        cookiesData.out_acct_encrypt_key;
                    obj.api = SELECT_PROVIDER_AUDIT_LOG;
                } else {
                    if (!isSort) {
                        obj.reqBody.in_sort_number = 17;
                    }
                    obj.reqBody.in_acct_encrypt_key =
                        cookiesData.out_acct_encrypt_key;
                    obj.api = SELECT_POSITION_AUDIT_LOG;
                }
                break;
            case CUSTOM_FILED_TEXT:
                if (!isSort) {
                    obj.reqBody.in_sort_number = 8;
                }
                obj.api = SELECT_CUSTOM_FIELD_AUDIT_LOG;
                obj.reqBody.in_custom_fld_col_mstr_key =
                    customFields.customFields.custom_fld_col_mstr_key;
                break;
            case PLAN_PROVIDER_TABLE_TEXT:
                obj.api = PLAN_PROVIDER_AUDIT;
                obj.reqBody.in_comp_plan_mstr_key =
                    compPlanFields.compPlanFields.comp_plan_mstr_key;
                obj.reqBody.in_acct_encrypt_key =
                    cookiesData.out_acct_encrypt_key;
                if (!isSort) {
                    obj.reqBody.in_sort_number = 16;
                    obj.reqBody.in_sort_order = "ASC";
                }
                break;
            default:
                obj.api = GET_ETL_AUDIT_LOGS;
                break;
        }
        return obj;
    };

    const getAuditLogsAsync = async (reqBodyData, isSort) => {
        let obj = await getModule(reqBodyData, isSort);
        let { module, api, reqBody } = obj;
        if (module === "compplan") {
            const reqData = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_filter: null,
                in_filter_db_name: "procare_system",
                in_filter_table_name: "vw_comp_plan_master_audit",
                in_hide_column: 0,
            };
            let res = await Action.getColumListXML(
                reqData,
                reqBody,
                api,
                module,
                dispatch
            );
            if (res) {
                getDataListAPICall(reqBody, api, module);
            }
        } else if (module === "jobconfiguration") {
            const reqData = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_filter: null,
                in_filter_db_name: "procare_system",
                in_filter_table_name: "vw_job_master_audit",
                in_result_name_mstr_key: 0,
                in_event_type_mstr_key: 0,
                in_hide_column: 0,
            };
            let res = await Action.getColumListXML(
                reqData,
                reqBody,
                api,
                module,
                dispatch
            );
            if (res) {
                getDataListAPICall(reqBody, api, module);
            }
        } else {
            getDataListAPICall(reqBody, api, module);
        }
    };

    const getEffVersionsApi = async () => {
        const versionProps = {
            apiEndPoints: { listApi: GET_CUSTOM_TABLE_EFF_DATES },
            dataItem: {
                mstr_key:
                    customTableFields.customTableFields.custom_table_mstr_key,
            },
            pageProps: { writeAccess: pageProps.writeAccess },
        };

        const versionApiCallData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_mstr_key:
                customTableFields.customTableFields.custom_table_mstr_key,
            in_sort_number: 1,
            in_sort_order: "ASC",
        };

        //get response for eff version tab
        let effVersionResponse = await ActionVersion.getListDataAsync(
            versionApiCallData,
            versionProps
        );
        const currentEndDate = effVersionResponse.currentVersionEndDate;
        //get response for eff version period menu
        const periodDataResponse = await ActionVersion.getPeriodsDataTypeAsync(
            currentEndDate
        );
        if (effVersionResponse.data.length > 0) {
            effVersionResponse["periodsData"] = periodDataResponse;
        }

        return effVersionResponse;
    };

    const getDataListAPICall = async (reqBody, api, module) => {
        let startDate = formatDateOnlyMoment(
            dateFields.start_date,
            "YYYY-MM-DD"
        );
        let endDate = formatDateOnlyMoment(dateFields.end_date, "YYYY-MM-DD");

        if (module !== CUSTOM_TABLE_TEXT) {
            if (dateFields.start_date !== "" && dateFields.end_date !== "") {
                if (reqBody.in_filter !== null) {
                    reqBody.in_filter = ` AND  (DATE (a.action_date) BETWEEN "${startDate}" AND  "${endDate}")  ${reqBody.in_filter}`;
                } else {
                    reqBody.in_filter = ` AND  (DATE (a.action_date) BETWEEN "${startDate}" AND  "${endDate}")`;
                }
            }
        }
        if (module === CUSTOM_TABLE_TEXT) {
            let effVersionResponse = await getEffVersionsApi();
            const selectedVersion = effVersionResponse.data.filter(
                (x) => x.isSelected === 1
            )[0];
            reqBody.date_version = selectedVersion.dateVersion;
            let resData = await Action.getListDataAsync(
                api,
                reqBody,
                dispatch,
                module
            );
            if (resData) {
                effVersionResponse["tierData"] = resData;
                let effRes = customTableFields.customTableFields;
                effRes["effVersionDetails"] = effVersionResponse;
                effRes.selected = true;
                setParentDataItem(effRes);
                setAPICallingState(false);
                setSearchClick(false);
            }
        } else {
            let res = await Action.getListDataAsync(
                api,
                reqBody,
                dispatch,
                module
            );
            if (res) {
                setAPICallingState(false);
                setSearchClick(false);
            }
        }
    };

    const onOpenPopup = (props) => {
        setOpenAuditLog({
            isOpen: true,
            data: props.dataItem,
        });
    };

    const ActionCell = (props) => {
        return (
            <td
                title="View Custom Table Data Rows Audit"
                className="text-center"
            >
                <span
                    className="k-icon k-font-icon k-i-eye pointer-class"
                    onClick={() => onOpenPopup(props)}
                ></span>
            </td>
        );
    };

    const DateCell = React.memo((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;
        const val =
            dataItem[field] && dataItem[field] !== "0000-00-00 00:00:00"
                ? transformDateTime({
                      date: dataItem[field],
                      format: "MM/DD/YYYY",
                      includeTime: true,
                  })
                : "";

        return (
            <td
                aria-colindex={ariaColumnIndex}
                data-grid-col-index={columnIndex}
            >
                <span title={val}>{val}</span>
            </td>
        );
    });

    const cellType = (type) => {
        switch (type) {
            case "date":
                return DateCell;
            case "expand":
                return ActionCell;
            default:
                return StringReandOnlyCell;
        }
    };

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={
                        column.field === "custom_table_type"
                            ? StringReandOnlyCell
                            : cellType(column.type)
                    }
                    width={column.field === "expand" ? 80 : column.minWidth}
                    sortable={column.field === "expand" ? false : true}
                    format={column.format}
                    resizable={column.field === "expand" ? false : true}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const StringReandOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;

        let val = dataItem[field || ""];

        if (field.indexOf("_mstr_key") !== -1) {
            val = dataItem[field.replace("_mstr_key", "_name") || ""];
        }
        let value =
            props.field === "custom_table_type"
                ? val === "S"
                    ? "Standard"
                    : "Hierarchical"
                : val;
        const defaultRendering = (
            <td
                aria-colindex={ariaColumnIndex}
                data-grid-col-index={columnIndex}
            >
                <span title={value}>{value}</span>
            </td>
        );

        return defaultRendering;
    }, []);

    let AuditColumn = useCol(gridColumns);

    const onClearFilter = () => {
        let apiData = getAPIData();
        //set in_params
        const dataVal = {
            ...apiData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getAuditLogsAsync(dataVal, filterData.isSort);
    };

    const handleColumnMenu = async (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
        }

        let filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;
        isFilter = false;
        filterString = replaceBlankAndMerge(filterString);

        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }
        const dataVal = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
            in_user_key: cookiesData.out_user_id.toString(),
            in_user_role_filter: "> 0",
        };
        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);
        getAuditLogsAsync(dataVal, isSort);
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) =>
            item.user_key === event.dataItem.user_key
                ? { ...item, [field]: event.value }
                : item
        );
        setData(newData);
    };

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;
        setAPICallingState(true);
        setPage({ skip: pageData.skip, take: pageData.take });
        let apiCallData = getAPIData();
        apiCallData.in_filter = getInFilter();
        apiCallData.in_sort_number = getInSortNumber();
        apiCallData.in_sort_order = getInSortOrder();
        apiCallData.in_page_rows = pageData.take;
        apiCallData.in_page_row_offset = pageData.skip;
        getAuditLogsAsync(apiCallData, filterData.isSort);
    };

    const onRefreshClick = async () => {
        setAPICallingState(true);
        let apiData = getAPIData();
        const dataVal = {
            ...apiData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        await getAuditLogsAsync(dataVal, filterData.isSort);
    };

    const handleMenuItemClick = (action) => {
        switch (action) {
            case "export":
                setExportDataClick(true);
                break;
            default:
                break;
        }
    };

    const headerConfig = {
        title: "Audit Logs",
        id: "audit_log",
        action_menu: {
            export: { fx: handleMenuItemClick },
        },
        manage_col: {
            columnsData: columnsData,
            setColumnsData: setColumnsData,
            setColumnCheck: setColumnCheck,
        },
    };

    const getDBObjectDataList = async () => {
        let req = {
            in_db_role_key: cookiesData.out_db_role_key,
            in_sort_number: 5,
            in_sort_order: "ASC",
            in_parent_filter:
                "AND a.parent_key IS NULL AND a.db_obj_type = 'menu'",
        };
        let res = await GetDBObjectListByAuditLog(req, dispatch, history);
        if (res.length > 0) {
            let tempArray = [];
            let tempExpandedIds = [];
            res.map((item) => {
                item?.children
                    ?.filter((x) => x.is_audit_log === 1)
                    .map((cItem) => {
                        let subChildTempArray = [];
                        let obj = {};

                        cItem?.subChildren?.map((sItem) => {
                            let obj = {};
                            obj.text = sItem.db_obj_name;
                            obj.id = sItem.db_obj_key;
                            subChildTempArray.push(obj);
                            return 0;
                        });
                        if (
                            cItem?.subChildren &&
                            cItem?.subChildren.length > 0
                        ) {
                            let expandedIds = cItem.subChildren.find(
                                (x) => x.parent_key === cItem.db_obj_key
                            ).parent_key;
                            tempExpandedIds.push(expandedIds);
                            setExpanded([...tempExpandedIds]);
                        }
                        obj.text = cItem.db_obj_name;
                        obj.id = cItem.db_obj_key;
                        obj.items = subChildTempArray;
                        tempArray.push(obj);
                        return 0;
                    });

                return 0;
            });
            // setDBObjList(sortArrayOfObjectsAlphabetically(tempArray, "text"));
            setDataObjFields({
                ...dbObjTypeFields,
                dbObjTypeData: tempArray,
                isDBObjDataLoading: false,
                dbTypeListLoading: false,
            });
        }
    };

    const onSearchButtonClick = () => {
        let isValid = handleValidation();
        if (isValid) {
            setPageSize(defaultPageSize);
            setPage({
                skip: 0,
                take: defaultPageSize.recordTake,
            });
            let moduleName =
                dbObjTypeFields.screenName === "provider-position"
                    ? `${providerFields.providerFields.text}`
                    : dbObjTypeFields.dbObjTypeValue.text;
            setModuleName(moduleName);
            setData([]);
            setColumns([]);
            setSearchClick(true);
            setFilterData({
                isSort: false,
                isFilter: false,
                data: "",
                in_sort_number: DEFAULT_SORT.number,
                sort_order: DEFAULT_SORT.order,
            });
        }
    };

    const parent = document.querySelector("#content-" + headerConfig.id);

    if (parent) {
        const exportToExcel = parent.querySelector(".export-to-excel");
        exportToExcel && exportToExcel.disableKendoMenu();
        data.length && exportToExcel && exportToExcel.enableKendoMenu();
    }

    const getExportData = async () => {
        let apiData = getAPIData();
        const dataVal = {
            ...apiData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: dataCount,
        };
        let startDate = formatDateOnly(dateFields.start_date);
        let endDate = formatDateOnly(dateFields.end_date);
        if (module !== CUSTOM_TABLE_TEXT) {
            if (dateFields.start_date !== "" && dateFields.end_date !== "") {
                if (dataVal.in_filter !== null) {
                    dataVal.in_filter = ` AND  (DATE (a.action_date) BETWEEN "${startDate}" AND  "${endDate}")  ${dataVal.in_filter}`;
                } else {
                    dataVal.in_filter = ` AND  (DATE (a.action_date) BETWEEN "${startDate}" AND  "${endDate}")`;
                }
            }
        }
        let response = "";
        let obj = getModule(dataVal, filterData.isSort);
        if (obj.module === "compplan") {
            setColumns([]);
            const reqData = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_filter: null,
                in_filter_db_name: "procare_system",
                in_filter_table_name: "vw_comp_plan_master_audit",
                in_hide_column: 0,
            };
            let res = await Action.getColumListXML(
                reqData,
                obj.reqBody,
                obj.api,
                obj.module,
                dispatch
            );
            if (res) {
                response = await Action.getExportDataAsync(
                    obj.api,
                    obj.reqBody,
                    dispatch,
                    obj.module
                );
            }
        } else if (obj.module === "jobconfiguration") {
            setColumns([]);
            const reqData = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_filter: null,
                in_filter_db_name: "procare_system",
                in_filter_table_name: "vw_job_master_audit",
                in_result_name_mstr_key: 0,
                in_event_type_mstr_key: 0,
                in_hide_column: 0,
            };
            let res = await Action.getColumListXML(
                reqData,
                obj.reqBody,
                obj.api,
                obj.module,
                dispatch
            );
            if (res) {
                response = await Action.getExportDataAsync(
                    obj.api,
                    obj.reqBody,
                    dispatch,
                    obj.module
                );
            }
        } else if (obj.module === CUSTOM_TABLE_TEXT) {
            let effVersionResponse = await getEffVersionsApi();
            const selectedVersion = effVersionResponse.data.filter(
                (x) => x.isSelected === 1
            )[0];
            obj.reqBody.date_version = selectedVersion.dateVersion;
            response = await Action.getExportDataAsync(
                obj.api,
                obj.reqBody,
                dispatch,
                obj.module
            );
            columns.map((item) => {
                if (item.type === "date") {
                    response.forEach((rItem) => {
                        if (
                            rItem[item.field] !== null &&
                            rItem[item.field] !== "0000-00-00 00:00:00"
                        ) {
                            rItem[item.field] = transformDateTime({
                                date: rItem[item.field],
                                includeTime: true,
                                format: "MM/DD/YYYY",
                            });
                        }
                        if (rItem[item.field] === "0000-00-00 00:00:00") {
                            rItem[item.field] = "";
                        }
                    });
                }

                if (item.type === "boolean") {
                    response.forEach((rItem) => {
                        rItem[item.field] = rItem[item.field]
                            ? "true"
                            : "false";
                    });
                }
                return 0;
            });
        } else {
            response = await Action.getExportDataAsync(
                obj.api,
                obj.reqBody,
                dispatch,
                obj.module
            );
        }

        if (response && response.length > 0) {
            setExportData(response);
        }
    };

    const handleValidation = () => {
        let isValid = true;
        let errObj = {};
        if (
            dbObjTypeFields.dbObjTypeValue.text
                .replace(/\s/g, "")
                .toLowerCase() !== CUSTOM_TABLE_TEXT
        ) {
            const { start_date, end_date } = dateFields;
            if (start_date && end_date) {
                const startDateObj = new Date(start_date);
                const endDateObj = new Date(end_date);

                if (start_date && end_date) {
                    if (startDateObj > endDateObj) {
                        errObj.commonDateValidate = MessageSchema.validDate;
                    } else {
                        errObj.commonDateValidate = "";
                    }
                }
            } else {
                if (start_date === "" || start_date === null) {
                    errObj.start_date = MessageSchema.actionStartDateRequired;
                }
                if (end_date === "" || end_date === null) {
                    errObj.end_date = MessageSchema.actionEndDateRequired;
                }
            }
        }

        if (
            dbObjTypeFields.dbObjTypeValue === "" ||
            dbObjTypeFields.dbObjTypeValue === null
        ) {
            errObj.db_obj_type = MessageSchema.screenNameRequired;
        } else {
            errObj.db_obj_type = "";
        }
        if (
            dbObjTypeFields.dbObjTypeValue.text
                .replace(/\s/g, "")
                .toLowerCase() === CUSTOM_TABLE_TEXT &&
            Object.keys(customTableFields?.customTableFields).length === 0
        ) {
            errObj.custom_table_name = MessageSchema.customTableDataRequired;
        } else {
            errObj.custom_table_name = "";
        }

        if (
            dbObjTypeFields.dbObjTypeValue.text
                .replace(/\s/g, "")
                .toLowerCase() === CUSTOM_FILED_TEXT &&
            Object.keys(customFields?.customFields).length === 0
        ) {
            errObj.custom_field_name = MessageSchema.customFieldNameRequired;
        } else {
            errObj.custom_field_name = "";
        }

        setErrorObj({ ...errObj });

        for (const [, value] of Object.entries(errObj)) {
            if (value !== "") {
                isValid = false;
            }
        }
        return isValid;
    };

    const onChange = (e) => {
        if (e.value.text.replace(/\s/g, "").toLowerCase() !== "events") {
            setDataObjFields({
                ...dbObjTypeFields,
                dbObjTypeValue: e.value,
                screenName: e.value.text.replace(/\s/g, "").toLowerCase(),
            });
            setErrorObj({
                ...errorObj,
                db_obj_type: "",
            });
            if (
                e.value.text.replace(/\s/g, "").toLowerCase() ===
                CUSTOM_TABLE_TEXT
            ) {
                setCustomTableFields({
                    ...customTableFields,
                    isCustomTableFieldLoading: true,
                });
                getCustomTableListAPI();
            } else if (
                e.value.text.replace(/\s/g, "").toLowerCase() ===
                CUSTOM_FILED_TEXT
            ) {
                setCustomFields({
                    ...customFields,
                    isCustomFieldLoading: true,
                });
                getCustomFieldListAPI();
            } else if (
                e.value.text.replace(/\s/g, "").toLowerCase() ===
                PLAN_PROVIDER_TABLE_TEXT
            ) {
                setCompPlanFields({
                    ...compPlanFields,
                    isCompPlanLoading: true,
                });
                getCompPlanListAPI();
            } else {
                setCustomTableFields({ customTableFields: {} });
                setErrorObj({
                    ...errorObj,
                    custom_table_name: "",
                });
            }
        }
    };

    const getCustomTableListAPI = async () => {
        const apiCallData = {
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "DESC",
            in_db_role_key: cookiesData?.out_db_role_key,
        };
        let res = await getCustomTableListAsync(apiCallData, dispatch, history);
        if (res.length) {
            setCustomTableList([...res]);
            setCustomTableFields({
                ...customTableFields,
                customTableFields: res[0],
                isCustomTableFieldLoading: false,
                customTableList: res,
            });
        }
    };

    const getCompPlanListAPI = async () => {
        const apiCallData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "DESC",
            in_user_key: cookiesData.out_user_id,
            in_multi_sort_order: null,
        };
        let res = await GetCompPlanDataApi(apiCallData, dispatch, history);
        if (res.length) {
            setCompPlanList([...res]);
            setCompPlanFields({
                ...compPlanFields,
                compPlanFields: res[0],
                isCompPlanLoading: false,
                compPlanList: res,
            });
        }
    };

    const getCustomFieldListAPI = async () => {
        const apiCallData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999999,
        };
        setCustomTableNameFields({
            ...customTableNameFields,
            isCustomTableNameFieldLoading: true,
        });
        let res = await getCustomFieldsMaster(apiCallData, dispatch, history);
        if (res.length) {
            const updatedFields = res.map((item) => {
                const baseName = item.custom_fld_table_label.replace(
                    /\s*\|\s*|\s+/g,
                    "-"
                );
                const name =
                    item.custom_fld_table_name === "vw_event_master"
                        ? `${baseName} | ${item.custom_fld_col_name}`
                        : `${item.custom_fld_table_label} | ${item.custom_fld_col_name}`;

                return { ...item, name };
            });
            setOriginalCustomFieldList(updatedFields);
            setCustomTableNameFields({
                ...customTableNameFields,
                isCustomTableNameFieldLoading: false,
                customTableNameFieldsValue: updatedFields[0],
                customTableNameList: updatedFields,
            });
            setCustomFields({
                ...customFields,
                customFields: updatedFields[0],
                isCustomFieldLoading: false,
            });
        }
    };

    const onExpandChange = React.useCallback(
        (event) =>
            setExpanded(expandedState(event.item, dataItemKey, expanded)),
        [expanded]
    );

    const { dbObjTypeValue: value } = dbObjTypeFields;
    const treeData = React.useMemo(
        () => {
            return processTreeData(
                dbObjTypeFields.dbObjTypeData,
                {
                    expanded,
                    value,
                    filter,
                },
                fields
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [expanded, dbObjTypeFields, filter]
    );
    const onFilterChange = (event) => setFilter(event.filter);

    const handleDateChange = (e) => {
        setDateFields({
            ...dateFields,
            [e.target.name]: e.target.value,
        });
        setErrorObj({
            ...errorObj,
            [e.target.name]: "",
            commonDateValidate: "",
        });
    };

    // const handleCustomTableList = (e) => {
    //   setCustomTableFields({
    //     ...customTableFields,
    //     customTableFields: e,
    //   });
    //   setErrorObj({
    //     ...errorObj,
    //     custom_table_name: "",
    //   });
    // };

    const handleProviderPosition = (e) => {
        setProviderPosition({
            ...providerFields,
            providerFields: e.value,
        });
        setErrorObj({
            ...errorObj,
            provider_position: "",
        });
    };

    const handleCustomFieldList = (e) => {
        setCustomFields({
            ...customFields,
            customFields: e.value,
        });
        setErrorObj({
            ...errorObj,
            custom_field_name: "",
        });
    };

    const exportFileName = () => {
        let name = camelize(moduleName.toLowerCase());
        let fileName = "";
        if (
            dbObjTypeFields.dbObjTypeValue.text
                .replace(/\s/g, "")
                .toLowerCase() === CUSTOM_TABLE_TEXT
        ) {
            fileName = `Export_Custom_Table_Data_Rows.xlsx`;
        } else {
            fileName = `Export_Audit_Log_${name}.xlsx`;
        }
        return fileName;
    };

    const checkSearchButton = () => {
        const isCustomTable = dbObjTypeFields.screenName !== CUSTOM_TABLE_TEXT;
        const isStartDateEmpty = dateFields.start_date === "";
        const isEndDateEmpty = dateFields.end_date === "";
        const isDbTypeValueEmpty = dbObjTypeFields.dbObjTypeValue.text === "";

        let isDisabled =
            dbObjTypeFields.dbTypeListLoading ||
            (isCustomTable &&
                (isStartDateEmpty || isEndDateEmpty || isDbTypeValueEmpty)) ||
            (!isCustomTable && isDbTypeValueEmpty);

        return isDisabled;
    };

    return (
        <>
            <div className="Main">
                <div className="content">
                    <div className="content-head">
                        <HeaderPage
                            pageProps={pageProps}
                            headerConfig={headerConfig}
                        />
                    </div>

                    <div className="content-body">
                        <div className="dtu-file-container">
                            <ul className={"Filter-links auditlogform"}>
                                <div
                                    ref={divRef}
                                    className={"k-form-field-wrap-popup"}
                                >
                                    <Label>{"Data Elements*:"}</Label>
                                    <CustomTreeDropdown
                                        className={"tree-popup capital-letter"}
                                        dropDownAppendTo={dropDownAppendTo}
                                        treeData={treeData.sort((a, b) =>
                                            a.text.localeCompare(b.text)
                                        )}
                                        value={dbObjTypeFields.dbObjTypeValue}
                                        onChange={onChange}
                                        placeholder="Select Data Element"
                                        textField={textField}
                                        dataItemKey={dataItemKey}
                                        selectField={selectField}
                                        expandField={expandField}
                                        onExpandChange={onExpandChange}
                                        loading={
                                            dbObjTypeFields.isDBObjDataLoading
                                        }
                                        filterable={true}
                                        onFilterChange={onFilterChange}
                                        filter={filter.value}
                                    />
                                    <Error>{errorObj.db_obj_type}</Error>
                                </div>
                                {dbObjTypeFields.screenName ===
                                CUSTOM_TABLE_TEXT ? (
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Custom Table Name*:"}</Label>
                                        <CustomDropdown
                                            name={"customTableFields"}
                                            data={
                                                customTableFields.customTableList
                                            }
                                            textField="custom_table_name_type"
                                            onOpen={(dataValue) => {
                                                setCustomTableFields({
                                                    ...customTableFields,
                                                    customTableList: dataValue,
                                                });
                                            }}
                                            dataItemKey="custom_table_mstr_key"
                                            valueData={customTableList.find(
                                                (c) =>
                                                    c.custom_table_name_type ===
                                                    customTableFields
                                                        .customTableFields
                                                        .custom_table_name_type
                                            )}
                                            onChange={(e, name) => {
                                                setCustomTableFields({
                                                    ...customTableFields,
                                                    [name]: e,
                                                });
                                            }}
                                            filterable={true}
                                            onFilterChange={(dataValue) => {
                                                setCustomTableFields({
                                                    ...customTableFields,
                                                    customTableList: dataValue,
                                                });
                                            }}
                                            autoClose={false}
                                            autoBind={true}
                                            isSelectALl={true}
                                            dataList={customTableList}
                                            loading={
                                                customTableFields.isCustomTableFieldLoading
                                            }
                                        />
                                        <Error>
                                            {errorObj.custom_table_name}
                                        </Error>
                                    </div>
                                ) : (
                                    false
                                )}

                                {dbObjTypeFields.screenName ===
                                PLAN_PROVIDER_TABLE_TEXT ? (
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Comp Plan Name*:"}</Label>
                                        <CustomDropdown
                                            name={"compPlanFields"}
                                            data={compPlanFields.compPlanList}
                                            textField="comp_plan_name"
                                            onOpen={(dataValue) => {
                                                setCompPlanFields({
                                                    ...compPlanFields,
                                                    compPlanList: dataValue,
                                                });
                                            }}
                                            dataItemKey="comp_plan_mstr_key"
                                            valueData={compPlanList.find(
                                                (c) =>
                                                    c.comp_plan_name ===
                                                    compPlanFields
                                                        .compPlanFields
                                                        .comp_plan_name
                                            )}
                                            onChange={(e, name) => {
                                                setCompPlanFields({
                                                    ...compPlanFields,
                                                    [name]: e,
                                                });
                                            }}
                                            filterable={true}
                                            onFilterChange={(dataValue) => {
                                                setCompPlanFields({
                                                    ...compPlanFields,
                                                    compPlanList: dataValue,
                                                });
                                            }}
                                            autoClose={false}
                                            autoBind={true}
                                            isSelectALl={true}
                                            dataList={compPlanList}
                                            loading={
                                                compPlanFields.isCompPlanLoading
                                            }
                                        />
                                    </div>
                                ) : (
                                    false
                                )}

                                {dbObjTypeFields.screenName ===
                                "provider-position" ? (
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Provider Position*:"}</Label>
                                        <DropDownList
                                            data={PROVIDER_POSITION_DATA}
                                            textField="text"
                                            dataItemKey="id"
                                            value={
                                                providerFields.providerFields
                                            }
                                            onChange={handleProviderPosition}
                                        />
                                        <Error>
                                            {errorObj.provider_position}
                                        </Error>
                                    </div>
                                ) : (
                                    false
                                )}

                                {dbObjTypeFields.screenName ===
                                CUSTOM_FILED_TEXT ? (
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Custom Field Names*:"}</Label>
                                        <CustomDropdown
                                            name={"customTableNameFieldsValue"}
                                            data={
                                                customTableNameFields.customTableNameList
                                            }
                                            textField="name"
                                            onOpen={(dataValue) => {
                                                setCustomTableNameFields({
                                                    ...customTableNameFields,
                                                    customTableNameList:
                                                        dataValue,
                                                });
                                            }}
                                            dataItemKey="custom_fld_col_mstr_key"
                                            valueData={customTableNameFields.customTableNameList.find(
                                                (c) =>
                                                    c.name ===
                                                    customTableNameFields
                                                        .customTableNameFieldsValue
                                                        .name
                                            )}
                                            onChange={(e, name) => {
                                                setCustomTableNameFields({
                                                    ...customTableNameFields,
                                                    [name]: e,
                                                });
                                            }}
                                            filterable={true}
                                            onFilterChange={(dataValue) => {
                                                setCustomTableNameFields({
                                                    ...customTableNameFields,
                                                    customTableNameList:
                                                        dataValue,
                                                });
                                            }}
                                            autoClose={false}
                                            autoBind={true}
                                            isSelectALl={true}
                                            dataList={originalCustomFieldList}
                                            loading={
                                                customTableNameFields.isCustomTableNameFieldLoading
                                            }
                                        />

                                        <Error>
                                            {errorObj.custom_field_name}
                                        </Error>
                                    </div>
                                ) : (
                                    false
                                )}
                                {dbObjTypeFields.screenName !==
                                    CUSTOM_TABLE_TEXT && (
                                    <>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup valueDropdown width20em"
                                            }
                                        >
                                            <Label>{"Start Date* : "}</Label>
                                            <DatePicker
                                                // value={dateFields.start_date}
                                                value={
                                                    typeof dateFields?.start_date ===
                                                    "object"
                                                        ? new Date(
                                                              dateFields?.start_date
                                                                  ?.toString()
                                                                  .replace(
                                                                      "GMT",
                                                                      ""
                                                                  )
                                                          )
                                                        : dateFields?.start_date
                                                }
                                                name="start_date"
                                                format="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleDateChange(e);
                                                }}
                                                formatPlaceholder={{
                                                    year: "yyyy",
                                                    month: "mm",
                                                    day: "dd",
                                                }}
                                                placeholder="yyyy-mm-dd"
                                                toggleButton={
                                                    CustomToggleButton
                                                }
                                            />
                                            <Error>{errorObj.start_date}</Error>
                                        </div>
                                        <Error>
                                            {dbObjTypeFields.startDateError}
                                        </Error>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup valueDropdown width20em"
                                            }
                                        >
                                            <Label>{"End Date* : "}</Label>
                                            <DatePicker
                                                toggleButton={
                                                    CustomToggleButton
                                                }
                                                // value={dateFields.end_date}
                                                value={
                                                    typeof dateFields?.end_date ===
                                                    "object"
                                                        ? new Date(
                                                              dateFields?.end_date
                                                                  ?.toString()
                                                                  .replace(
                                                                      "GMT",
                                                                      ""
                                                                  )
                                                          )
                                                        : dateFields?.end_date
                                                }
                                                name="end_date"
                                                format="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleDateChange(e);
                                                }}
                                                formatPlaceholder={{
                                                    year: "yyyy",
                                                    month: "mm",
                                                    day: "dd",
                                                }}
                                                placeholder="yyyy-mm-dd"
                                            />
                                            <Error>{errorObj.end_date}</Error>
                                            <Error>
                                                {errorObj.commonDateValidate}
                                            </Error>
                                        </div>
                                        <Error>
                                            {dateFields.validationError}
                                        </Error>
                                    </>
                                )}
                                <Button
                                    primary={true}
                                    className="searchbtn"
                                    disabled={checkSearchButton()}
                                    type={"submit"}
                                    onClick={(e) => {
                                        onSearchButtonClick();
                                    }}
                                >
                                    <i className="fas fa-search"></i>
                                </Button>
                            </ul>

                            <div className="auditLog-title">
                                <h1>{`${toCamelCase(moduleName)}`}</h1>
                            </div>

                            {isSearchClick ? <LoadingPanel /> : false}
                            {effVersionLoadingState ? <LoadingPanel /> : false}

                            {gridColumns.length > 0 && !isSearchClick && (
                                <>
                                    {parentDataItem &&
                                        dbObjTypeFields.screenName ===
                                            CUSTOM_TABLE_TEXT &&
                                        moduleName ===
                                            "CUSTOM TABLE DATA ROWS" && (
                                            <EffectiveVersions
                                                pageProps={pageProps}
                                                versionData={parentDataItem}
                                                effVersionState={
                                                    effVersionState
                                                }
                                                setEffVersionState={
                                                    setEffVersionState
                                                }
                                                effVersionLoadingState={
                                                    effVersionLoadingState
                                                }
                                                isShowRightMenu={false}
                                            />
                                        )}
                                    <DataTable
                                        isAPICalling={isAPICalling}
                                        initialLoading={initialLoading}
                                        data={data}
                                        id={DATA_TABLE_ID}
                                        itemChange={itemChange}
                                        pageData={pageData}
                                        dataCount={dataCount}
                                        customColumn={AuditColumn}
                                        columns={gridColumns}
                                        module={"auditLog"}
                                        fileName={exportFileName()}
                                        defaultPageSize={defaultPageSize}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        setPage={setPage}
                                        handlePageChange={handlePageChange}
                                        handleColumnMenu={handleColumnMenu}
                                        refreshClick={onRefreshClick}
                                        onClearFilter={onClearFilter}
                                        noDataFound={noDataFound}
                                        isExportDataClick={exportDataClick}
                                        setExportData={setExportDataClick}
                                        getExportData={getExportData}
                                        exportData={exportData}
                                        reorderable={false}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {openAuditLog.isOpen && (
                    <CustomTableDataRow
                        pageProps={pageProps}
                        setOpenAuditLog={setOpenAuditLog}
                        openAuditLog={openAuditLog}
                    />
                )}
            </div>
        </>
    );
};
export default React.memo(AuditLogs);
