import React, { useEffect, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import { validateRuleItem } from "./ruleFilterHelper";
import { changeCancelBtnState, changeUpdateBtnState, formatDateOnlyMoment } from "src/utils/utils";


const RuleFilterItemValue = (props) => {
  const { ruleItem, dropdownItems, onObjectChange, loading, enableFilterConditions=false } = props;

  const inOperators = ["IN", "NOT IN", "IS NULL", "IS NOT NULL", "LIKE", "NOT LIKE"];

  const [text, setText] = useState(ruleItem?.valueItem?.value || "");


  const [errorInfo, setErrorInfo] = useState({
    error: ruleItem?.valueItem?.error || false,
    errorMsg: ruleItem?.valueItem?.errorMsg || "",
  });
  const [search, setSearch] = useState("");
  const [dropdownData, setDropdownData] = useState(dropdownItems || []);

  useEffect(() => {
    if (ruleItem?.valueItem) {
      setText(ruleItem?.valueItem?.value);
        setErrorInfo({ error: ruleItem?.valueItem?.error || false, errorMsg: ruleItem?.valueItem?.errorMsg || "" });
    }
  }, [ruleItem.valueItem, ruleItem.attributeItem]);

  useEffect(() => {
    if (dropdownItems) {
      setDropdownData(dropdownItems);
    }
  }, [dropdownItems]);

  const onInputChange = (e) => {
    const val = e.target.value;
    setText(val);
    const validateRule = validateRuleItem({ ...ruleItem, valueItem: { ...ruleItem.valueItem, value: val } });
    setErrorInfo(validateRule);
    changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
    changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
  };

  const onDropdownChange = (val) => {
    onObjectChange({
      ...ruleItem.valueItem,
      error: errorInfo.error,
      errorMsg: errorInfo.errorMsg,
      value: val,
    });
  };

  const isDisabled = ruleItem.operatorItem?.value === "IS NULL" || ruleItem.operatorItem?.value === "IS NOT NULL";

  if (
    ruleItem.valueItem.key === "number" ||
    ruleItem.valueItem.key === "string" ||
    (ruleItem.valueItem.key === "date" && inOperators.includes(ruleItem.operatorItem.value))
  ) {
    return (
      <div>
        <Input
          type="text"
          name={`${ruleItem.valueItem.key}_${ruleItem.uId}`}
          value={text}
  
          placeholder={loading ? "Loading..." : ""}
          style={{ width: "100%" }}
          disabled={isDisabled}
          onChange={(e) => onInputChange(e)}
          onBlur={(e) => {
            onObjectChange({
              ...ruleItem.valueItem,
              error: errorInfo.error,
              errorMsg: errorInfo.errorMsg,
              value: text,
            });
          }}
          className={isDisabled && "customDisabled"}
        />
        {errorInfo && errorInfo.error && enableFilterConditions  ? (
          <Label style={{ fontSize: 11, color: "red" }}>{errorInfo.errorMsg}</Label>
        ) : (
          <Label />
        )}
      </div>
    );
  }

  if (ruleItem.valueItem.key === "date") {
    return (
      <div>
        <DatePicker
          name={`${ruleItem.valueItem.key}_${ruleItem.uId}`}
          value={typeof ruleItem.valueItem.value === 'string' ? ruleItem.valueItem.value.replace("GMT", "") : ruleItem.valueItem.value}
          style={{ width: "100%" }}
          className="dateItem"
          format="yyyy-MM-dd"
          disabled={ruleItem.operatorItem?.value === "IS NULL" || ruleItem.operatorItem?.value === "IS NOT NULL"}
          onChange={(e) => onInputChange(e)}
          formatPlaceholder={{
            year: "yyyy",
            month: "mm",
            day: "dd",
          }}
          placeholder="yyyy-mm-dd"
          onBlur={() =>
            onObjectChange({
              ...ruleItem.valueItem,
              error: errorInfo.error,
              errorMsg: errorInfo.errorMsg,
              value: text,
            })
          }
        />
        {errorInfo && errorInfo.error ? (
          <Label style={{ fontSize: 11, color: "red" }}>{errorInfo.errorMsg}</Label>
        ) : (
          <Label />
        )}
      </div>
    );
  }
  
  return (
    <DropDownList
      name={`${ruleItem.valueItem.key}_${ruleItem.uId}`}
      value={isDisabled ? { text: "", value: "" } : ruleItem.valueItem.value}
      onChange={(e) => onDropdownChange(e.target.value)}
      disabled={isDisabled}
      data={dropdownData}
      loading={loading}
      dataItemKey={"value"}
      textField={"text"}
      filterable={true}
      filter={search}
      style={{ width: "100%" }}
      onFilterChange={(event) => {
        setSearch(event.filter.value);
        setDropdownData(filterBy(dropdownItems.slice(), event.filter));
      }}
      onOpen={(event) =>{
        setSearch("")
        setDropdownData(filterBy(dropdownItems.slice(), event.filter));
      }}
    />
  );
};

export default RuleFilterItemValue;
