/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { passwordValidator } from "./validator";
import schema from "./schema/authentication";
import useServiceApi from "../service";
import {
    getLocalStorage,
    setFlash,
    getUserCredential,
    removeLocalStorage,
    setLocalStorage,
} from "../../../lib/cookies";
import { encrypt } from "../../../utils/crypto";
import { CreateAuthCookie } from "src/utils/loginUtils";
import { getHostURL } from "src/utils/utils";
import usePasswordToggle from "src/custom-hooks/usePasswordToggle";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { errStatusHandler, getUserToken } from "src/api-setup/api-helper";
import { useDispatch } from "react-redux";
import { setNewMessage } from "src/redux/actions/setting-actions";

const AuthenticationForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectPath = searchParams.get("redirect");

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const [InputType, IconClass, toggleVisibility] = usePasswordToggle();
    const [List, Action] = useServiceApi();
    const formSchema = schema;
    const { isRefreshLoading, setRefreshLoading } = List;
    const [formKey, setFormKey] = React.useState(1);
    // const [passwordValue, setPasswordValue] = useState("")
    const processEnv = process.env;
    const localStorageData = getLocalStorage(
        processEnv.REACT_APP_CREDENTIAL_KEY
    );

    const successRedirect = (response) => {
        switch (response.out_credential) {
            case "MFA_ACCESS":
                if (redirectPath) {
                    history.push(`/otp?redirect=${redirectPath}`);
                } else {
                    history.push("/otp");
                }
                break;
            case "CHANGE_PWD_ACCESS":
                if (redirectPath) {
                    window.location.href = `/change-password?redirect=${redirectPath}`;
                } else {
                    window.location.href = "/change-password";
                }
                break;
            case "AUTH_ACCESS":
                if (redirectPath) {
                    window.location.href = `/${redirectPath}`;
                } else {
                    window.location.href = "/" + response.out_db_obj_code;
                }
                break;
            default:
                if (redirectPath) {
                    window.location.href = `/authentication?redirect=/${redirectPath}`;
                } else {
                    window.location.href = "/authentication";
                }
                break;
        }
    };

    const resetForm = () => {
        setFormKey(formKey + 1);
    };
    //on form submit
    const handleSubmit = async (dataItem) => {
        const password = dataItem.in_password
            ? encrypt(dataItem.in_password)
            : "";

        const reqObj = {
            in_username: localStorageData.out_user_name,
            in_password: password,
        };

        const res = await Action.authenticateAsync(reqObj);
        const response = res?.data.out_parameters
            ? res?.data.out_parameters
            : res?.data?.data?.out_parameters;
        if (response?.out_ret_code > 0) {
            dispatch(
                setNewMessage({
                    message: response?.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            resetForm();
            errStatusHandler(res, history, dispatch);
        }
        //forcefully redirect to forgot password
        if (response?.out_forgot_password) {
            setFlash("forgot_password", response.out_error_msg);
            history.push("/forgotpassword");
        }

        if (response?.out_secret_token) {
            removeLocalStorage('sso_window')
            CreateAuthCookie(response);
            successRedirect(response);
        }
    };

    const RenderAzureLink = () => {
        React.useEffect(() => {
            if (localStorageData.out_sso_type === "ms_azure" && getLocalStorage('sso_window')) {
                handleSSOLogin();
            } else if(localStorageData.out_sso_type === "ms_azure") {
                setLocalStorage('sso_window', true)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        if (localStorageData.out_sso_type !== "ms_azure") {
            return "";
        }

        const handleSSOLogin = async () => {
            setRefreshLoading(true);

            const width = 800;
            const height = 800;
            const left = window.screen.width / 2 - width / 2;
            const top = window.screen.height / 2 - height / 2;

            const newWindow = window.open(
                `${getHostURL()}backend/v1/user/sso-login/${
                    localStorageData?.out_tenant_id
                }`,
                "AzureLogin",
                `width=${width},height=${height},top=${top},left=${left}`
            );

            const intervalId = setInterval(() => {
                if (newWindow?.closed) {
                    const newWindowLoc = newWindow.location;
                    const cookieData = getUserCredential();

                    if (cookieData) {
                        successRedirect(cookieData);
                    } else {
                        setRefreshLoading(false);
                    }

                    clearInterval(intervalId);
                }
            }, 1000);
        };

        return (
            <div>
                <div className="form-button line signupazurecont">
                    <Button
                        type={"button"}
                        onClick={handleSSOLogin}
                        className="btn-active bttn"
                    >
                        <span className="azurelogo"> </span> Sign in with Azure
                    </Button>
                </div>
                <div className="dividerline">
                    {" "}
                    <span>Or</span>
                </div>
            </div>
        );
    };

    const renderPasswordForm = () => {
        return (
            <Form
                onSubmit={handleSubmit}
                ignoreModified={true}
                key={formKey}
                validator={(value) => {
                    return passwordValidator(value, formSchema);
                }}
                render={(formRenderProps) => (
                    <div className="form">
                        {isRefreshLoading && loadingPanel}

                        <div className="formwrapper-elm-cont">
                            <FormElement
                                className="formwrapper-form-cont"
                                horizontal={true}
                                id={"passwordForm"}
                            >
                                <div className="formwrapper-elm-cont input-field-style">
                                    <Field
                                        {...formSchema.in_password}
                                        type={InputType}
                                        autoFocus={localStorageData?.out_sso_type === "ms_azure" ? false : true}
                                        // value={passwordValue}
                                        // onChange={(e)=>setPasswordValue(e.value)}
                                    />
                                    <a
                                        onClick={toggleVisibility}
                                        className={IconClass}
                                    >
                                        {" "}
                                    </a>
                                </div>
                                <div className="k-form-buttons"></div>
                            </FormElement>
                        </div>
                        <div className="loginbuttoncont">
                            {<RenderForgotPwdLink />}
                            <Button
                                type={"submit"}
                                className="btn-active bttn"
                                form="passwordForm"
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                )}
            />
        );
    };

    const RenderForgotPwdLink = () => {
        const history = useHistory();

        const redirectHandler = () => {
            history.push("forgotpassword");
        };

        return (
            <a onClick={redirectHandler} className="cursor-pointer link-class">
                Forgot Password ?
            </a>
        );
    };

    return (
        <>
            {RenderAzureLink()}
            {renderPasswordForm()}
        </>
    );
};

export default React.memo(AuthenticationForm);
