import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { compPlanStatementSelector } from "src/redux/selectors/custom-selector";
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";

import { IntlService } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { setCompPlanSection2DataAction } from "src/redux/actions/compplan-actions";
import { Tooltip } from "@progress/kendo-react-tooltip";

import ExcelExportCompPlanStatement from "./ExcelExportCompPlanStatement";
const intl = new IntlService("en");

const initialDataState = {
    group: [
        {
            field: "kpi_name",
            compare: (a, b) => a.kpi_report_order - b.kpi_report_order,
        },
        { field: "result_group_name", dir: "asc" },
        { field: "result_type_name", dir: "asc" },
    ],
};
const aggregates = [
    {
        field: "period1_value_total",
        aggregate: "sum",
    },
    {
        field: "period2_value_total",
        aggregate: "sum",
    },
    {
        field: "period3_value_total",
        aggregate: "sum",
    },
    {
        field: "period4_value_total",
        aggregate: "sum",
    },
    {
        field: "period5_value_total",
        aggregate: "sum",
    },
    {
        field: "period6_value_total",
        aggregate: "sum",
    },
    {
        field: "period7_value_total",
        aggregate: "sum",
    },
    {
        field: "period8_value_total",
        aggregate: "sum",
    },
    {
        field: "period9_value_total",
        aggregate: "sum",
    },
    {
        field: "period10_value_total",
        aggregate: "sum",
    },
    {
        field: "period11_value_total",
        aggregate: "sum",
    },
    {
        field: "period12_value_total",
        aggregate: "sum",
    },
    {
        field: "period13_value_total",
        aggregate: "sum",
    },
    {
        field: "period14_value_total",
        aggregate: "sum",
    },
    {
        field: "period15_value_total",
        aggregate: "sum",
    },
    {
        field: "period16_value_total",
        aggregate: "sum",
    },
    {
        field: "period17_value_total",
        aggregate: "sum",
    },
];

const processWithGroups = (data, dataState) => {
    const groups = dataState.group;
    if (groups) {
        groups.map((group) => (group.aggregates = aggregates));
    }
    dataState.group = groups;
    const newDataState = process(data, dataState);

    setGroupIds({
        data: newDataState.data,
        group: dataState.group,
    });
    return newDataState;
};

const CompPlanSectionTwo = forwardRef((props, sectionRef) => {
    const { isReportRunning, isExporting, reportHeight } = props;
    const { compPlanSection2Data, isProviderDetail, showSection1 } =
        useSelector(compPlanStatementSelector, shallowEqual);
    const dispatch = useDispatch();

    const dataState = useMemo(() => {
        return isProviderDetail
            ? {
                  ...initialDataState,
                  group: [
                      { field: "position_name" },
                      {
                          field: "kpi_name",
                          compare: (a, b) =>
                              a.kpi_report_order - b.kpi_report_order,
                      },
                      { field: "result_group_name" },
                      { field: "result_type_name", dir: "asc" },
                  ],
              }
            : initialDataState;
    }, [isProviderDetail]);

    const [result, setResult] = React.useState(
        processWithGroups(compPlanSection2Data.data, dataState)
    );
    const [collapsedState, setCollapsedState] = React.useState([]);
    const excelExportRef = React.useRef(null);

    const expandChange = (event) => {
        const item = event.dataItem;
        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }

        setTimeout(() => {
            resizeColGroup();
        }, 0);
    };

    const newData = setExpandedState({
        data: result.data,
        collapsedIds: collapsedState,
    });

    const cellRender = (tdElement, cellProps) => {
        if (tdElement && cellProps.rowType === "groupHeader") {
            const rowStyle = {
                position: "sticky",
                fontWeight: "bold",
                backgroundColor:
                    cellProps.dataItem.field === "position_name"
                        ? "#009DDC"
                        : cellProps.dataItem.field === "kpi_name"
                        ? "#7AC143"
                        : cellProps.dataItem.field === "result_group_name"
                        ? "#eae8e8"
                        : cellProps.dataItem.field === "result_type_name"
                        ? "#FDFD96"
                        : undefined,
            };
            const valDisplay = tdElement.props.children?.props?.children?.[1];

            const headerVal =
                tdElement.props.children?.type === "p" ? (
                    <>
                        {tdElement.props.children?.props?.children?.[0]}
                        <span title={valDisplay}>{valDisplay}</span>
                    </>
                ) : null;
            return React.cloneElement(
                tdElement,
                {
                    ...tdElement.props,
                    style: { ...tdElement.props.style, ...rowStyle },
                },
                headerVal
            );
        } else if (
            tdElement &&
            cellProps.rowType === "data" &&
            cellProps.field.startsWith("period")
        ) {
            const dataVal = parseVal(
                cellProps.dataItem[cellProps.field],
                cellProps.dataItem.report_format
            );
            const dataVal1 = <span title={dataVal}>{dataVal}</span>;
            return React.cloneElement(tdElement, tdElement.props, dataVal1);
        } else if (tdElement && cellProps.rowType === "data") {
            const dataVal = (
                <span title={cellProps.dataItem[cellProps.field]}>
                    {cellProps.dataItem[cellProps.field]}
                </span>
            );
            return React.cloneElement(tdElement, tdElement.props, dataVal);
        }
        return tdElement;
    };
    const rowRender = (row, props) => {
        if (props.rowType === "groupHeader" && !props.dataItem.value)
            return null;
        return row;
    };
    const CustomHeaderCell = (props) => {
        return (
            <span className="k-cell-inner" title={props.title}>
                <span className="k-link k-text-center">
                    {props.field === "period4_value" ||
                    props.field === "period8_value" ||
                    props.field === "period12_value" ||
                    props.field === "period16_value" ? (
                        <Button
                            className="k-column-title"
                            fillMode="solid"
                            onClick={() => toggleColumn(props.field)}
                        >
                            <span title={props.title}>{props.title}</span>
                        </Button>
                    ) : (
                        <Button
                            className="k-column-title"
                            fillMode="flat"
                            style={{ color: "#424242", cursor: "default" }}
                        >
                            <span title={props.title}>{props.title}</span>
                        </Button>
                    )}
                </span>
            </span>
        );
    };

    const getTotalValue = (field) => {
        let format = "$#,##0.00;-$#,##0.00";
        const item = compPlanSection2Data.data.filter(
            (it) => it.result_metadata_tags === "<grand_total>"
        );
        if (item.length) {
            format = item[0].report_format;
        }

        const total = item.reduce((it, it1) => it + it1[`${field}_total`], 0);
        const numberVal = intl.parseNumber(total || 0);
        return intl.formatNumber(numberVal, format);
    };

    const parseVal = (fieldVal, format) => {
        const numberVal = intl.parseNumber(fieldVal || 0);

        if (!format) {
            return !fieldVal ? "" : Boolean(numberVal) ? "True" : "False";
        }

        const formatArr = format.split("-");
        return intl.formatNumber(numberVal, formatArr[formatArr.length - 1]);
    };

    const toggleColumn = (field) => {
        let ids = field.match(/\d+/g).map(Number);
        if (!ids.length) return;

        const parNumber = ids[0];
        let finalGrids = [...compPlanSection2Data.columns];
        for (let i = 2; i <= 4; i++) {
            finalGrids[parNumber - i] = {
                ...finalGrids[parNumber - i],
                show: !finalGrids[parNumber - i].show,
            };
        }

        dispatch(setCompPlanSection2DataAction({ columns: finalGrids }));
    };

    useEffect(() => {
        const newDataState = processWithGroups(
            compPlanSection2Data.data,
            dataState
        );
        setResult(newDataState);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanSection2Data.data]);

    useEffect(() => {
        resizeColGroup();

        if (document.getElementsByClassName("k-grouping-header").length > 0) {
            const element = document
                .getElementsByClassName("k-grouping-header")
                .item(0);
            element.classList.add("k-hidden");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanSection2Data, isReportRunning]);

    const resizeColGroup = () => {
        const colGroup = document.getElementsByTagName("colgroup");

        if (colGroup?.length) {
            for (let i = 0; i < colGroup.length; i++) {
                const colGroupCol = colGroup[i].getElementsByTagName("col");
                for (let j = 0; j < colGroupCol.length; j++) {
                    if (
                        colGroupCol[j].offsetWidth > 10 &&
                        colGroupCol[j].offsetWidth < 35
                    ) {
                        colGroupCol[j].width = "10px";
                    }
                }
            }
        }
    };

    const TooltipContentTemplate = (props) => {
        const targetObj = props.title;
        const title = targetObj.title;

        return (
            <div className="pc-grid-tooltip">
                <strong>{title}</strong>
            </div>
        );
    };

    const isEllipsisActive = (element) => {
        let returnBool = false;

        const allowTags = ["SPAN", "TD"];

        if (!allowTags.includes(element.tagName)) {
            return returnBool;
        }

        let tdElement = element;
        let spanElement = element;

        if (element.tagName === "TD") {
            spanElement = element.firstChild;
        } else {
            tdElement = element.closest("td");
        }

        if (!tdElement || !spanElement) {
            return returnBool;
        }

        if (spanElement?.className?.includes("k-icon")) {
            return returnBool;
        }

        const bodyStyle = window.getElementStyle(tdElement);
        const tdPadding =
            parseFloat(bodyStyle["paddingLeft"]) +
            parseFloat(bodyStyle["paddingRight"]);

        const tdWidth = tdElement.offsetWidth - tdPadding;
        const spanWidth = spanElement.offsetWidth;

        // text was truncated.
        if (spanWidth > tdWidth) {
            spanElement.title = spanElement.innerText;
            returnBool = true;
        } else {
            spanElement.title = "";
        }

        return returnBool;
    };

    useImperativeHandle(
        sectionRef,
        () => {
            return {
                exportToExcel: () => excelExportRef.current?.excelExport(),
            };
        },
        []
    );

    if (compPlanSection2Data.columns.length === 0) return null;

    return (
        <Tooltip
            content={(props) => <TooltipContentTemplate title={props} />}
            filter={isEllipsisActive}
            openDelay={100}
            position="auto"
            //anchorElement="target"
        >
            <ExcelExportCompPlanStatement
                newData={newData}
                group={dataState.group}
                ref={excelExportRef}
            />
            <Grid
                style={
                    isExporting || isReportRunning
                        ? {
                              height: "100%",
                              minHeight: isReportRunning
                                  ? reportHeight
                                  : undefined,
                          }
                        : {
                              height: reportHeight,
                              overflowY: "scroll",
                          }
                }
                className="plan-comp-section2 kpipaymentgridpadding plan-columns"
                resizable={false}
                groupable={{ enabled: true, footer: "none" }}
                data={newData}
                {...dataState}
                scrollable={isExporting ? "scrollable" : "scrollable"}
                lockGroups={true}
                onExpandChange={expandChange}
                expandField="expanded"
                cellRender={cellRender}
                rowRender={rowRender}
            >
                <GridNoRecords>
                    <span>
                        {!isReportRunning ? "No Data Available" : undefined}
                    </span>
                </GridNoRecords>
                <GridToolbar>
                    <div className="Page-title kpi-paymnet-title-sec-2">
                        <h1>KPI Payment Details</h1>
                    </div>
                </GridToolbar>
                <Column
                    field="result_name"
                    title="Result Name"
                    width={180}
                    resizable={false}
                    headerCell={CustomHeaderCell}
                    locked
                    footerCell={(props) =>
                        !isReportRunning || isExporting ? (
                            <td
                                className="k-text-center"
                                colSpan={props.colSpan}
                                style={props.style}
                            >
                                <span title="Total Payments">
                                    Total Payments
                                </span>
                            </td>
                        ) : null
                    }
                />
                {compPlanSection2Data.columns
                    .filter((it) => it.show)
                    .map((it, index) => (
                        <Column
                            id={it.per_number}
                            key={it.per_number}
                            className="k-text-center"
                            width={
                                it.period_name.toLowerCase().includes("year")
                                    ? 90
                                    : 85
                            }
                            resizable={false}
                            field={`period${it.per_number}_value`}
                            title={it.period_name}
                            headerCell={CustomHeaderCell}
                            footerCell={(props) =>
                                !isReportRunning || isExporting ? (
                                    <td
                                        className="k-text-center"
                                        colSpan={props.colSpan}
                                        style={props.style}
                                    >
                                        <span
                                            title={getTotalValue(
                                                `period${it.per_number}_value`
                                            )}
                                        >
                                            {getTotalValue(
                                                `period${it.per_number}_value`
                                            )}
                                        </span>
                                    </td>
                                ) : null
                            }
                        />
                    ))}
            </Grid>
        </Tooltip>
    );
});

export default CompPlanSectionTwo;
