import React from "react";
import "../src/scss/App.scss";
import "@progress/kendo-theme-default/dist/all.scss";
import socketIOClient from "socket.io-client";
import Routes from "./routes";
import { NotificationToaster } from "./components/notificationToaster";
import projectSetting from "./setting";
import { getUserCredential } from "./lib/cookies";
import { DbObjectService } from "./service/dbObject";
import UnSavedChangePopup from "./components/UnsavedChangePopup";
import MaintenanceMsg from "./pages/common/maintenanceMsg";
import { PendoHook } from "src/components/pendo/script.hook";

const ENDPOINT = projectSetting.socket_url;

export let socket = socketIOClient.connect(ENDPOINT, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    transports: ["websocket"],
    forceNode: true,
});

//  // Listen for messages from the server
// socket.on('message', (data) => {
// });
//    socket.on('connect', (param) => {
//     socket.removeAllListeners();

//    });
//    socket.on('disconnect', (reason) => {
//     socket.removeAllListeners();
//      socket = null
//    });
//    socket.on('reconnect', (reason) => {
//   });

function App() {
    const cookiesData = getUserCredential();
    const getSidebarDataAsync = DbObjectService();
    const pendoHook = PendoHook();

    React.useEffect(() => {
        if (cookiesData) {
            getSidebarDataAsync();
            pendoHook.renderScript();
        }
    }, [cookiesData, getSidebarDataAsync]);

    return (
        <div className="App">
            <div>
                <MaintenanceMsg />
                <Routes />
                <NotificationToaster />
                <UnSavedChangePopup />
            </div>
        </div>
    );
}

export default App;
