// eslint-disable-next-line no-useless-concat
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import ManageResult from "./manage-results";
import DtuBatchLogs from "../common/dtuBatchLogs";
import CustomDropdown from "../../components/customDropdown";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getUserCredential } from "../../lib/cookies";

import { GridButton } from "../resultType/manage-results";
import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { ManageResultType } from "../resultType/result-type";
import { HeaderPage } from "../base/headerPage";
import Popup from "../../components/customPopup";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import useServiceApi from "./service";
import { REPORT_DISPLAY } from "../../constants/constants";
import UploadDtu from "../user/uploadDtu";
// import {useDispatch } from "react-redux";
// import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
// import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";

const initialFormFields = {
    display_report: 3,
    display_report_label: "",
    report_hyperlink: 0,
    report_order: 0,
    result_desc: "",
    result_group_mstr_key: "",
    result_group_name: "",
    result_name: "",
    result_name_type: "",
    result_name_type_group: "",
    result_type_mstr_key: 0,
    result_type_name: null,
    resultGroupData: [],
    resultDisplay: [],
    in_report_display: {
        text: "None",
        value: 0,
    },
};
const Results = forwardRef((pageProps, ref) => {
    const [list, Action] = useServiceApi();
    const cookiesData = getUserCredential();
    //  const { unsavedPopup } = useSelector(unSaveChangesPopupSelector, shallowEqual);
    // const dispatch = useDispatch();
    const [isAPICall, setAPICall] = React.useState(false);
    const [isShow, setShow] = React.useState({
        auditLogs: false,
        dtuLogs: false,
        deleteSelectedRow: false,
        sendEmails: false,
        uplodDtu: false,
        deleteRows: false,
    });
    const [errorObj, setErrorObj] = React.useState({});
    const [isSaveClick, setSaveClick] = React.useState(false);

    const [selectedRowId, setSelectedRowId] = React.useState("");
    const [buttonState, setbuttonState] = React.useState({
        isCancelClick: true,
        isAddDataClick: true,
    });
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [isAddClick, setAddnew] = React.useState(false);
    const [dataList, setDataList] = React.useState([]);
    const [copyDataClick, setcopyDataClick] = React.useState(false);
    const [resultNameEdit, setResultNameEdit] = React.useState(false);
    const [columnsData, setColumnsData] = React.useState([]);
    const [isCheckColumn, setColumnCheck] = React.useState(false);
    const [selectedArray, setSelectedArray] = React.useState([]);
    const [formFields, setFormFields] = React.useState({
        ...initialFormFields,
    });
    const addRef = React.useRef(null);
    let popupRef = React.useRef();

    const {
        data,
        resultTypeFields,
        setResultTypeData,
        originResultTypeData,
        resultGroupData,
        setResultGroupData,
        originResultGroupData,
    } = list;

    useImperativeHandle(
        ref,
        () => ({
            chooseResult: () => {
                return selectedRowId;
            },
        }),
        [selectedRowId]
    );

    React.useEffect(() => {
        if (list.loadingState.isSaveData) {
            setAPICall(true);
        }
    }, [list.loadingState.isSaveData]);

    React.useEffect(() => {
        if (resultNameEdit === false) {
            changeCancelBtnState();
            changeUpdateBtnState();
        }
    }, [resultNameEdit]);

    useEffect(() => {}, [isShow.resultType, dataList]);

    const handleMenuItemClick = (module) => {
        switch (module) {
            case "export":
                setExportDataClick(true);
                break;
            case "dtuLogs":
                setShow({
                    ...isShow,
                    dtuLogs: !isShow.dtuLogs,
                });
                break;
            case "delete":
                setShow({
                    ...isShow,
                    deleteRows: !isShow.deleteRows,
                });
                break;
            case "result":
                setShow({
                    ...isShow,
                    isResult: !isShow.isResult,
                });
                break;
            case "manageType":
                // TODO : UNSAVED CHANGES
                //  dispatch(setUnSavedChangesPopup({...unsavedPopup, isUnSavedChange: false}));
                setShow({
                    ...isShow,
                    isResultType: !isShow.isResultType,
                });
                break;
            case "duplicateRow":
                setcopyDataClick(true);
                break;
            default:
                break;
        }
    };
    const handleFieldChange = (e) => {
        const name = e.name ? e.name : e.target.name;
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
    };
    const AddNewForm = () => {
        return (
            <Form
                onSubmit={handleSubmit}
                render={() => (
                    <FormElement className="emailForm" horizontal={true}>
                        <FieldWrapper className="full-width">
                            <div className="k-form-field-wrap-popup">
                                <Label>Result Name *: </Label>
                                <Input
                                    type="text"
                                    name="result_name"
                                    value={formFields.result_name}
                                    onChange={(e) => {
                                        handleFieldChange(e);
                                    }}
                                    autoFocus={true}
                                    placeholder="Result Name"
                                />
                                <ShowError name="result_name" />
                            </div>

                            <div className="k-form-field-wrap-popup">
                                <Label>Result Description : </Label>
                                <Input
                                    type="text"
                                    name="result_desc"
                                    value={formFields.result_desc}
                                    onChange={(e) => {
                                        handleFieldChange(e);
                                    }}
                                    placeholder="Result Description"
                                />
                            </div>
                        </FieldWrapper>

                        <FieldWrapper className="full-width">
                            <div className={"k-form-field-wrap-popup"}>
                                <Label>{"Result Type: "}</Label>
                                <CustomDropdown
                                    name={"resultTypeValue"}
                                    data={resultTypeFields.resultTypeData}
                                    textField="text"
                                    dataItemKey="value"
                                    valueData={originResultTypeData.find(
                                        (c) =>
                                            c.value ===
                                            resultTypeFields.resultTypeValue
                                                .value
                                    )}
                                    onOpen={(dataV) => {
                                        setResultTypeData({
                                            ...resultTypeFields,
                                            resultTypeData:
                                                originResultTypeData,
                                        });
                                    }}
                                    onChange={(e, name) => {
                                        setResultTypeData({
                                            ...resultTypeFields,
                                            [name]: e,
                                        });
                                    }}
                                    onFilterChange={(dataValue) => {
                                        setResultTypeData({
                                            ...resultTypeFields,
                                            resultTypeData: dataValue,
                                        });
                                    }}
                                    filterable={true}
                                    autoClose={false}
                                    autoBind={true}
                                    dataList={originResultTypeData}
                                    loading={
                                        resultTypeFields.isresultTypeDataLoading
                                    }
                                    placeholder="Result Type"
                                />
                            </div>
                            <div className={"k-form-field-wrap-popup"}>
                                <Label>{"Result Group*: "}</Label>
                                <CustomDropdown
                                    name={"resultGroupValue"}
                                    data={resultGroupData.resultGroupDataValue}
                                    textField="text"
                                    dataItemKey="value"
                                    valueData={originResultGroupData.find(
                                        (c) =>
                                            c.value ===
                                            resultGroupData.resultGroupValue
                                                .value
                                    )}
                                    onOpen={(dataV) => {
                                        setResultGroupData({
                                            ...resultGroupData,
                                            resultGroupDataValue:
                                                originResultGroupData,
                                        });
                                    }}
                                    onChange={(e, name) => {
                                        setResultGroupData({
                                            ...resultGroupData,
                                            [name]: e,
                                        });
                                    }}
                                    onFilterChange={(dataValue) => {
                                        setResultGroupData({
                                            ...resultGroupData,
                                            resultGroupDataValue: dataValue,
                                        });
                                    }}
                                    filterable={true}
                                    autoClose={false}
                                    autoBind={true}
                                    dataList={originResultGroupData}
                                    loading={
                                        resultGroupData.isresultGroupDataLoading
                                    }
                                />
                            </div>
                        </FieldWrapper>

                        <FieldWrapper className="full-width">
                            <div
                                className={
                                    "k-form-field-wrap-popup valueDropdown"
                                }
                            >
                                <Label>{"Report Display : "}</Label>
                                <DropDownList
                                    onChange={handleFieldChange}
                                    value={REPORT_DISPLAY.find(
                                        (c) =>
                                            c.value ===
                                            formFields.in_report_display.value
                                    )}
                                    data={REPORT_DISPLAY}
                                    textField="text"
                                    name="in_report_display"
                                />
                            </div>
                            <div className="k-form-field-wrap-popup">
                                <Label>Result Order : </Label>
                                <NumericTextBox
                                    value={formFields.report_order}
                                    format={"#"} // No decimal places allowed
                                    onChange={(e) => {
                                        handleFieldChange(e);
                                    }}
                                    placeholder="Result Order"
                                    name="report_order"
                                />
                            </div>
                        </FieldWrapper>
                        <FieldWrapper>
                            <div
                                className={
                                    "k-form-field-wrap-popup hyperlinkckbox"
                                }
                            >
                                <Label>{"HyperLink"}</Label>
                                <Checkbox
                                    value={formFields.report_hyperlink}
                                    name={"report_hyperlink"}
                                    onChange={(e) => {
                                        handleFieldChange(e);
                                    }}
                                />
                            </div>
                        </FieldWrapper>
                    </FormElement>
                )}
            />
        );
    };

    const addNewData = async (e) => {
        setAddnew(!isAddClick);
        e.preventDefault();
        await Action.getResultTypeList();
        await Action.getResultGroup();
        setFormFields(initialFormFields);
    };
    const ShowError = React.useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj, isSaveClick]
    );
    const onCancelClick = () => {
        setFormFields(initialFormFields);
        setErrorObj({});
        setAddnew(false);
        setSaveClick(false);
    };
    const handleValidation = () => {
        let isValid = true;
        if (formFields.result_name.trim() === "") {
            isValid = false;
            errorObj.result_name = "Please enter Result Name";
        } else {
            errorObj.result_name = "";
        }
        setErrorObj((preVState) => ({ ...preVState, errorObj }));
        return isValid;
    };
    const handleSubmit = () => {
        let valid = handleValidation();

        if (valid) {
            const bodyData = {
                in_user_id: cookiesData.out_user_id.toString(),
                in_tenant_id: cookiesData.out_tenant_id,
                in_result_name: formFields.result_name,
                in_result_desc: formFields.result_desc,
                in_result_type: resultTypeFields.resultTypeValue.value,
                in_result_group: resultGroupData.resultGroupValue.value,
                in_report_display: formFields.in_report_display.value,
                in_report_order:
                    formFields.report_order === ""
                        ? 0
                        : formFields.report_order,
                in_report_hyperlink:
                    formFields.report_hyperlink === true ? 1 : 0,
            };
            Action.addDataAsync(bodyData);
            setErrorObj({});
            setAddnew(false);
        } else {
            setSaveClick(true);
        }
    };

    const AddNewPopup = () => {
        return (
            <Popup
                anchor={addRef.current}
                show={isAddClick}
                setShow={setAddnew}
                className="right0"
            >
                <div className="dot-dropdown-open dot-dropdown-open-Form">
                    <div className="caption">
                        <span>Add New Result Name</span>
                    </div>

                    {AddNewForm()}

                    <div className="dropdownFooter">
                        <div className="k-form-buttons">
                            <Button
                                primary={true}
                                type="submit"
                                onClick={() => {
                                    onCancelClick();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary={true}
                                type="submit"
                                onClick={() => {
                                    handleSubmit(formFields, errorObj);
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    };

    const AddNewLink = () => {
        return (
            <div ref={popupRef}>
                <a
                    href="/#"
                    className="cta-small"
                    ref={addRef}
                    onClick={(e) => {
                        addNewData(e);
                    }}
                >
                    <i className="fas fa-plus"></i> Add New Result Name
                </a>

                {AddNewPopup()}
            </div>
        );
    };

    const handleUpload = (e) => {
        setShow({
            ...isShow,
            dtuUpload: !isShow.dtuUpload,
        });
        e.preventDefault();
    };

    const headerConfig = {
        title: "Result Names",
        id: "resultName",
        buttons: { template_fx: GridButton },
        add_new_fx: AddNewLink,
        upload_fx: handleUpload,
        upload_title: "Result Name",
        action_menu: {
            export: { fx: handleMenuItemClick },
            dtuLog: { fx: handleMenuItemClick },
            delete: { fx: handleMenuItemClick },
            duplicateRow: {
                fx: handleMenuItemClick,
                caption: "Duplicate Selected Row",
            },
            ...(pageProps.resultTypeReadAccess === 1
                ? {
                      manageType: {
                          fx: handleMenuItemClick,
                          items: [
                              {
                                  caption: "Manage Result Type",
                                  module: "manageType",
                              },
                          ],
                      },
                  }
                : {}),
        },
        manage_col: {
            columnsData: columnsData,
            setColumnsData: setColumnsData,
            setColumnCheck: setColumnCheck,
        },
    };
    const parent = document.querySelector("#content-" + headerConfig.id);

    if (parent) {
        const exportToExcel = parent.querySelector(".export-to-excel");
        const deleteRows = parent.querySelector(".delete-rows");
        const copyRow = parent.querySelector(".copy-data");

        exportToExcel && exportToExcel.disableKendoMenu();
        deleteRows && deleteRows.disableKendoMenu();
        copyRow && copyRow.disableKendoMenu();
        dataList.length && exportToExcel && exportToExcel.enableKendoMenu();
        if (selectedRowId !== "") {
            deleteRows && deleteRows.enableKendoMenu();
            if (selectedArray.length === 1) {
                copyRow && copyRow.enableKendoMenu();
            }
        }
    }

    return (
        <>
            <div className="Main">
                <div className="content">
                    <div className="content-head">
                        {" "}
                        <HeaderPage
                            pageProps={pageProps}
                            headerConfig={headerConfig}
                        />
                    </div>
                    <div className="content-body">
                        <div className="dtu-file-container">
                            <ManageResult
                                isAPICall={isAPICall}
                                setAPICall={setAPICall}
                                setShow={setShow}
                                setExportDataClick={setExportDataClick}
                                exportDataClick={exportDataClick}
                                selectedRowId={selectedRowId}
                                setSelectedRowId={setSelectedRowId}
                                setDataList={setDataList}
                                setcopyDataClick={setcopyDataClick}
                                copyDataClick={copyDataClick}
                                setbuttonState={setbuttonState}
                                buttonState={buttonState}
                                dataList={dataList}
                                pageProps={pageProps}
                                isShow={isShow}
                                isAddClick={isAddClick}
                                setAddnew={setAddnew}
                                resultNameEdit={resultNameEdit}
                                setResultNameEdit={setResultNameEdit}
                                columnsData={columnsData}
                                setColumnsData={setColumnsData}
                                setColumnCheck={setColumnCheck}
                                isCheckColumn={isCheckColumn}
                                setSelectedArray={setSelectedArray}
                                isFromResultName={pageProps.isFromResultName}
                                defaultResultNameKey={
                                    pageProps.defaultResultNameKey
                                }
                                showNameResultPopup={
                                    pageProps.showNameResultPopup
                                }
                                ref={ref}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isShow.dtuLogs ? (
                <DtuBatchLogs
                    pageProps={pageProps}
                    visible={isShow.dtuLogs}
                    setShow={setShow}
                    module={"rule_result_name_master"}
                />
            ) : (
                false
            )}
            {isShow.isResultType ? (
                <ManageResultType
                    visible={isShow.isResultType}
                    setShow={setShow}
                    pageProps={pageProps}
                />
            ) : (
                false
            )}

            {isShow.dtuUpload && (
                <UploadDtu
                    visible={isShow.dtuUpload}
                    setShow={setShow}
                    title={"Upload Result Name"}
                    pageId={"result_name"}
                    setAPICall={setAPICall}
                />
            )}
        </>
    );
});

export default React.memo(Results);
