import { useCallback } from "react";
import { getUserCredential } from "../../lib/cookies";

export const PendoHook = () => {
    const cookiesData = getUserCredential();

    const getInitialInfo = useCallback(() => {
        const visitor = {
            id: cookiesData.out_user_id,
            email: cookiesData.out_user_name,
            role: cookiesData.out_db_role_type_abbr,
        };

        const account = {
            id: cookiesData.out_tenant_id,
            accountName: visitor.email,
        };

        return { visitor, account };
    }, [cookiesData]);

    const getScriptBody = useCallback(() => {
        const apiKey = "d8509312-6e0f-447e-6c0f-9d4a0745d7b6";

        return (
            "(function(apiKey){ (function(p,e,n,d,o) { var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[]; v=['initialize','identify','updateOptions','pageLoad','track']; for(w=0,x=v.length;w<x;++w) (function(m) { o[m]=o[m] || function() { o._q[m===v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments,0)));};})(v[w]); y=e.createElement(n); y.async=!0; y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js'; z=e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y,z);})(window,document,'script','pendo'); pendo.initialize(" +
            JSON.stringify(getInitialInfo()) +
            ");})('" +
            apiKey +
            "');"
        );
    }, [getInitialInfo]);

    const renderScript = useCallback(() => {
        const script = document.createElement("script");
        script.textContent = getScriptBody();

        document.querySelector("head").appendChild(script);
    }, [getScriptBody]);

    return {
        renderScript,
    };
};
