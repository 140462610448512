// eslint-disable-next-line no-useless-concat
/* eslint-disable jsx-a11y/anchor-is-valid */
import { getter } from "@progress/kendo-react-common";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input, Checkbox, Switch } from "@progress/kendo-react-inputs";
import * as React from "react";
import { useDispatch } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { CellRender, RowRender } from "../../components/renderers";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { removeMessage } from "../../redux/actions/setting-actions";
import MessageSchema from "../../utils/messageSchema";
import { DialogComponent } from "../../components/dialog";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { GetSystemConfigData, updateColumnReorderAsync } from "../../lib/common-service";
import {
  getfilterDataString,
  changeCancelBtnState,
  changeUpdateBtnState,
  dateFormateCell,
  formatDateOnly,
  dateFormateCellForJob,
  executeGetColumnOrderInfo,
} from "../../utils/utils";
import { socket } from "../../App";
import useApi from "./service";
import { JobDetail } from "./jobDetail";
import { useHistory } from "react-router";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "job_queue_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const JobData = (restProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [list, Action] = useApi();
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  React.useState(false);

  const {
    data,
    columns,
    dataCount,
    setData,
    originalData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
    setColumns,
  } = list;

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [exportData, setExportData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [errorObj, setErrorObj] = React.useState({});
  const [jobDetailLoading, setjobDetailLoading] = React.useState({});
  const [isJobCancel, setIsJobCancel] = React.useState(false);
  const [isJobRestartPopup, setisJobRestartPopup] = React.useState(false);
  const [ispipelineDelete, setIspipelineDelete] = React.useState(false);
  const [isJobqueueAdd, setIsJobqueueAdd] = React.useState(false);
  const [jobQueueKeyData, setJobQueueKeyData] = React.useState("");

  const statusFilterString =
    'AND ((a.job_status_key IN (1, 4, 5)) OR (a.job_status_key = 3 AND a.comment IS NOT NULL AND a.comment <> ""))';

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_db_role_key: cookiesData?.out_db_role_key,
    in_filter: statusFilterString,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
  };
  const jobprocessApiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_select_type: "running",
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_percentage: "-10",
  };

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      const inputCell = document.activeElement.closest("td");
      if (inputCell) {
        const cellClass = inputCell.classList[0];
        cellClass === "plan-field" && cancelJobGridChanges();
      } else {
        restProps.isJobEdit === true && cancelJobGridChanges();
        restProps.isEngineEdit === true && cancelEnginePhaseChanges();
      }
    };
  }

  if (document.getElementById("grid-incell-update")) {
    document.getElementById("grid-incell-update").onclick = function (e) {
      restProps.isJobEdit === true && updateJobGridChanges();
      restProps.isEngineEdit === true && updateEnginePhaseChanges();
      // restProps.isProviderEdit === true && updateproviderGridChanges();
    };
  }

  React.useEffect(() => {
    restProps.setDataList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (restProps.isAPICall) {
      restProps.setSelectedRowId("");
      setSelectedState({});
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (restProps.isAPICallForReport) {
      onRefreshClick();

      restProps.setisAPICallForReport(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICallForReport]);

  React.useEffect(() => {
    if (restProps.isJobDetail) {
      setjobDetailLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isJobDetail]);

  React.useEffect(() => {
    if (restProps.isAddNewConfig) {
      restProps.setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAddNewConfig]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (list.loadingState.isUpdateData) {
      list.setLoadState({ ...list.loadingState, isUpdateData: false });
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isUpdateData]);

  React.useEffect(() => {
    if (list.loadingState.isrestartData) {
      list.setLoadState({
        ...list.loadingState,
        isrestartData: false,
      });
      onRefreshClick();
      setIspipelineDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isrestartData]);

  React.useEffect(() => {
    // if (isJobqueueAdd) {
    onRefreshClick();
    setIsJobqueueAdd(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJobqueueAdd]);

  React.useEffect(() => {
    if (restProps.isShow.deleteRows) {
      remove();
      restProps.setShow({ ...restProps.isShow, deleteRows: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isShow.deleteRows]);

  React.useEffect(() => {
    let previousJobQueueKey = null;
    let hasJobCommentRun = false;
    let FailJobQueueKey = null;

    const handleJobStatusProgress = async (dataStatus) => {
      if (
        dataStatus.jobStatus === "Running" &&
        cookiesData.out_tenant_id == dataStatus.tenantId
      ) {
        const newJobQueueKey = dataStatus.jobQueueKey;
        if (newJobQueueKey !== previousJobQueueKey) {
          previousJobQueueKey = newJobQueueKey;
          setAPICallingState(true);
          await getGridData(apiCallData);
          if (dataStatus.jobTypeCode === "report_publish") {
            var imageDiv = document.querySelector(".k-loading-imagegear");
            if (imageDiv) {
              imageDiv.style.display = "block";
            }
            let res = await Action.getListDataAsync(
              apiCallData,
              localStorage.getItem("intervalDays")
            );

            const reportApiCallData = {
              compPlanMstrKey: res[0].comp_plan_mstr_key,
              periodMstrKey: res[0].period_mstr_key,
            };
            await Action.selectReportStatus(reportApiCallData);
          } else {
            await Action.getProcessJobMaster(jobprocessApiCallData);
          }
        }
      } else {
        if (dataStatus.jobQueueKey !== FailJobQueueKey) {
          hasJobCommentRun = false; // Reset flag when jobQueueKey changes
        }
        if (
          dataStatus.jobComment !== null &&
          dataStatus.jobQueueKey !== FailJobQueueKey &&
          !hasJobCommentRun &&
          cookiesData.out_tenant_id == dataStatus.tenantId
        ) {
          FailJobQueueKey = dataStatus.jobQueueKey;
          hasJobCommentRun = true; // Mark as run
          setAPICallingState(true);
          await getGridData(apiCallData);
        }
      }
    };
    socket.on("JOB_RESTART_STATUS", (jobStatusData) => {
      if (jobStatusData.jobStatus === true) {
        previousJobQueueKey = null;
      }
    });

    socket.on("JOB_STATUS_PROGRESS", handleJobStatusProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Include dependencies in the dependency array

  React.useEffect(() => {
    const handleJobStatusProgress = async (dataStatus) => {
      if (
        dataStatus.jobStatus === true &&
        cookiesData.out_tenant_id == dataStatus.tenantId
      ) {
        setAPICallingState(true);
        await getGridData(apiCallData);
        restProps.setprogressBarPer(null);

        setIspipelineDelete(true);
        var imageDiv = document.querySelector(".k-loading-imagegear");
        if (imageDiv) {
          imageDiv.style.display = "none";
        }
      }
    };
    socket.on("JOB_KILL_STATUS", handleJobStatusProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Include dependencies in the dependency array

  React.useEffect(() => {
    let previousprocessCtrlMstrKey = null;

    const handleJobStatusPerProgress = async (dataPer) => {
      const roundedPercentage = Math.floor(dataPer.percentage);
      if (
        roundedPercentage === 100 &&
        cookiesData.out_tenant_id == dataPer.tenantId
      ) {
        var imageDiv = document.querySelector(".k-loading-imagegear");
        if (imageDiv) {
          imageDiv.style.display = "none";
        }
        const newprocessCtrlMstrKey = dataPer.processCtrlMstrKey;
        if (newprocessCtrlMstrKey !== previousprocessCtrlMstrKey) {
          previousprocessCtrlMstrKey = newprocessCtrlMstrKey;
          setAPICallingState(true);
          await getGridData(apiCallData);
          await Action.getProcessJobMaster(jobprocessApiCallData);
        }
      }
    };

    socket.on("SUCCESS_PROGRESS_JOB_PER", handleJobStatusPerProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let prvPubLoadMstrKey = null;
    const handleJobStatusPerProgress = async (dataPer) => {
      const roundedPercentage = Math.floor(dataPer.processCtrlPer);
      if (
        roundedPercentage === 100 &&
        cookiesData.out_tenant_id == dataPer.tenantId
      ) {
        var imageDiv = document.querySelector(".k-loading-imagegear");
        if (imageDiv) {
          imageDiv.style.display = "none";
        }
        const newpubLoadMstrKey = dataPer.pubLoadMstrKey;
        if (newpubLoadMstrKey !== prvPubLoadMstrKey) {
          prvPubLoadMstrKey = newpubLoadMstrKey;
          setAPICallingState(true);
          await getGridData(apiCallData);
          let res = await Action.getListDataAsync(
            apiCallData,
            localStorage.getItem("intervalDays")
          );
          const reportApiCallData = {
            compPlanMstrKey: res[0]?.comp_plan_mstr_key,
            periodMstrKey: res[0]?.period_mstr_key,
          };

          // Check if both keys are set
          if (
            reportApiCallData.compPlanMstrKey &&
            reportApiCallData.periodMstrKey
          ) {
            await Action.selectReportStatus(reportApiCallData);
          }
        }
      }
    };

    socket.on("SUCCESS_PROGRESS_REPORT", handleJobStatusPerProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getGridData(apiCallData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGridData = async (inParams) => {
    inParams.in_filter =
      inParams.in_filter !== statusFilterString && inParams.in_filter !== null
        ? statusFilterString + inParams.in_filter
        : statusFilterString;
    let res = await Action.getListDataAsync(inParams);
    restProps.setDataList(res);

    setPageMenuState(res);
  };
  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.job_queue_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    restProps.setSelectedRowId(strSelectedKeys);
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : "";
  };
  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const cancelJobGridChanges = () => {
    dispatch(
      removeMessage({
        message: "",
        type: "Error",
        show: false,
      })
    );
    originalData.map((item) => {
      item.inEdit = false;
      item.selected = false;
      return 0;
    });
    restProps.setisJobEdit(false);
    setData(originalData);
    setErrorObj({});
    restProps.setDataList(originalData);
  };

  const cancelEnginePhaseChanges = () => {
    restProps.setisCancelClick(true);
  };

  const updateEnginePhaseChanges = () => {
    restProps.setisUpdateClick(true);
  };
  const handleValidation = () => {
    let isValid = true;

    data
      .filter((x) => x.inEdit !== undefined)
      .map((item) => {
        const { job_name, rowId, email_subject, email_body, job_type_name } =
          item;

        let errorObj = {
          job_name: "",
          email_subject: "",
          email_body: "",
        };

        if (job_name.trim() === "") {
          errorObj.job_name = MessageSchema.jobNameRequired;
        } else {
          errorObj.job_name = "";
        }
        if (job_type_name === "Email Process") {
          if (email_subject.trim() === "") {
            errorObj.email_subject = MessageSchema.jobSubjectRequired;
          } else {
            errorObj.email_subject = "";
          }
          if (email_body.trim() === "") {
            errorObj.email_body = MessageSchema.jobBodyRequired;
          } else {
            errorObj.email_body = "";
          }
        }

        let obj = {};

        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);

        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }

        return 0;
      });

    return isValid;
  };

  const updateJobData = async () => {
    let updatedJOBDataList = [];

    //update plan data
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        let obj = {};
        obj.in_user_id = cookiesData.out_user_id.toString();
        obj.in_tenant_id = cookiesData.out_tenant_id;
        obj.in_job_queue_key = item.job_queue_key;
        obj.in_job_type_mstr_key = item.job_type_mstr_key;
        obj.in_job_name = item.job_name;
        obj.in_job_desc = item.job_desc;
        obj.in_job_email_notif = item.job_email_notif;
        obj.in_email_subject = item.email_subject;
        obj.in_email_body = item.email_body;
        obj.in_published_position = item.published_position;

        updatedJOBDataList.push(obj);

        return 0;
      });

    const updateJobBodyData = {
      updateJOB: [...updatedJOBDataList],
    };
    if (updatedJOBDataList.length > 0) {
      let response = await Action.updateDataAsync(updateJobBodyData);

      if (response) {
        // TODO : UNSAVED CHANGES
        // dispatch(
        //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
        // );
        data.map((item) => {
          item.inEdit = false;
          return 0;
        });

        restProps.setisJobEdit(false);
        onRefreshClick();
      }
    }
  };

  const updateJobGridChanges = () => {
    let valid = handleValidation();

    if (valid) {
      updateJobData();
    } else {
      const elements = document.getElementsByClassName("k-textbox");
      elements[0].focus();

      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
    }
  };

  const remove = (deleteArray = null) => {
    let deleteIds = deleteArray === null ? deleteSelectedIds : deleteArray;
    const isRecurringJobData = deleteIds.some((item) => {
      return data.some(
        (obj) => obj.job_queue_key === item.id && obj.job_scheduler_key !== null
      );
    });
    let switchValue = false;
    const onValueChange = (e) => {
      switchValue = e.value;
    };
    setConfirmationPopup({
      ...confirmationPopup,

      desc: (
        <>
          {confirmationPopup.desc}
          {isRecurringJobData ? (
            <div className="text-center">
              Delete All Scheduled Jobs?{" "}
              <Switch
                onLabel={"Yes"}
                offLabel={"No"}
                onChange={onValueChange}
              />
            </div>
          ) : null}
        </>
      ),
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, () =>
        switchValue
          ? onBatchDeleteConfirm(deleteArray)
          : onDeleteConfirm(deleteArray)
      ),

      data: [],
      action: "Delete",
    });
  };

  const onBatchDeleteConfirm = (deleteArray) => {
    let deleteIds = deleteArray === null ? deleteSelectedIds : deleteArray;
    let resultArray = [];
    deleteIds.forEach((item) => {
      const matchingObj = data.find((obj) => obj.job_queue_key === item.id);
      if (matchingObj) {
        const jobSchedulerKey = matchingObj.job_scheduler_key;

        if (jobSchedulerKey === null) {
          // If jobSchedulerKey is null, match both id and job_queue_key
          const exactMatch = data.find((obj) => obj.job_queue_key === item.id);
          if (exactMatch) {
            resultArray.push({
              id: exactMatch.job_queue_key,
            });
          }
        } else {
          // If jobSchedulerKey is not null, match only job_scheduler_key
          const filteredObjects = data.filter(
            (obj) => obj.job_scheduler_key === jobSchedulerKey
          );
          filteredObjects.forEach((filteredObj) => {
            resultArray.push({
              id: filteredObj.job_queue_key,
            });
          });
        }
      }
    });
    let deleteData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_queue_key: resultArray,
    };
    Action.deleteDataAsync(deleteData);
    setDeleteSelectedIds([]);
    setErrorObj({});
    restProps.setSelectedRowId("");
  };
  const onDeleteConfirm = (deleteArray, event) => {
    let deleteData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_queue_key: deleteArray === null ? deleteSelectedIds : deleteArray,
      in_job_scheduler_flag: 0,
      in_job_schedule_key: "",
    };
    Action.deleteDataAsync(deleteData);
    setDeleteSelectedIds([]);
    setErrorObj({});
    restProps.setSelectedRowId("");
    restProps.setisJobEdit(false);
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
      desc: "Are you sure you want to delete this record?",
    });
  };

  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected: item.job_queue_key === dataItem.job_queue_key ? true : false,
    }));
    setData(newData);
    restProps.setSelectedRowId(dataItem.job_queue_key);
    restProps.setDataList(newData);
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }

      let obj = {};
      obj.id = Number(item);

      tempArray.push(obj);
      return 0;
    });

    const newSelectedStateKeys = Object.keys(newSelectedState);

    const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
      return newSelectedState[x] !== false;
    });

    let newData = data.map((item) => ({
      ...item,
      selected: selectedKeyArr.includes(item.job_queue_key),
    }));
    const isMatched = newData.some(
      (item) =>
        newSelectedState[item.job_queue_key.toString()] &&
        item.job_status_name === "Running"
    );

    setData(newData);
    setSelectedState(newSelectedState);
    if (!isMatched) {
      restProps.setSelectedRowId(tempArray[0].id);
    } else {
      restProps.setSelectedRowId("");
    }
    setDeleteSelectedIds(tempArray);
  };

  const enterEdit = (dataItem, field) => {
    // if (restProps.pageProps.writeAccess === 0) {
    //   return false;
    // }
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: item.job_queue_key === dataItem.job_queue_key ? field : undefined,
    // }));
    // setData(newData);
    // restProps.setisJobEdit(true);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (dataItem.comp_plan_name === "") {
      handleValidation();
    }
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      const newData = data.map((item) =>
        item.job_queue_key === event.dataItem.job_queue_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );
  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      let sortNameField = "";

      //replace mstr key with name field only when sorting is applied
      if (
        columnMenuData[0].field &&
        columnMenuData[0].field.indexOf("_mstr_key")
      ) {
        let sortKeyField = columnMenuData[0].field;
        sortNameField = sortKeyField.replace("_mstr_key", "_name");
      }

      const sortOriginField =
        sortNameField !== "" ? sortNameField : columnMenuData[0].field;

      index = obj.findIndex((x) => x === sortOriginField) + 1;
    }
    const filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }
    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
    };
    setFilterData({
      filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);
    getGridData(dataVal);
  };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          sortable={column.field === "proc_ctrl_mstr_key" ? false : true}
          width={column.field === "proc_ctrl_mstr_key" ? "80px" : "180px"}
          resizable={column.field === "proc_ctrl_mstr_key" ? false : true}
          editable={false}
          filterable={column.field === "proc_ctrl_mstr_key" ? false : true}
          reorderable={column.field === "proc_ctrl_mstr_key" ? false : true}
          cell={
            column.field === "published_position"
              ? publicAssinCell
              : column.field === "time_interval"
                ? dateFormateCellForJob
                : column.field === "proc_ctrl_mstr_key"
                  ? CustomCell
                  : column.field === "email_body" ||
                    column.field === "job_email_notif" ||
                    column.field === "email_subject" ||
                    column.field === "job_type_mstr_key"
                    ? NameCell
                    : StringReandOnlyCell
          }
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }
    const defaultRendering =
      field === "job_name" ? (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {dataItem.job_scheduler_key !== null ? (
            <span>
              <i
                className="far fa-clock"
                title={
                  "Recurring Type: " +
                  dataItem.recurring_type_code +
                  "\n" +
                  "Start Date: " +
                  formatDateOnly(dataItem.start_datetime) +
                  "\n" +
                  "End Date: " +
                  formatDateOnly(dataItem.end_datetime)
                }
                style={{ marginRight: "5px" }}
              ></i>

              {val}
            </span>
          ) : (
            <span title={val}>{val}</span>
          )}
        </td>
      ) : (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          <span title={val}>{val}</span>
        </td>
      );

    return defaultRendering;
  }, []);
  const cancelJob = () => {
    setIsJobCancel(true);
  };

  const restartJob = (JobQueueKeyData) => {
    setJobQueueKeyData(JobQueueKeyData);
    setisJobRestartPopup(true);
  };

  const handleRestartClickCancel = () => {
    setisJobRestartPopup(false);
  };

  const onJobCancelConfirm = () => {
    setIsJobCancel(false);
  };
  const onJobDeleteConfirm = async () => {
    setIsJobCancel(false);
    const runningJob = data.find((item) => item.job_status_name === "Running");

    const bodyData = {
      in_proc_ctrl_mstr_key: runningJob.proc_ctrl_mstr_key,
      in_period_mstr_key: runningJob.period_mstr_key,
      in_tenant_id: cookiesData.out_tenant_id,
      in_user_id: cookiesData.out_user_id.toString(),
      in_job_type_code: runningJob.job_type_code,
      in_out_db_user_name: cookiesData.out_db_user_name,
    };

    await Action.updatePipeLineAsync(bodyData);
  };
  const handleRestartClick = async () => {
    // async function fetchData() {
    let dataObject = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: "AND system_pref_code = 'sys_time_zone'",
      in_sort_number: null,
      in_sort_order: null,
      in_page_row_offset: null,
      in_page_rows: null,
    };
    const resDataObject = await GetSystemConfigData(dataObject, dispatch);
    if (resDataObject) {
      const restartbodyData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_user_id: cookiesData.out_user_id.toString(),
        in_job_queue_key: jobQueueKeyData,
        in_time_interval: resDataObject[0].pref_value,
      };
      Action.restartPipeLineAsync(restartbodyData);
      setisJobRestartPopup(false);
    }
    // }
  };
  const CustomCell = React.useCallback(
    (props) => {
      const { dataItem } = props;
      let jobStatusData = dataItem.job_status_name;
      let JobTypeCode = dataItem.job_type_code;

      const handleIconClick = () => {
        if (jobStatusData === "Running") {
          cancelJob();
        } else {
          let tempArray = [];
          let obj = {};
          obj.id = dataItem.job_queue_key;
          tempArray.push(obj);

          remove(tempArray);
        }
      };

      return (
        <td className="k-command-cell" style={{ textAlign: "center" }}>
          <a>
            {JobTypeCode === "report_publish" ? (
              <i
                title="Kill Job"
                className={
                  jobStatusData === "Running"
                    ? ""
                    : "far fa-trash-alt cursor-pointer"
                }
                style={jobStatusData === "Running" ? { color: "red" } : {}}
                onClick={handleIconClick}
              ></i>
            ) : !ispipelineDelete && jobStatusData !== "Cancelled" ? (
              <>
                <i
                  title={
                    jobStatusData === "Running" ? "Kill Job" : "Delete Job"
                  }
                  className={
                    jobStatusData === "Running"
                      ? "fas fa-ban cursor-pointer"
                      : "far fa-trash-alt far fa-calendar cursor-pointer"
                  }
                  style={jobStatusData === "Running" ? { color: "red" } : {}}
                  onClick={handleIconClick}
                ></i>
                &nbsp;
              </>
            ) : (
              <>
                {jobStatusData === "Cancelled" ? (
                  <>
                    <i
                      title="Delete Job"
                      className={"far fa-trash-alt cursor-pointer"}
                      style={{ marginRight: "5px" }}
                      onClick={handleIconClick}
                    ></i>
                    <i
                      title="Restart Job"
                      className={"fas fa-redo-alt cursor-pointer"}
                      onClick={() => restartJob(dataItem.job_queue_key)}
                    ></i>
                  </>
                ) : (
                  <i
                    title="Kill Job"
                    className={
                      jobStatusData === "Running"
                        ? "fas fa-ban cursor-pointer"
                        : "far fa-trash-alt cursor-pointer"
                    }
                    style={jobStatusData === "Running" ? { color: "red" } : {}}
                    onClick={handleIconClick}
                  ></i>
                )}
              </>
            )}
          </a>
        </td>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ispipelineDelete, data]
  );

  const publicAssinCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      let valueData =
        props.dataItem[props.field] !== ""
          ? props.dataItem[props.field] === 1
            ? "true"
            : "false"
          : "";

      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );

          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value === true ? 1 : 0,
          });
        }
      };

      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="kpi-field"
        >
          {isInEdit ? (
            <Checkbox
              value={dataItem[props.field]}
              checked={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={valueData}>{valueData}</span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      if (dataItem["job_type_name"] === "Email Process") {
        if (
          field === "email_body" ||
          field === "job_email_notif" ||
          field === "email_subject"
        ) {
          isInEdit = field === dataItem.inEdit;
        }
      } else {
        if (
          field === "email_body" ||
          field === "job_email_notif" ||
          field === "email_subject"
        ) {
          isInEdit = false;
        }
      }
      let valueData = dataItem[field || ""];
      if (field.indexOf("_mstr_key") !== -1) {
        valueData = dataItem[field.replace("_mstr_key", "_name") || ""];
      }
      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES

          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );

          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="kpi-field"
        // title=""
        >
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={valueData}>{valueData}</span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  let GroupColumn = useCol(columns);

  const onClearFilter = () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };
    getGridData(dataVal);
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      exitEdit={exitEdit}
    />
  );

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };
    let res = await Action.exportDataAsync(dataVal);
    if (res) {
      const exportRes = res.map((item) => ({
        ...item,
        period_start_date: item.published_position === 1,
        job_type_mstr_key: item.job_type_name,
      }));
      setExportData(exportRes);
    }
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };

  const prepareColumnString = (colData) => {
    return executeGetColumnOrderInfo(colData);
};


  const columnReorderData = async (columnData) => {
    if (restProps.writeAccess === 0) {
      return false;
    }
    const str = prepareColumnString(columnData);
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: str,
    };
    let res = await updateColumnReorderAsync(reqData, dispatch, history);
    if (res) {
      let colDataList = JSON.parse(JSON.stringify(columnData));
      colDataList.forEach((item) => {
        item.hidden = false;
      });
      const mergedArray = columns.map((item1) => {
        const item2 = colDataList.find(
          (item) => item.field === item1.field
        );
        return {
          ...item1,
          ...item2,
        };
      });
      setColumns([]);
      let colData = mergedArray.sort(function (a, b) {
        return a?.ariaColumnIndex - b?.ariaColumnIndex;
      });
      setColumns(colData);
    }
  };

  return (
    <>
      {isRefreshLoading && loadingPanel}
      {columns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          data={data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          id="job-grid"
          editField={editField}
          pageData={pageData}
          dataCount={dataCount}
          customColumn={GroupColumn}
          columns={columns}
          dataItemKey={DATA_ITEM_KEY}
          width={"auto"}
          module={"job_configuration"}
          getExportData={getExportData}
          setExportData={restProps.setExportDataClick}
          isExportDataClick={restProps.exportDataClick}
          fileName={"Export_job_configuration.xlsx"}
          defaultPageSize={defaultPageSize}
          pageSize={pageSize}
          setPageSize={setPageSize}
          exportData={exportData}
          rowRender={customRowRender}
          cellRender={customCellRender}
          itemChange={itemChange}
          handlePageChange={handlePageChange}
          handleColumnMenu={handleColumnMenu}
          onClearFilter={onClearFilter}
          onSelectionChange={onSelectionChange}
          refreshClick={onRefreshClick}
          columnReorderData={
            columnReorderData
          }
        />
      ) : (
        <>{loadingPanel}</>
      )}
      {restProps.isJobDetail ? (
        <JobDetail
          visible={restProps.isJobDetail}
          setisJobDetail={restProps.setisJobDetail}
          pageProps={restProps}
          setjobDetailLoading={setjobDetailLoading}
          isJobqueueAdd={isJobqueueAdd}
          setIsJobqueueAdd={setIsJobqueueAdd}
          jobDetailLoading={jobDetailLoading}
        />
      ) : (
        false
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"auto"}
        height={"auto"}
        className={"Dialog-Delete multipal-job"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={
          confirmationPopup.action === "Delete"
            ? "delete-confirm"
            : "unSave-confirm"
        }
      />
      {isJobCancel ? (
        <Dialog title={"Confirm Job Cancel"} id="dialog-timeout" width={550}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            <img
              src={require("../../images/alert.png").default}
              alt="ProCARE"
              style={{ height: "25" }}
            />{" "}
            Are you sure you want to cancel the current Job?
          </p>
          <DialogActionsBar layout="center">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onJobCancelConfirm}
            >
              Cancel
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onJobDeleteConfirm}
            >
              Yes
            </button>
          </DialogActionsBar>{" "}
        </Dialog>
      ) : (
        false
      )}
      {isJobRestartPopup ? (
        <Dialog title={"Confirm Job Restart"} id="dialog-timeout" width={550}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            <img
              src={require("../../images/alert.png").default}
              alt="ProCARE"
              style={{ height: "25" }}
            />{" "}
            Are you sure you want to restart the Job?
          </p>
          <DialogActionsBar layout="center">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleRestartClickCancel}
            >
              Cancel
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleRestartClick}
            >
              Yes
            </button>
          </DialogActionsBar>{" "}
        </Dialog>
      ) : (
        false
      )}
    </>
  );
};
