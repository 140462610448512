/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
import React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { getUserCredential } from "../../lib/cookies";
import { useDispatch } from "react-redux";
import { Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { GetDataTypes } from "../../lib/common-service";
import { useHistory } from "react-router";
import MessageSchema from "../../utils/messageSchema";
import { INSERT_CUSTOM_TABLE_COLUMNS } from "../../api-setup/api-endpoints";
import { generateHeader, POSTAPI } from "../../api-setup/api-helper";
import { CellRender, RowRender } from "../../components/renderers";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { DialogComponent } from "../../components/dialog";
import useApi from "./service";
import { LoadingPanel } from "../../components/loadingPanel";
import {
    DELETE_BTN_ACTIONS,
    WARNING_BTN_ACTIONS,
} from "../../constants/constants";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { microTime } from "../../utils/utils";
import DataTable from "../../components/dataTable";
import { columnMenuProps } from "../../components/customColumnMenu";
import { gridColumns } from "./addCustomTable";
// import { unSaveChangesPopupSelector } from 'src/redux/selectors/custom-selector';
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

const EDIT_FIELD = "inEdit";
const DATA_TABLE_ID = "custom-table-grid";
const editField = "inEdit";
const DATA_ITEM_KEY = "in_custom_col_mstr_key";

const ModalTitle = () => {
    return <span>Edit Custom Table</span>;
};

const checkEmptyColumns = (obj) => {
    let isValid = true;
    if (obj.in_custom_col_name === "" || obj.in_custom_data_type === "") {
        isValid = false;
    }
    return isValid;
};

let initialObj = {
    in_custom_col_mstr_key: "",
    in_custom_col_name: "",
    in_custom_col_desc: "",
    in_custom_col_order: 0,
    in_custom_data_type: "",
    in_is_key: 0,
};

const EditCustomTable = (restProps) => {
    const dispatch = useDispatch();
    // const { unsavedPopup } = useSelector(unSaveChangesPopupSelector, shallowEqual);
    const history = useHistory();
    const [visible, setVisible] = React.useState(false);
    const cookiesData = getUserCredential();
    const [data, setData] = React.useState([]);
    const [errorObj, setErrorObj] = React.useState({});
    const [editData, setEditData] = React.useState({});
    const [originalData, setOriginalData] = React.useState([]);
    const [dataTypes, setDataTypes] = React.useState([]);
    const [list, Action] = useApi();
    const { loadingState, confirmationPopup, setConfirmationPopup } = list;
    const [formFields, setFormFields] = React.useState({
        in_custom_table_desc: "",
        in_custom_table_name: "",
    });
    const [isEdit, setIsEdit] = React.useState(false);
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const [isChange, setChange] = React.useState(false);
    const [customTableType, setCustomTableType] = React.useState("");
    const [loadState, setLoadingState] = React.useState({
        isInitialLoading: false,
        isDeleteLoading: false,
    });
    const [configErrorObj, setConfigErrorObj] = React.useState({});
    const [modal, setModal] = React.useState(true);
    const [stageChange, setStateChange] = React.useState("DEFAULT");

    React.useEffect(() => {
        if (loadingState.isDeleteData) {
            getDataTypesList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingState]);

    React.useEffect(() => {
        setLoadingState({
            ...loadState,
            isInitialLoading: true,
        });
        getDataTypesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataTypesList = async () => {
        let reqData = {
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "ASC",
        };
        let dataTypesRes = await GetDataTypes(reqData, dispatch, history);
        if (dataTypesRes.length) {
            setDataTypes(dataTypesRes);
            await getCustomTableRows(dataTypesRes);
        } else {
            await getCustomTableRows();
        }
    };

    const getCustomTableRows = async (dataTypesRes) => {
        let reData = {
            in_filter: null,
            in_custom_table_mstr_key:
                restProps.editDataItem.custom_table_mstr_key,
            in_sort_number: 1,
            in_sort_order: "DESC",
            in_multi_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 500,
        };
        let res = await Action.getCustomTableColumns(reData);
        if (res) {
            let tempArray = [];
            res.map((item, i) => {
                let obj = {};
                // obj.inEdit = 'in_custom_col_name'
                obj.in_custom_table_mstr_key =
                    restProps.editDataItem.custom_table_mstr_key;
                obj.in_custom_col_name = item.origin_custom_col_name;
                obj.in_custom_col_desc = item.custom_col_desc;
                obj.in_custom_col_order = item.custom_col_order;
                obj.in_custom_data_type = item.data_type_name;
                obj.in_custom_col_mstr_key = item.custom_col_mstr_key;
                obj.in_date_version = item.date_version;
                obj.in_is_key = item.custom_col_is_key;
                obj.version = item.version;
                obj.dataTypes = dataTypesRes;
                obj.showDeleteIcon =
                    restProps.editDataItem.custom_table_type === "H"
                        ? item.origin_custom_col_name === "Node Name" ||
                          item.origin_custom_col_name === "Node"
                            ? true
                            : false
                        : false;
                obj.disabled =
                    restProps.editDataItem.custom_table_type === "H"
                        ? item.origin_custom_col_name === "Node Name" ||
                          item.origin_custom_col_name === "Node"
                            ? true
                            : false
                        : false;
                obj.isAddNewRow = false;
                obj.rowId = i;
                tempArray.push(obj);
                return 0;
            });
            setFormFields({
                ...formFields,
                in_custom_table_name: restProps.editDataItem.custom_table_name,
                in_custom_table_desc: restProps.editDataItem.custom_table_desc,
            });
            setCustomTableType(
                restProps.editDataItem.custom_table_type === "S" ||
                    restProps.editDataItem.custom_table_type === null
                    ? "standard"
                    : "hierarchical"
            );
            setData([
                ...tempArray.sort(
                    (a, b) => b.in_custom_col_order - a.in_custom_col_order
                ),
            ]);
            setOriginalData(JSON.parse(JSON.stringify(tempArray)));
            setLoadingState({
                ...loadState,
                isInitialLoading: false,
                isDeleteLoading: false,
            });
        } else {
            setLoadingState({
                ...loadState,
                isInitialLoading: false,
            });
        }
    };

    React.useEffect(() => {
        setVisible(restProps.visible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ShowError = React.useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const onClosePopup = () => {
        setVisible(!visible);
        restProps.setShow({ ...restProps.visible, dtuLogs: false });
        // TODO : UNSAVED CHANGES
        //   dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
    };

    const handleValidation = (dataItem) => {
        let configError = {};
        let isValid = true;
        if (isValid) {
            isValid = false;
            errorObj.customTableValidate =
                data.length === 0 ? MessageSchema.customTableRowsValidate : "";
            if (data.length > 0) {
                isValid = false;
                let isCheckedKey = data.filter((x) => x.in_is_key === 1);
                if (isCheckedKey.length > 0) {
                    isValid = true;
                }
                errorObj.customTableValidate =
                    isCheckedKey.length === 0
                        ? MessageSchema.customTableRowsRequired
                        : "";
            }
        }
        data.map((item, key) => {
            if (item.in_custom_col_name.trim() === "") {
                configError[
                    `in_custom_col_name_${item.rowId}`
                ] = `Name is required`;
                isValid = false;
            }
            if (item.in_custom_data_type === "") {
                configError[
                    `in_custom_data_type_${item.rowId}`
                ] = `Data type is required`;
                isValid = false;
            }
            return 0;
        });

        setConfigErrorObj({ ...configError });
        if (formFields.in_custom_table_name === "") {
            errorObj.in_custom_table_name =
                MessageSchema.customTableNameRequired;
            isValid = false;
        }
        setErrorObj((preVState) => ({ ...preVState, errorObj }));
        return isValid;
    };

    const insertUpdateCustomTablesRows = () => {
        let tempData = [];
        data.filter((x) => x.inEditItem === true).map((item) => {
            let obj = {};
            if (item.isAddNewRow) {
                delete item.in_custom_col_mstr_key;
            }
            obj["in_user_id"] = cookiesData.out_user_id;
            obj["in_tenant_id"] = cookiesData.out_tenant_id;
            obj["in_custom_table_mstr_key"] =
                restProps.editDataItem.custom_table_mstr_key;
            obj["in_custom_col_mstr_key"] = item.in_custom_col_mstr_key || "";
            obj["in_custom_col_name"] = item.in_custom_col_name;
            obj["in_custom_col_desc"] = item.in_custom_col_desc;
            obj["in_custom_col_order"] = item.in_custom_col_order;
            obj["in_custom_data_type"] = item.in_custom_data_type
                ? dataTypes.find(
                      (x) => x.data_type_name === item.in_custom_data_type
                  ).data_type_mstr_key
                : 0;
            obj["in_is_key"] = item.in_is_key ? 1 : 0;
            obj["in_version"] = item.version;
            tempData.push(obj);
            return 0;
        });
        const bodyData = {
            insertColumnsArray: [...tempData],
        };
        POSTAPI(
            false,
            INSERT_CUSTOM_TABLE_COLUMNS,
            null,
            generateHeader(),
            bodyData,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                handleCancel();
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
            }
        });
    };

    const handleCancel = () => {
        setVisible(false);
        setIsEdit(false);
        restProps.setShow({ ...restProps.visible, editCustomTable: false });
        restProps.setCustomTableUpdate(true);
        setChange(false);
        // TODO : UNSAVED CHANGES
        //   dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
    };

    const handleCancelBtn = () => {
        setIsEdit(false);
        setFormFields({
            ...formFields,
            in_custom_table_desc: restProps.editDataItem.custom_table_desc,
            in_custom_table_name: restProps.editDataItem.custom_table_name,
        });
        setData([...originalData]);
        setErrorObj({});
        restProps.setShow({ ...restProps.visible, editCustomTable: false });
    };

    const exitEdit = (dataItem, index, dataIndex, e) => {
        const newData = data.map((item) => ({
            ...item,
            [EDIT_FIELD]: undefined,
        }));
        if (editData) {
            if (JSON.stringify(editData) === JSON.stringify(dataItem)) {
                setEditData("");
                setData(newData);
            }
        } else {
            setData(newData);
        }
    };

    const enterEdit = (dataItem, field) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false;
        }

        let newData = data.map((item) => ({
            ...item,
            inEdit:
                item.in_custom_col_mstr_key === dataItem.in_custom_col_mstr_key
                    ? field
                    : undefined,
        }));
        setData(newData);
    };

    const customCellRender = (td, props) => {
        return (
            <CellRender
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={EDIT_FIELD}
            />
        );
    };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={EDIT_FIELD}
            errorObj={errorObj}
            exitEdit={exitEdit}
        />
    );

    const DropDownCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            const isInEdit = field === dataItem.inEdit;
            const rowId = dataItem.rowId;
            // const value = field && dataItem[field] ? dataItem[field] : "";
            let valueData = dataItem.dataTypes;
            let dataValue = "";
            if (props && valueData && valueData.length > 0) {
                let value = valueData?.find(
                    (x) => x.data_type_name === dataItem[props.field]
                );
                dataValue = value ? value.data_type_name : "";
            }
            const handleChange = (e) => {
                if (props.onChange) {
                    setChange(true);
                    props.onChange({
                        dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value.data_type_name,
                    });
                }
            };
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <div>
                            <DropDownList
                                style={{
                                    width: "150px",
                                }}
                                popupSettings={{
                                    height: "100px",
                                }}
                                onChange={handleChange}
                                value={
                                    valueData &&
                                    valueData.find(
                                        (c) => c.data_type_name === dataValue
                                    )
                                }
                                data={valueData}
                                textField="data_type_name"
                                placeholder={"Select"}
                                disabled={
                                    dataItem.disabled
                                        ? dataItem.disabled
                                        : false
                                }
                            />
                        </div>
                    ) : (
                        dataValue.toString()
                    )}
                    {Object.keys(configErrorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(configErrorObj).length > 0
                                ? configErrorObj[`${field}_${rowId}`]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        [configErrorObj]
    );

    const checkBoxCell = (props) => {
        const { dataItem } = props;

        const handleChange = (e) => {
            if (props.onChange) {
                props.onChange({
                    dataIndex: 0,
                    dataItem: props.dataItem,
                    field: props.field,
                    syntheticEvent: e.syntheticEvent,
                    value: e.target.checked ? 1 : 0,
                });
            }
        };
        return (
            <td title={dataItem[props.field]}>
                <input
                    type="checkbox"
                    onChange={handleChange}
                    value={dataItem[props.field]}
                    checked={dataItem[props.field]}
                    disabled={
                        restProps.pageProps.writeAccess === 0
                            ? true
                            : dataItem.disabled
                            ? dataItem.disabled
                            : false
                    }
                />
            </td>
        );
    };

    const itemChange = React.useCallback(
        (event) => {
            const field = event.field || "";
            const newData = data.map((item) =>
                item.in_custom_col_mstr_key ===
                event.dataItem.in_custom_col_mstr_key
                    ? {
                          ...item,
                          [field]: event.value,
                          inItemEdit: true,
                          selected: true,
                          inEditItem: true,
                      }
                    : { ...item, selected: false, inItemEdit: false }
            );
            let editItem = event.dataItem;
            editItem[field] = event.value;
            delete editItem.inEdit;
            setEditData(editItem);
            setData(newData);
            setIsEdit(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const onDeleteConfirm = (selectedKey) => {
        let isNewData = false;
        let deleteId = "";
        const deleteArray = deleteSelectedIds
            .filter((x) => x.id === selectedKey)
            .map((obj2) => {
                let id = obj2?.id ? obj2?.id : obj2;
                const obj1 = data.find(
                    (elem) => elem.in_custom_col_mstr_key === id
                );
                isNewData = obj1?.isAddNewRow ? true : false;
                deleteId = obj1?.in_custom_col_mstr_key;
                return {
                    id: obj2.id,
                };
            });
        if (isNewData) {
            let newData = [...data];
            let index = newData.findIndex(
                (x) => x.in_custom_col_mstr_key === deleteId
            );
            newData.splice(index, 1);
            let ids = deleteSelectedIds.filter((x) => x !== deleteId);
            setDeleteSelectedIds(ids);
            setData(newData);
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
                type: "",
                actionBtn: [],
                data: "",
                action: "",
            });
            setConfigErrorObj({});
            let editData = newData.filter((x) => x.inItemEdit === true);
            if (editData.length > 0) {
                setIsEdit(true);
            } else {
                setIsEdit(false);
            }
        } else {
            setLoadingState({
                ...loadState,
                isDeleteLoading: true,
            });
            let deleteData = {
                // in_user_id: cookiesData.out_user_id,
                // in_custom_col_mstr_key: deleteArray,
                // in_tenant_id: cookiesData.out_tenant_id,
                in_user_id: cookiesData.out_user_id,
                in_custom_table_mstr_key:
                    restProps.editDataItem.custom_table_mstr_key,
                in_tenant_id: cookiesData.out_tenant_id,
            };

            Action.FlushCustomTableDataRowsAsync(deleteData).then(function (
                result
            ) {
                if (result === "success") {
                    let deleteReqData = {
                        in_user_id: cookiesData.out_user_id,
                        in_custom_col_mstr_key: deleteArray,
                        in_tenant_id: cookiesData.out_tenant_id,
                    };
                    Action.deleteCustomTableDataRowsAsync(deleteReqData).then(
                        function (result1) {
                            if (result1 === "success") {
                                setConfigErrorObj({});
                                setDeleteSelectedIds([]);
                                setVisible(false);
                                let newData = data.filter(
                                    (x) => x.isAddNewRow === true
                                );
                                if (newData.length > 0) {
                                    setData([...newData]);
                                    setIsEdit(true);
                                } else {
                                    setIsEdit(false);
                                }
                                getDataTypesList();
                            }
                        }
                    );
                }
            });
        }
        setErrorObj({});
    };

    const remove = (props) => {
        let savedDataList = data.filter(
            (x) =>
                typeof x.in_custom_col_mstr_key === "number" &&
                x.isAddNewRow !== true
        );
        let isRemoveData = true;
        let originalDataKeyLength = originalData.filter(
            (x) => x.in_is_key === 1
        ).length;
        let originalKeyData = originalData.filter((x) => x.in_is_key === 1);
        originalKeyData.map((item) => {
            if (item[DATA_ITEM_KEY] === props[DATA_ITEM_KEY]) {
                if (originalDataKeyLength === 1) {
                    isRemoveData = false;
                }
            }
            return 0;
        });
        if (savedDataList.length === 1 && props.isAddNewRow === false) {
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: true,
                type: "DELETE_CONFIRM",
                title: "Warning",
                desc: "At Least one Key element is require",
                actionBtn: WARNING_BTN_ACTIONS(
                    onCancelConfirm,
                    onDeleteConfirm
                ),
                data: props.in_custom_col_mstr_key,
                action: "Delete",
            });
        } else {
            if (isRemoveData) {
                if (
                    deleteSelectedIds.findIndex(
                        (x) =>
                            x.in_custom_col_mstr_key ===
                            props.in_custom_col_mstr_key
                    ) === -1
                ) {
                    deleteSelectedIds.push({
                        id: props.in_custom_col_mstr_key,
                    });
                }
                if (props.isAddNewRow) {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: true,
                        type: "DELETE_CONFIRM",
                        title: "Confirmation",
                        desc: "Are you sure you want to delete this field?",
                        actionBtn: [
                            {
                                name: "Cancel",
                                onClick: onCancelConfirm,
                            },
                            {
                                name: "Yes",
                                onClick: () => {
                                    onDeleteConfirm(
                                        props.in_custom_col_mstr_key
                                    );
                                },
                            },
                        ],
                        data: props.in_custom_col_mstr_key,
                        action: "Delete",
                    });
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: true,
                        type: "DELETE_CONFIRM",
                        title: "Confirmation",
                        desc: "Are you sure you want to delete this field?, These changes will flush the existing custom table data. Are you sure you want to continue?",
                        actionBtn: [
                            {
                                name: "Cancel",
                                onClick: onCancelConfirm,
                            },
                            {
                                name: "Yes",
                                onClick: () => {
                                    onDeleteConfirm(
                                        props.in_custom_col_mstr_key
                                    );
                                },
                            },
                        ],
                        data: props.in_custom_col_mstr_key,
                        action: "Delete",
                    });
                }
            } else {
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: true,
                    type: "DELETE_CONFIRM",
                    title: "Warning",
                    desc: "At Least one Key element is require",
                    actionBtn: WARNING_BTN_ACTIONS(
                        onCancelConfirm,
                        onDeleteConfirm
                    ),
                    data: props.in_custom_col_mstr_key,
                    action: "Delete",
                });
            }
        }
    };

    const ActionCell = (props) => {
        return (
            <td className="text-center" title="Delete">
                {!props.dataItem.showDeleteIcon ? (
                    <button
                        disabled={
                            restProps.pageProps.writeAccess === 0 ? true : false
                        }
                        className="k-button k-grid-save-command delete-i"
                        onClick={() => {
                            remove(props.dataItem);
                        }}
                    >
                        <i title="Delete" className="far fa-trash-alt"></i>
                    </button>
                ) : (
                    <span></span>
                )}
            </td>
        );
    };

    const onCancelConfirm = () => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        setDeleteSelectedIds([]);
    };

    const addNewRow = async () => {
        setChange(true);
        setErrorObj({});

        let dataIndex =
            data.length > 0 ? data.length - 1 : data.length === 0 ? 0 : -1;

        if (dataIndex > -1) {
            let isValid =
                data.length > 0 ? checkEmptyColumns(data[0]) : !data.length;
            if (isValid) {
                let tempData = data.map((item) => ({
                    ...item,
                    selected: false,
                }));
                let initObj = JSON.parse(JSON.stringify(initialObj));
                initObj.in_custom_col_mstr_key = microTime();
                initObj.in_custom_col_order = data[0]
                    ? data[0].in_custom_col_order + 1
                    : 1;
                initObj.dataTypes = dataTypes || [];
                initObj.inEdit = "in_custom_col_name";
                initObj.isAddNewRow = true;
                initObj.inItemEdit = true;
                initObj.inEditItem = true;
                tempData.unshift(initObj);
                let index = tempData.findIndex(
                    (x) =>
                        x.in_custom_col_mstr_key ===
                        initObj.in_custom_col_mstr_key
                );
                tempData[index].rowId = tempData.length - 1;
                tempData[index].selected = false;
                let findIndex = tempData.findIndex(
                    (x) =>
                        x.inItemEdit === true &&
                        x.in_custom_col_mstr_key !==
                            initObj.in_custom_col_mstr_key
                );
                if (findIndex > -1) {
                    tempData[findIndex].inEdit = undefined;
                }
                setData([...tempData]);
                const elements = document.getElementsByClassName("table-input");
                if (elements.length) {
                    elements[0].focus();
                }
            }
        }
        setIsEdit(true);
    };

    const onWarningConfirm = (event) => {
        let deleteData = {
            in_user_id: cookiesData.out_user_id,
            in_custom_table_mstr_key:
                restProps.editDataItem.custom_table_mstr_key,
            in_tenant_id: cookiesData.out_tenant_id,
        };

        Action.FlushCustomTableDataRowsAsync(deleteData).then(function (
            result
        ) {
            if (result === "success") {
                insertUpdateCustomTablesRows();
                setDeleteSelectedIds([]);
                setVisible(false);
            }
        });

        setErrorObj({});
        setIsEdit(false);
    };

    const handleUniqueValid = () => {
        let isValid = true;
        let configError = {};
        const nameMap = new Map();
        for (const item of data) {
            const lowerCaseName = item.in_custom_col_name.toLowerCase().trim();

            if (nameMap.has(lowerCaseName)) {
                data.filter((x) => x.inItemEdit === true).map((item) => {
                    configError[
                        `in_custom_col_name_${item.rowId}`
                    ] = `Name should be unique`;
                    isValid = false;
                    return 0;
                });
                setConfigErrorObj({ ...configError });
            }
            nameMap.set(lowerCaseName, true);
        }

        return isValid;
    };

    const handleSubmit = async (dataItem) => {
        let isValid = handleValidation(dataItem);
        let uniqueValid = handleUniqueValid(formFields);
        if (isValid && uniqueValid) {
            if (isChange) {
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: true,
                    type: "DELETE_CONFIRM",
                    title: "Confirmation",
                    desc: "These changes will flush the existing custom table data. Are you sure you want to continue?",
                    actionBtn: DELETE_BTN_ACTIONS(
                        onCancelConfirm,
                        onWarningConfirm
                    ),
                    data: "",
                    action: "Delete",
                });
            } else {
                let updatedDataList = [];
                let obj = {};
                obj.in_user_id = cookiesData.out_user_id.toString();
                obj.in_tenant_id = cookiesData.out_tenant_id;
                obj.in_custom_table_mstr_key =
                    restProps.editDataItem.custom_table_mstr_key;
                obj.in_custom_table_name =
                    formFields.in_custom_table_name.trim();
                obj.in_custom_table_desc =
                    formFields.in_custom_table_desc.trim();
                obj.in_custom_table_type =
                    customTableType === "standard" || customTableType === null
                        ? "S"
                        : "H";
                updatedDataList.push(obj);
                const bodyData = {
                    updateCustomTableData: [...updatedDataList],
                };
                let res = await Action.updateDataAsync(bodyData);
                if (
                    res &&
                    data.filter((x) => x.inEditItem === true).length === 0
                ) {
                    handleCancel();
                }
                if (data.filter((x) => x.inEditItem === true).length > 0) {
                    await insertUpdateCustomTablesRows();
                }
            }
        }
    };

    const handleChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.value ? e.value : e.target.value || "",
        });
        setErrorObj({
            ...errorObj,
            [e.target.name]: "",
        });
        setIsEdit(true);
    };

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;
            const rowId = dataItem.rowId;
            let value = props.field;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Input
                            disabled={
                                dataItem.disabled ? dataItem.disabled : false
                            }
                            value={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                            className="table-input"
                            autoFocus={true}
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                    {Object.keys(configErrorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(configErrorObj).length > 0
                                ? configErrorObj[`${value}_${rowId}`]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [configErrorObj]
    );

    const NumberCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value:
                            parseFloat(e.target.value) < 0 ? 0 : e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <NumericTextBox
                            disabled={
                                dataItem.disabled ? dataItem.disabled : false
                            }
                            value={dataItem[props.field]}
                            format={"#"} // No decimal places allowed
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={
                        column.field === "expand"
                            ? ActionCell
                            : column.field === "in_custom_data_type"
                            ? DropDownCell
                            : column.field === "in_is_key"
                            ? checkBoxCell
                            : column.field === "in_custom_col_order"
                            ? NumberCell
                            : NameCell
                    }
                    sortable={false}
                    resizable={column.field === "expand" ? false : true}
                    width={column.field === "expand" ? 50 : column.minWidth}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let CustomColumn = useCol(gridColumns);

    const handleStageChange = (e) => {
        setStateChange(e.state);
        if (e.state === "MINIMIZED") {
            setModal(false);
        } else if (e.state === "DEFAULT") {
            setModal(true);
        }
    };

    const removeKeysFromArray = (array, keysToRemove) => {
        return array.map((obj) => {
            let newObj = { ...obj };
            keysToRemove.forEach((key) => {
                delete newObj[key];
            });
            return newObj;
        });
    };

    const disabledSaveBtn = () => {
        const keysToRemove = [
            "inItemEdit",
            "selected",
            "inEditItem",
            "dataTypes",
            "inEdit",
        ];
        let currentData = removeKeysFromArray(
            JSON.parse(JSON.stringify(data)),
            keysToRemove
        );
        let originData = removeKeysFromArray(
            JSON.parse(JSON.stringify(originalData)),
            keysToRemove
        );
        if (
            JSON.stringify(currentData) === JSON.stringify(originData) &&
            formFields?.in_custom_table_name ==
                restProps?.editDataItem?.custom_table_name &&
            formFields?.in_custom_table_desc ==
                restProps?.editDataItem?.custom_table_desc
        ) {
            return true;
        }
        return !isEdit;
    };

    return (
        <>
            <Window
                className={"Dialog-dtulogs Dialog-lModal"}
                title={<ModalTitle />}
                onClose={onClosePopup}
                width={1124}
                height={640}
                resizable={false}
                stage={stageChange}
                onStageChange={handleStageChange}
                modal={modal}
            >
                <Form
                    render={() => (
                        <FormElement
                            className="addcustomtableform"
                            horizontal={true}
                        >
                            <FieldWrapper>
                                <fieldset className={"k-form-fieldset"}>
                                    <div
                                        className={
                                            "k-form-field-wrap-popup customtabtype"
                                        }
                                    >
                                        <Label>{"Custom Table Type : "}</Label>
                                        {customTableType && (
                                            <div className="disableinputtext">
                                                {customTableType === "standard"
                                                    ? "Standard"
                                                    : "Hierarchical"}
                                            </div>
                                        )}
                                    </div>
                                </fieldset>
                            </FieldWrapper>

                            <FieldWrapper>
                                <fieldset className={"k-form-fieldset"}>
                                    <div className={"k-form-field-wrap-popup "}>
                                        <Label className="customtable200">
                                            {"Custom Table Name*:"}
                                        </Label>
                                        <Input
                                            name={"in_custom_table_name"}
                                            value={
                                                formFields.in_custom_table_name
                                            }
                                            onChange={handleChange}
                                            autoFocus={false}
                                            disabled={
                                                restProps.pageProps
                                                    .writeAccess === 0
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <ShowError
                                            name={"in_custom_table_name"}
                                        />
                                    </div>
                                </fieldset>
                            </FieldWrapper>

                            <FieldWrapper>
                                <fieldset className={"k-form-fieldset "}>
                                    <div
                                        className={
                                            "k-form-field-wrap-popup customtablediscriptionflex"
                                        }
                                    >
                                        <Label>{"Description:"}</Label>
                                        <TextArea
                                            type={"text"}
                                            name={"in_custom_table_desc"}
                                            value={
                                                formFields.in_custom_table_desc
                                            }
                                            rows={1}
                                            onChange={handleChange}
                                            disabled={
                                                restProps.pageProps
                                                    .writeAccess === 0
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </fieldset>
                            </FieldWrapper>
                        </FormElement>
                    )}
                />
                <div className="error-bottom">
                    <ShowError name={"customTableValidate"} />
                </div>

                <div className="inner-title">
                    <div className="popup-head mb-0">
                        <h3 className="popup-head-left">
                            Configure Custom Table
                        </h3>
                        {restProps.pageProps.writeAccess === 1 && (
                            <div className="popup-head-right">
                                <ul className="Quick-links">
                                    <li
                                        className="Add-new"
                                        onClick={() => {
                                            addNewRow();
                                        }}
                                    >
                                        <a className="cta-small">
                                            <span>
                                                <i className="fas fa-plus"></i>
                                                Add New Field
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="warning-message-custom-table-popup">
                        <Label>
                            <p>Warning:</p> column name or data type changes
                            might break configured rules and formulas in jobs.
                            Recommended action is to identify all impacted rules
                            and formulas and update as needed to sync with
                            custom table fields
                        </Label>
                    </div>
                </div>
                {loadState.isDeleteLoading && <LoadingPanel />}
                {!loadState.isInitialLoading ? (
                    <div className="configuretable">
                        <DataTable
                            isAPICalling={false}
                            initialLoading={true}
                            data={data}
                            id={DATA_TABLE_ID}
                            editField={editField}
                            customColumn={CustomColumn}
                            columns={gridColumns}
                            dataItemKey={DATA_ITEM_KEY}
                            gridHeight={300}
                            module={"custom_table"}
                            rowRender={customRowRender}
                            cellRender={customCellRender}
                            itemChange={itemChange}
                            isPagination={false}
                            reorderable={false}
                        />
                    </div>
                ) : (
                    <LoadingPanel />
                )}
                <WindowActionsBar layout={"end"}>
                    <Button
                        type={"submit"}
                        className="k-button cancelbtn"
                        onClick={handleCancelBtn}
                    >
                        Cancel
                    </Button>
                    <Button
                        type={"submit"}
                        className="primarybtn k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        disabled={disabledSaveBtn()}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </WindowActionsBar>
            </Window>

            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};
export default React.memo(EditCustomTable);
