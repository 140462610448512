import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { userGridSelector } from "../../../redux/selectors/custom-selector";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import schema from "./schema/sendEmail";
import { sendEmailValidator } from "./validator";
import { getUserCredential } from "../../../lib/cookies";
import {
    GetCompPlanDataApi,
    GetGroupNameAPI,
} from "../../../lib/common-service";
import useServiceApi from "../service";
import {
    MULTI_SELECT_ALL_TEXT,
    MULTI_SELECT_ALL_ID,
} from "src/constants/constants";

const SendEmailForm = (restProps) => {
    const cookiesData = getUserCredential();
    const history = useHistory();
    const dispatch = useDispatch();
    const userGridReduxData = useSelector(userGridSelector, shallowEqual);
    const [List, Action] = useServiceApi();

    const { isRefreshLoading } = List;
    const [formSchema, setFormSchema] = React.useState(schema);

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const multiSelectDefaultVal = [
        {
            id: MULTI_SELECT_ALL_ID,
            text: MULTI_SELECT_ALL_TEXT,
        },
    ];

    React.useEffect(() => {
        setDataSource();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const onBoaringVal = formSchema.in_is_onboarding?.value;
        const statusVal = formSchema.in_user_status_value?.value;

        let newChangeElementObj = {
            in_is_onboarding: true,
            in_is_attach: true,
            in_subject: false,
            in_message: true,
        };

        if (!statusVal) {
            newChangeElementObj.in_is_onboarding = false;
            newChangeElementObj.in_subject = true;
        }

        //update onboarding false if status dropdown contains select all or active
        if (
            statusVal &&
            statusVal.find((elem) =>
                [MULTI_SELECT_ALL_TEXT, "active"].includes(elem.text)
            )
        ) {
            newChangeElementObj.in_is_onboarding = false;
            newChangeElementObj.in_subject = true;
        }

        if (
            statusVal &&
            statusVal.find((elem) => ["inactive"].includes(elem.text))
        ) {
            newChangeElementObj.in_subject = true;
        }

        //update attach, subject, message false if onboarding is visible and checked true
        if (
            newChangeElementObj.in_is_onboarding === true &&
            onBoaringVal === true
        ) {
            newChangeElementObj.in_is_attach = false;
            newChangeElementObj.in_subject = false;
            newChangeElementObj.in_message = false;
        }

        setFormSchema({
            ...formSchema,
            on_change_state: newChangeElementObj,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formSchema.in_is_onboarding?.value,
        formSchema.in_user_status_value?.value,
    ]);

    //set datasource into form json
    const setDataSource = async () => {
        //const roleDataSource = [];
        const roleDataSource = await getRoleDs();
        const groupDataSource = await GetGroupDs();
        const compPlanDataSource = await GetCompPlanDs();
        const statusDataSource = await getStatusDs();

        setFormSchema({
            ...formSchema,
            in_role_value: {
                ...formSchema.in_role_value,
                data: roleDataSource,
                originData: roleDataSource,
                value: roleDataSource.length ? multiSelectDefaultVal : [],
                loading: false,
            },
            in_group_value: {
                ...formSchema.in_group_value,
                data: groupDataSource,
                originData: groupDataSource,
                value: groupDataSource.length ? multiSelectDefaultVal : [],
                loading: false,
            },
            in_comp_plan_value: {
                ...formSchema.in_comp_plan_value,
                data: compPlanDataSource,
                originData: compPlanDataSource,
                value: compPlanDataSource.length ? multiSelectDefaultVal : [],
                loading: false,
            },
            in_user_status_value: {
                ...formSchema.in_user_status_value,
                data: statusDataSource,
                originData: statusDataSource,
                value: statusDataSource.length ? multiSelectDefaultVal : [],
                loading: false,
            },
        });
    };

    //get role data
    const getRoleDs = () => {
        const { roleData } = userGridReduxData.roleData;
        return roleData.filter(val=> val?.text?.toLowerCase() != "select all").map(({ value: id, ...rest }) => ({
            id,
            ...rest,
        }));
    };

    //get status data
    const getStatusDs = () => {
        const { userStatusData } = userGridReduxData.userStatusData;
        const elem1 = ["locked", "suspended", "select all"];

        return userStatusData
            .filter((e) => !elem1.includes(e.text.toLowerCase()))
            .map(({ value: id, ...rest }) => ({
                id,
                ...rest,
            }));
    };

    //call API to get comp plan data
    const GetCompPlanDs = async () => {
        const apiParam = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: 6,
            in_sort_order: "ASC",
            in_user_key: cookiesData.out_user_id,
            in_multi_sort_order: null,
        };

        let resDataObj = await GetCompPlanDataApi(apiParam, history, dispatch);
        let tempArray = [];

        resDataObj.map((item) => {
            let obj = {};
            obj.text = item.comp_plan_name;
            obj.id = item.comp_plan_mstr_key;
            tempArray.push(obj);
            return 0;
        });

        return tempArray;
    };

    //call API to get group data
    const GetGroupDs = async () => {
        let apiParam = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_user_role_filter: null,
            in_sort_number: 4,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };

        let resDataObj = await GetGroupNameAPI(apiParam, history, dispatch);
        let tempArray = [];

        resDataObj.map((item) => {
            let obj = {};
            obj.text = item.title_name;
            obj.id = item.org_title_key;
            tempArray.push(obj);
            return 0;
        });

        return tempArray;
    };

    const handleStatusChange = (e) => {
        setFormSchema({
            ...formSchema,
            [e.target.props.name]: {
                ...formSchema[e.target.props.name],
                value: e.value,
                data: formSchema[e.target.props.name].originData,
            },
        });
    };

    const handleOnBoardChange = (e) => {
        changeElement(e.target.name, e.value);
    };

    const changeElement = (name, data) => {
        setFormSchema({
            ...formSchema,
            [name]: {
                ...formSchema[name],
                value: data,
            },
        });
    };

    //on form submit
    const handleSubmit = async (dataItem) => {
        const changeElementObj = formSchema.on_change_state;

        const reqObj = {
            in_comp_plan_mstr_key: getMstrKeyFromObj(
                dataItem,
                "in_comp_plan_value"
            ),
            in_group_mstr_key: getMstrKeyFromObj(dataItem, "in_group_value"),
            in_db_role_mstr_key: getMstrKeyFromObj(dataItem, "in_role_value"),
            in_user_status_mstr_key: getMstrKeyFromObj(
                dataItem,
                "in_user_status_value"
            ),
            in_subject:
                changeElementObj.in_subject && dataItem.in_subject
                    ? dataItem.in_subject
                    : "",
            in_message:
                changeElementObj.in_message && dataItem.in_message
                    ? dataItem.in_message
                    : "",
            in_is_attach:
                changeElementObj.in_is_attach && dataItem.in_is_attach
                    ? dataItem.in_is_attach
                    : false,
            in_is_onboarding:
                changeElementObj.in_is_onboarding && dataItem.in_is_onboarding
                    ? dataItem.in_is_onboarding
                    : false,
        };

        let response = await Action.sendEmailAsync(reqObj);

        response.is_success && restProps.onClosePopup();
        response.is_update && restProps.setAPICall(true);
    };

    const getMstrKeyFromObj = (dataItemObj, key) => {
        let mstrKeys = [];

        if (!formSchema?.[key]?.data?.length) {
            return dataItemObj[key];
        }

        if (dataItemObj[key] === undefined) {
            mstrKeys.push(0);
            return mstrKeys;
        }

        mstrKeys.push(
            ...dataItemObj[key].map((obj) =>
                obj.id === MULTI_SELECT_ALL_ID ? 0 : obj.id
            )
        );

        return mstrKeys;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validator={(value) => {
                return sendEmailValidator(value, formSchema);
            }}
            render={(formRenderProps) => (
                <div className="formwrapper-cont">
                    {isRefreshLoading && loadingPanel}

                    <FormElement
                        className="formwrapper-form-cont"
                        horizontal={true}
                        id={"sendEmailForm"}
                    >
                        <div className="formwrapper-elm-cont">
                            <Field
                                setFormSchema={setFormSchema}
                                formSchema={formSchema}
                                {...formSchema.in_role_value}
                            />

                            <Field
                                setFormSchema={setFormSchema}
                                formSchema={formSchema}
                                {...formSchema.in_group_value}
                            />
                        </div>

                        <div className="formwrapper-elm-cont">
                            <Field
                                setFormSchema={setFormSchema}
                                formSchema={formSchema}
                                {...formSchema.in_comp_plan_value}
                            />

                            <Field
                                setFormSchema={setFormSchema}
                                formSchema={formSchema}
                                // onFieldChange={handleStatusChange}
                                {...formSchema.in_user_status_value}
                            />
                        </div>

                        {(formSchema?.on_change_state?.in_is_onboarding ||
                            formSchema?.on_change_state?.in_is_attach) && (
                                <div className="formwrapper-elm-cont">
                                    {formSchema?.on_change_state
                                        ?.in_is_onboarding && (
                                            <Field
                                                onFieldChange={handleOnBoardChange}
                                                {...formSchema.in_is_onboarding}
                                            />
                                        )}

                                    {formSchema?.on_change_state?.in_is_attach && (
                                        <Field {...formSchema.in_is_attach} />
                                    )}
                                </div>
                            )}

                        {formSchema?.on_change_state?.in_subject && (
                            <div className="formwrapper-elm-cont">
                                <Field {...formSchema.in_subject} />
                            </div>
                        )}

                        {formSchema?.on_change_state?.in_message && (
                            <div className="formwrapper-elm-cont">
                                <Field {...formSchema.in_message} />
                            </div>
                        )}
                        <div className="k-form-buttons"></div>
                    </FormElement>
                </div>
            )}
        />
    );
};

export default React.memo(SendEmailForm);
