import * as React from "react";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
import {
    CommonHeaderCellTemplate,
    AssignCheckboxHeaderCellTemplate,
    CustomDataRowsHeaderCellTemplate,
    EventEditableHeaderCellTemplate,
    ActionHeaderCellTemplate,
} from "./template/gridHeaderCellTemplate";
import {
    DisplayGroupFilterTemplate,
    ResultGroupFilterTemplate,
    ResultTypeFilterTemplate,
    BooleanFilterTemplate,
    KpiFilterTemplate,
    PeriodTypeFilterTemplate,
    EventTypeFilterTemplate,
    EventTableFilterTemplate,
    EventGroupTypeFilterTemplate,
    UnitTypeFilterTemplate,
    RuleResultNameFilterTemplate,
    CompElementFilterTemplate,
    JobTypeFilterTemplate,
    JobParamsFilterTemplate,
    SystemGroupFxFilterTemplate,
    RuleTypeFilterTemplate,
    UserStatusTypeFilterTemplate,
    DbRoleFilterTemplate,
    CustomTableTypeFilterTemplate,
    ApprovalStatusKeyTemplate,
    RoleTypeFilterTemplate,
    NodeTypeFilterTemplate,
    objTypeFilterTemplate,
    creditUnitTypeFilterTemplate,
} from "./template/filterColumnMenuTemplate";

export const CustomColumnMenu = (props) => {
    const [columnsExpanded, setColumnsExpanded] = React.useState(false);
    const onFilterExpandChange = (value) => {
        setColumnsExpanded(value ? false : columnsExpanded);
    };

    const getCustomFilterUI = (field) => {
        var filterData = props.filter;
        switch (field) {
            case "event_group_mstr_key":
                return (props) =>
                    EventGroupTypeFilterTemplate({ filterData, ...props });
            case "result_type_mstr_key":
                return (props) =>
                    ResultTypeFilterTemplate({ filterData, ...props });
            case "result_group_mstr_key":
                return (props) =>
                    ResultGroupFilterTemplate({ filterData, ...props });
            case "display_report":
                return (props) =>
                    DisplayGroupFilterTemplate({ filterData, ...props });
            // case "period_type_name":
            case "period_type_mstr_key":
                return (props) =>
                    PeriodTypeFilterTemplate({ filterData, ...props });
            case "kpi_type_mstr_key":
                return (props) => KpiFilterTemplate({ filterData, ...props });
            case "bg_assigned":
            case "is_model":
            case "is_kpi_assign":
            case "is_position_assign":
            case "report_hyperlink":
            case "is_processed":
            case "is_assign":
                return (props) =>
                    BooleanFilterTemplate({ filterData, ...props });
            case "etl_event_type_mstr_key":
                return (props) =>
                    EventTypeFilterTemplate({ filterData, ...props });
            case "etl_table_mstr_key":
                return (props) =>
                    EventTableFilterTemplate({ filterData, ...props });
            case "system_unit_mstr_key":
                return (props) =>
                    UnitTypeFilterTemplate({ filterData, ...props });
            case "charge_amount_unittype":
                return (props) =>
                    creditUnitTypeFilterTemplate({ filterData, ...props });
            case "result_name_mstr_key":
                return (props) =>
                    RuleResultNameFilterTemplate({ filterData, ...props });
            case "comp_el_type_mstr_key":
                return (props) =>
                    CompElementFilterTemplate({ filterData, ...props });

            case "object_type":
                return (props) =>
                    objTypeFilterTemplate({ filterData, ...props });
            case "job_type_name":
            case "job_type_mstr_key":
                return (props) =>
                    JobTypeFilterTemplate({ filterData, ...props });
            case "status_type_mstr_key":
                return (props) =>
                    UserStatusTypeFilterTemplate({ filterData, ...props });
            case "db_role_mstr_key":
                return (props) =>
                    DbRoleFilterTemplate({ filterData, ...props });
            case "job_param":
                return (props) =>
                    JobParamsFilterTemplate({ filterData, ...props });
            case "unit_type_group_fx":
                return (props) =>
                    SystemGroupFxFilterTemplate({ filterData, ...props });
            case "rule_type_mstr_key":
                return (props) =>
                    RuleTypeFilterTemplate({ filterData, ...props });
            case "custom_table_type":
                return (props) =>
                    CustomTableTypeFilterTemplate({ filterData, ...props });
            case "approval_status_mstr_key":
                return (props) =>
                    ApprovalStatusKeyTemplate({ filterData, ...props });
            case "db_role_type_name":
                return (props) =>
                    RoleTypeFilterTemplate({ filterData, ...props });
            case "Node":
                return (props) =>
                    NodeTypeFilterTemplate({ filterData, ...props });
            default:
                return "";
        }
    };

    return (
        <GridColumnMenuFilter
            {...props}
            expanded={true}
            onExpandChange={onFilterExpandChange}
            filterUI={getCustomFilterUI(props.column.field)}
        />
    );
};

export const columnMenuProps = (columns, module) => {
    let filterType = null;
    let customHeaderCellTemplate = null;

    //set default date format
    if (columns.type === "date" && !columns.format) {
        columns.format = "{0:MM/dd/yyyy HH:mm:ss}";
    }

    //set default number format
    if (columns.type === "number" && !columns.format) {
        columns.format = "{0:0}";
    }

    switch (columns.field) {
        case "is_kpi_assign":
        case "is_position_assign":
        case "is_assign":
            customHeaderCellTemplate = (props) => (
                <AssignCheckboxHeaderCellTemplate
                    {...props}
                    aditionalProps={
                        columns.aditionalParam.assignCheckboxProps || {}
                    }
                />
            );
            break;
        default:
            customHeaderCellTemplate = CommonHeaderCellTemplate;
    }

    if (module === "custom_table_rows") {
        customHeaderCellTemplate = (props) => (
            <CustomDataRowsHeaderCellTemplate
                {...props}
                isRequired={columns.isRequired}
            />
        );
    }

    if (module === "event") {
        customHeaderCellTemplate = (props) => (
            <EventEditableHeaderCellTemplate
                {...props}
                isEditable={columns.isEditable}
            />
        );
    }

    switch (columns.type) {
        case "number":
            filterType = "numeric";
            break;
        case "boolean":
            filterType = "boolean";
            break;
        case "date":
            filterType = "date";
            break;
        default:
            filterType = "text";
    }

    let menuConfig = {
        field:
            module === "compensation_detail" && columns.alias
                ? `${columns.alias}$${columns.field}`
                : columns.field,
        title: columns.title,
        headerCell:
            columns.filterable == 1
                ? customHeaderCellTemplate
                : columns.field
                ? ActionHeaderCellTemplate
                : false,
        columnMenu: columns.filterable == 1 && CustomColumnMenu,
        filter: filterType,
        minResizableWidth: columns.minResizableWidth,
        encrypted: columns.encrypted,
        alias: columns.alias,
        hidden: columns.show,
        gname: columns.gname,
        show: columns.show,
    };

    if (columns.format) {
        menuConfig["format"] = columns.format;
    }

    if (columns.aggregates) {
        menuConfig["aggregates"] = columns.aggregates;
    }

    if (columns.groupHeaderTemplate) {
        menuConfig["groupHeaderTemplate"] = columns.groupHeaderTemplate;
    }

    if (columns.groupFooterTemplate) {
        menuConfig["groupFooterTemplate"] = columns.groupFooterTemplate;
    }

    return menuConfig;
};
