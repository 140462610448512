import React, { useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { userNameValidator } from "./validator";
import schema from "./schema/userName";
import useServiceApi from "../service";
import {
    clearLocalStorage,
    getLocalStorage,
    // clearLocalStorage,
    removeOtpData,
    setLocalStorage,
} from "../../../lib/cookies";
import { CreateUserLocalStorage } from "src/utils/loginUtils";
import { useHistory, useParams, useLocation } from "react-router-dom";

const UserNameForm = () => {
    const history = useHistory();
    const param = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectPath = searchParams.get("redirect");

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const [List, Action] = useServiceApi();
    const formSchema = schema;
    const { isRefreshLoading } = List;

    const processEnv = process.env;
    const localStorageData = getLocalStorage(
        processEnv.REACT_APP_CREDENTIAL_KEY
    );

    const [userName, setUserName] = useState(localStorageData?.out_user_name);
    const initialValues = {
        in_username: userName,
    };

    React.useEffect(() => {
        removeOtpData();
    }, [param]);

    //on form submit
    const handleSubmit = async (dataItem) => {
        setUserName(dataItem.in_username)
        clearLocalStorage();
        const reqObj = {
            in_username: dataItem.in_username ? dataItem.in_username : "",
        };

        let response = await Action.accessUserNameAsync(reqObj);
        if (response?.out_secret_token) {
            CreateUserLocalStorage({ out_user_name: dataItem.in_username });
            CreateUserLocalStorage(response);
            setLocalStorage('sso_window', true)
            if (redirectPath) {
                history.push(`/authentication?redirect=${redirectPath}`);
            } else {
                history.push(`/authentication`);
            }
        }
    };

    const RenderUserNameForm = () => {
        return (
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                ignoreModified={true}
                validator={(value) => {
                    return userNameValidator(value, formSchema);
                }}
                render={(formRenderProps) => (
                    <div className="form">
                        {isRefreshLoading && loadingPanel}

                        <div className="formwrapper-elm-cont">
                            <FormElement
                                className="formwrapper-form-cont"
                                horizontal={true}
                                id={"userNameForm"}
                            >
                                <div className="formwrapper-elm-cont">
                                    <Field
                                        {...formSchema.in_username}
                                        value={userName}
                                    />
                                </div>
                                <div className="k-form-buttons"></div>
                            </FormElement>
                        </div>
                        <div className="form-button line">
                            <Button
                                type={"submit"}
                                className="btn-active bttn"
                                form="userNameForm"
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            />
        );
    };

    // const signOut = () => {
    //     clearLocalStorage();
    //     window.location.href = "/login";
    // };

    return <RenderUserNameForm />;
};

export default React.memo(UserNameForm);
