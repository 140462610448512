const env = process.env.REACT_APP_ENV_TYPE;

let projectSetting = {};
if (env === "dev") {
    projectSetting = {
        baseURL: "https://client.procareportal.com/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "ws://devbkup.procareportal.com:8181",
    };
} else if (env === "devbkup") {
    projectSetting = {
        baseURL: "https://client.procareportal.com/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "ws://devbkup.procareportal.com:8181",
    };
} else if (env === "qabkup") {
    projectSetting = {
        baseURL: "http://qabkup.procareportal.com:8181/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "ws://qabkup.procareportal.com:8181",
    };
} else if (env === "qa") {
    projectSetting = {
        baseURL: "https://client.procareportal.com/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "wss://client.procareportal.com",
    };
} else if (env === "demo") {
    projectSetting = {
        baseURL: "http://sandbox.procareportal.com:8181/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "ws://sandbox.procareportal.com:8181",
    };
} else if (env === "demobkup") {
    projectSetting = {
        baseURL: "http://sandboxbkup.procareportal.com:8181/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "ws://sandboxbkup.procareportal.com:8181",
    };
} else if (env === "clientbkup") {
    projectSetting = {
        baseURL: "http://clientbkup.procareportal.com:8181/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "ws://clientbkup.procareportal.com:8181",
    };
} else if (env === "localhost") {
    projectSetting = {
        baseURL: "http://qa.procareportal.local/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "ws://qa.procareportal.local",
    };
} else if (env === "dev2") {
    projectSetting = {
        baseURL: "https://dev2.procareportal.com/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "wss://dev2.procareportal.com",
    };
} else {
    projectSetting = {
        baseURL: "https://client.procareportal.com/",
        backend: "backend/",
        version: "v1",
        valid_otp_time: "05:00",
        socket_url: "wss://client.procareportal.com",
    };
}

export default projectSetting;
