import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import DataTable from "../../components/dataTable";
import {
    GridColumn as Column,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import { Button } from "@progress/kendo-react-buttons";
import {
    DEFAULT_SORT,
    USER_GRID_DEFAULT_PAGE_SIZE,
    USER_GRID_PAGE_SIZES,
} from "../../constants/grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import { removeMessage } from "../../redux/actions/setting-actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DELETE_BTN_ACTIONS, REPORT_DISPLAY } from "../../constants/constants";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { useHistory } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CellRender, RowRender } from "../../components/renderers";
import serviceData from "./service";
import {
    getfilterDataString,
    changeCancelBtnState,
    changeUpdateBtnState,
    // isSameRow,
    replaceBlankAndMerge,
    microTime,
    executeGetColumnOrderInfo,
    debounceForFunc,
} from "../../utils/utils";
import { getter } from "@progress/kendo-data-query";
import { DialogComponent } from "../../components/dialog";
import { setResultSettingData } from "../../redux/actions/rule-library-actions";
import { resultSettingSelector } from "../../redux/selectors/custom-selector";
import { updateColumnReorderAsync } from "src/lib/common-service";

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

const editField = "inEdit";
const DATA_ITEM_KEY = "result_name_mstr_key";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const initialSort = [
    {
        field: "user_first_name",
        dir: "asc",
    },
];

const ManageResult = forwardRef((restProps, ref) => {
    const resultSettingReduxData = useSelector(
        resultSettingSelector,
        shallowEqual
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const [list, Action] = serviceData();
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [sort, setSort] = React.useState(initialSort);
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const [errorObj, setErrorObj] = React.useState({});
    const [selectedState, setSelectedState] = React.useState({});
    const [exportData, setExportData] = React.useState([]);
    const [selectedRowId, setSelectedRowId] = React.useState("");
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });
    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [gridColumns, setGridColumns] = React.useState([]);

    // Get APIdata from service file
    const {
        data,
        columns,
        dataCount,
        setData,
        setColumns,
        originalData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setAPICallingState,
        initialLoading,
        noDataFound,
        isRefreshLoading,
        resultTypeFields,
        resultGroupData,
        autoScrollIndex,
        setAutoScrollIndex,
    } = list;

    const apiCallData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_load_type: "data-reload",
    };

    if (document.getElementById("grid-incell-cancel")) {
        document.getElementById("grid-incell-cancel").onclick = function (e) {
            dispatch(
                removeMessage({
                    message: "",
                    type: "Error",
                    show: false,
                })
            );
            originalData.map((item) => {
                item.inEdit = false;
                item.selected = false;
                return 0;
            });
            setData(originalData);
            setErrorObj({});
            restProps.setDataList(originalData);
            restProps.setAddnew(false);
            // restProps.setSelectedRowId("");
            // setSelectedState({});

            //disable in-cell update and cancel button
            e.target.disableKendoBtn();
            // TODO : UNSAVED CHANGES
            // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
            changeUpdateBtnState();
            changeUpdateBtnState("enable", "dialog-btn_1");
        };
    }

    if (document.getElementById("grid-incell-update")) {
        document.getElementById("grid-incell-update").onclick = function (e) {
            handleUpdate();
        };
    }

    React.useEffect(() => {
        restProps.setDataList(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (restProps.isAPICall) {
            Action.getResultTypeList();
            restProps.setAPICall(false);
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAPICall]);

    React.useEffect(() => {
        if (restProps.isCheckColumn) {
            setColumns(restProps.columnsData);
            restProps.setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isCheckColumn]);

    React.useEffect(() => {
        getResultNameList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getResultNameList = async () => {
        const dataVal = {
            ...apiCallData,
            in_load_type: "grid-reload",
        };

        let response = await Action.getResultList(
            dataVal,
            restProps.defaultResultNameKey
        );

        if (response.length === 0) return;

        let defaultResultNameKey =
            restProps.defaultResultNameKey || response[0].result_name_mstr_key;
        let selectedKey = defaultResultNameKey;
        if (
            restProps.showNameResultPopup &&
            restProps.defaultResultNameKey === null
        ) {
            selectedKey = response[0].result_name_mstr_key;
        }
        if (restProps.showNameResultPopup) {
            let obj = {
                [selectedKey]: true,
            };
            setSelectedRowId(selectedKey);
            setSelectedState(obj);
            restProps.setSelectedRowId(selectedKey);
            setDeleteSelectedIds([{ id: selectedKey }]);
            let tempArray = [];
            let selectObj = {
                id: Number(selectedKey),
            };
            tempArray.push(selectObj);
            restProps.setSelectedArray(tempArray);
        }

        let selectedResult = response.find(
            (x) => x.result_name_mstr_key === selectedKey
        );
        /*
        setAutoScrollIndex(
            response.findIndex((x) => x.result_name_mstr_key === selectedKey)
        );
        */

        dispatch(
            setResultSettingData({
                resultSettingData: {
                    ...resultSettingReduxData.resultSettingData,
                    selected_result_name: selectedResult.result_name,
                    selected_result_name_key: selectedKey,
                },
            })
        );
    };

    React.useEffect(() => {
        // setTimeout(function () {
        Action.getResultGroup();
        Action.getResultTypeList();
        // }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (list.loadingState.isDeleteData) {
            list.setLoadState({ ...list.loadingState, isDeleteData: false });
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isDeleteData]);

    React.useEffect(() => {
        if (list.loadingState.isSaveData) {
            list.setLoadState({ ...list.loadingState, isSaveData: false });
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isSaveData]);

    React.useEffect(() => {
        if (columns.length > 0) {
            restProps.setDataList(data);
            restProps.setColumnsData(columns);
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        if (restProps.isCheckColumn) {
            setGridColumns(restProps.columnsData);
            restProps.setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isCheckColumn]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isShow.deleteRows]);

    React.useEffect(() => {
        if (restProps.copyDataClick) {
            handleCopyData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.copyDataClick]);

    const onRowClick = (dataItem) => {
        if (!restProps.addNew) {
            let newData = data.map((item) => ({
                ...item,
                selected:
                    item.result_name_mstr_key === dataItem.result_name_mstr_key
                        ? true
                        : false,
            }));
            setSelectedRowId(dataItem.result_name_mstr_key);
            restProps.setSelectedRowId(dataItem.result_name_mstr_key);
            let Array = [];
            let obj = {};
            obj.id = dataItem.result_name_mstr_key;
            Array.push(obj);
            setData(newData);
            restProps.setDataList(newData);
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        selected_result_name: dataItem.result_name,
                        selected_result_name_key: dataItem.result_name_mstr_key,
                    },
                })
            );
        }
    };

    const enterEdit = (dataItem, field) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false;
        }
        if (!restProps.addNew) {
            Action.getResultTypeList();
            const newData = data.map((item) => ({
                ...item,
                [editField]:
                    item.result_name_mstr_key === dataItem.result_name_mstr_key
                        ? field
                        : undefined,
            }));
            setData(newData);
        }
    };

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            data: [],
            action: "Delete",
        });
    };

    const onDeleteConfirm = async (key) => {
        let data = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_result_name_mstr_key: deleteSelectedIds,
            in_tenant_id: cookiesData.out_tenant_id,
        };

        let res = await Action.deleteDataAsync(data);
        if (res === "success") {
            let selectedRuleResultKey = "";
            deleteSelectedIds.map((item) => {
                if (item.id === restProps.defaultResultNameKey) {
                    selectedRuleResultKey = item.id;
                }
            });
            if (selectedRuleResultKey) {
                dispatch(
                    setResultSettingData({
                        resultSettingData: {
                            ...resultSettingReduxData.resultSettingData,
                            result_name: "",
                            result_name_key: "",
                            selected_result_name: "",
                            selected_result_name_key: "",
                        },
                    })
                );
            }
        }
        setDeleteSelectedIds([]);
        setErrorObj({});
        restProps.setSelectedRowId("");
        restProps.setResultNameEdit(false);
    };

    const onCancelConfirm = (action) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        restProps.setShow({ ...restProps.visible, deleteRows: false });
        data.map((item) => {
            item.selected = false;
            return 0;
        });
        setData([...data]);
        restProps.setbuttonState({
            ...restProps.buttonState,
            isAddDataClick: true,
            isCancelClick: true,
        });
    };

    const handleUpdateValidation = () => {
        let isValid = true;
        data.filter((x) => x.inIteamEdit === true).map((item) => {
            const { result_name, result_group_mstr_key, rowId } = item;
            let errorObj = { result_name: "" };
            if (result_name.trim() === "") {
                errorObj.result_name = "Please enter Result Name";
            } else {
                errorObj.result_name = "";
            }
            if (result_group_mstr_key === "") {
                errorObj.result_group_mstr_key = "Please enter Result Group";
            } else {
                errorObj.result_group_mstr_key = "";
            }
            let obj = {};
            for (const [key, value] of Object.entries(errorObj)) {
                obj[`${key + rowId}`] = `${value}`;
            }
            setErrorObj(obj);
            for (const [, value] of Object.entries(obj)) {
                if (value !== "") {
                    isValid = false;
                }
            }
            return 0;
        });
        return isValid;
    };

    const handleUpdate = async () => {
        let valid = handleUpdateValidation();
        if (valid) {
            let updatedDataList = [];
            data.filter((x) => x.inIteamEdit === true && x.inEdit !== true).map(
                (item) => {
                    let valid = handleUpdateValidation();
                    if (valid) {
                        let obj = {};
                        obj.in_user_id = cookiesData.out_user_id.toString();
                        obj.in_tenant_id = cookiesData.out_tenant_id;
                        obj.in_result_name = item.result_name;
                        obj.in_result_desc = item.result_desc;
                        obj.in_result_name_mstr_key = item.result_name_mstr_key;
                        obj.in_result_type_mstr_key =
                            typeof item.result_type_mstr_key === "string"
                                ? resultTypeFields.resultTypeData.find(
                                      (x) =>
                                          x.text === item.result_type_mstr_key
                                  ).value
                                : item.result_type_mstr_key;
                        obj.in_result_group_mstr_key =
                            typeof item.result_group_mstr_key === "string"
                                ? resultGroupData.resultGroupDataValue.find(
                                      (x) =>
                                          x.text === item.result_group_mstr_key
                                  ).value
                                : item.result_group_mstr_key;
                        obj.in_report_display =
                            typeof item.display_report === "string"
                                ? REPORT_DISPLAY.find(
                                      (x) => x.text === item.display_report
                                  ).value
                                : item.display_report;
                        obj.in_report_order = item.report_order || 0;
                        obj.in_report_hyperlink = item.report_hyperlink;

                        updatedDataList.push(obj);
                        return 0;
                    }
                    return 0;
                }
            );
            const bodyData = {
                updateResult: [...updatedDataList],
            };
            if (updatedDataList.length > 0) {
                let response = await Action.updateDataAsync(bodyData);

                if (response) {
                    onRefreshClick();
                    restProps.setResultNameEdit(false);
                }
            }
        } else {
            const elements = document.getElementsByClassName("k-textbox");
            elements[0].focus();
            // TODO : UNSAVED CHANGES
            // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));

            changeCancelBtnState("enable");
            changeUpdateBtnState("enable");
            changeUpdateBtnState("disable", "dialog-btn_1");
        }
    };

    const publicAssinCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;
            let valueData =
                props.dataItem[props.field] !== ""
                    ? props.dataItem[props.field] === 1
                        ? "true"
                        : "false"
                    : "";

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    // TODO : UNSAVED CHANGES
                    // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
                    changeUpdateBtnState("disable", "dialog-btn_1");

                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value === true ? 1 : 0,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                    className="kpi-field"
                >
                    {isInEdit ? (
                        <Checkbox
                            value={dataItem[props.field]}
                            checked={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                        />
                    ) : (
                        <span title={valueData}>{valueData}</span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            if (restProps.isAddClick) {
                isInEdit = dataItem.inEdit;
            }
            let value = props.field + dataItem.rowId;

            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    // TODO : UNSAVED CHANGES
                    // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
                    changeUpdateBtnState("disable", "dialog-btn_1");
                    props.onChange({
                        dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                    title={dataItem[props.field]}
                >
                    {isInEdit ? (
                        field === "report_order" ? (
                            <NumericTextBox
                                value={dataItem[field] || 0}
                                format={"#"} // no decimal places allowed
                                onChange={(e) => {
                                    handleChange(e, props, value);
                                }}
                                name={`${field}`}
                            />
                        ) : (
                            <Input
                                value={dataItem[field]}
                                onChange={(e) => {
                                    handleChange(e, props, value);
                                }}
                                name={`${field}`}
                            />
                        )
                    ) : (
                        <span>{props.dataItem[props.field || ""]}</span>
                    )}

                    {/*isInEdit ? (
                        <Input
                            type={field === "report_order" ? "number" : ""}
                            // required
                            value={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                        />
                    ) : (
                        <span>{props.dataItem[props.field || ""]}</span>
                    )*/}
                    {/* {!minMaxValidation(props.dataItem[props.field])&& <div className="manage-event"><Error>This Field Is required</Error></div>} */}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const handleColumnMenu = async (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";
            index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                sortNameField =
                    sortKeyField !== "display_report"
                        ? sortKeyField.replace("_mstr_key", "_name")
                        : sortKeyField.replace(
                              "display_report",
                              "display_report_label"
                          );
            }
            const sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            index = obj.findIndex((x) => x === sortOriginField) + 1;
        }

        let filterString = isFilter
            ? getfilterDataString(columnMenuData, columns)
            : filterData.data;

        isFilter = false;

        // filterString =
        //   filterString === " AND ( r.result_type_mstr_key = blank ) "
        //     ? " AND ( r.result_type_mstr_key = 0 ) "
        //     : filterString;
        filterString = replaceBlankAndMerge(filterString, "resultName");

        if (filterString !== "") {
            isFilter = true;
        }

        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        getGridData(dataVal);
    };

    const itemChange = React.useCallback(
        (event) => {
            restProps.setResultNameEdit(true);
            // handleValidation();
            const field = event.field || "";
            let newData = "";

            newData = data.map((item) =>
                item.result_name_mstr_key ===
                event.dataItem.result_name_mstr_key
                    ? { ...item, [field]: event.value, inIteamEdit: true }
                    : item
            );

            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const setPageMenuState = (response) => {
        if (!deleteSelectedIds.length) {
            return false;
        }

        let ids = response.map((el) => el.result_name_mstr_key);

        let selectedKeys = [];
        let tempArray = [];
        let tempArrayData = [];

        Object.keys(selectedState).map((item) => {
            let obj = {};

            obj.id = Number(item);
            selectedKeys.push(item);
            tempArray.push(obj);

            return 0;
        });
        ids.map((item) => {
            if (selectedKeys && selectedKeys.includes(item.toString())) {
                tempArrayData.push(item);
            }
            return 0;
        });

        let strSelectedKeys = "";

        if (tempArrayData.length > 0) {
            strSelectedKeys = parseInt(selectedKeys[0]);
        }
        restProps.setSelectedRowId(strSelectedKeys);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);

        setPage({ skip: pageData.skip, take: pageData.take });

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };

        getGridData(dataVal);
    };
    const getGridData = async (dataVal) => {
        let response = await Action.getResultList(dataVal);
        restProps.setDataList(response);
        if (!restProps.copyDataClick) {
            setPageMenuState(response);
        }
        if (restProps.copyDataClick) {
            restProps.setcopyDataClick(false);
        }

        let selectedRecord = response.find(
            (x) => x.result_name_mstr_key === selectedRowId
        );
        if (restProps.showNameResultPopup) {
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        result_name: "",
                        result_name_key: "",
                        selected_result_name: selectedRecord
                            ? selectedRecord?.result_name
                            : response[0].result_name,
                        selected_result_name_key: selectedRecord
                            ? selectedRecord?.result_name_mstr_key
                            : response[0].result_name_mstr_key,
                    },
                })
            );
            let selectedId = selectedRecord
                ? selectedRecord?.result_name_mstr_key
                : response[0].result_name_mstr_key;
            setDeleteSelectedIds([{ id: selectedId }]);
            setSelectedRowId(selectedId);
            let obj = {
                [selectedId]: true,
            };
            setSelectedState(obj);
        }

        // restProps.setResultNameEdit(false)
        // TODO : UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));

        changeCancelBtnState();
        changeUpdateBtnState();
        changeUpdateBtnState("enable", "dialog-btn_1");

        restProps.setAddnew(false);
        setErrorObj({});
    };

    const onRefreshClick = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        getGridData(dataVal);
    };

    const handleCopyData = () => {
        const dataV = data.filter(
            (x) => x.result_name_mstr_key === selectedRowId
        );
        handleAdd(dataV);
    };

    const handleAdd = (dataV) => {
        let dataItem = dataV[0];
        const bodyData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            // in_result_name: dataItem.result_name,

            in_result_name:
                "Copy of " + dataItem.result_name + "-" + microTime(),

            in_result_desc: dataItem.result_desc,
            in_result_name_mstr_key: dataItem.result_name_mstr_key,
            in_result_type:
                typeof dataItem.result_type_mstr_key === "string"
                    ? resultTypeFields.resultTypeData.find(
                          (x) => x.text === dataItem.result_type_mstr_key
                      ).value
                    : dataItem.result_type_mstr_key,
            in_result_group:
                typeof dataItem.result_group_mstr_key === "string"
                    ? resultGroupData.resultGroupDataValue.find(
                          (x) => x.text === dataItem.result_group_mstr_key
                      ).value
                    : dataItem.result_group_mstr_key,
            in_report_display:
                typeof dataItem.display_report === "string"
                    ? REPORT_DISPLAY.find(
                          (x) => x.text === dataItem.display_report
                      ).value
                    : dataItem.display_report,
            in_report_order: dataItem.report_order || 0,
            in_report_hyperlink: dataItem.report_hyperlink,
        };
        // restProps.setSelectedRowId("");
        restProps.setResultNameEdit(false);

        Action.addDataAsync(bodyData);
        // setSelectedState({});
        // onRefreshClick();
    };

    const DropDownCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;
            let erroeValue = props.field + dataItem.rowId;
            let valueData =
                props.field === "result_group_mstr_key"
                    ? resultGroupData.resultGroupDataValue
                    : props.field === "display_report"
                    ? REPORT_DISPLAY
                    : props.field === "result_type_mstr_key"
                    ? resultTypeFields.resultTypeData
                    : [];
            let dataValue = "";
            if (props && valueData.length > 0) {
                let value = valueData?.find(
                    (x) =>
                        x.value === dataItem[props.field] ||
                        x.text === dataItem[props.field]
                );
                dataValue = value ? value.text : "";
            }
            const handleChange = (e) => {
                if (props.onChange) {
                    // TODO : UNSAVED CHANGES
                    // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));

                    changeUpdateBtnState("enable");
                    changeUpdateBtnState("disable", "dialog-btn_1");

                    props.onChange({
                        dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value.text,
                    });
                }
            };
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <DropDownList
                            // style={{
                            //   width: "250px",
                            // }}
                            onChange={handleChange}
                            value={
                                valueData &&
                                valueData.find((c) => c.text === dataValue)
                            }
                            data={valueData}
                            textField="text"
                            placeholder={"Select"}
                        />
                    ) : (
                        <span>{dataValue.toString()}</span>
                    )}

                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[erroeValue]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            resultTypeFields.resultTypeData,
            resultGroupData.resultGroupDataValue,
            errorObj,
        ]
    );

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={
                        column.title === "selected"
                            ? ""
                            : column.field === "result_group_mstr_key" ||
                              column.field === "display_report" ||
                              column.field === "result_type_mstr_key"
                            ? DropDownCell
                            : column.field === "report_hyperlink"
                            ? publicAssinCell
                            : NameCell
                    }
                    editable={column.field === "Delete" ? false : true}
                    sortable={true}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    let customColumn = useCol(gridColumns);

    const exitEdit = (dataItem, index, dataIndex, e) => {
        // if (dataItem.result_name === "" ||dataItem.result_group_mstr_key === "" || dataItem.result_group_mstr_key === null) {
        //     handleValidation();
        // }
        // let newData = data.map((item) => ({
        //   ...item,
        //   inEdit: undefined
        // }));
        // setData(newData);
    };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={editField}
            exitEdit={exitEdit}
        />
    );
    const customCellRender = (td, props) => {
        return (
            <CellRender
                navigatable={true}
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={editField}
                onRowClick={onRowClick}
                updateActBtn="grid-incell-update"
                cancelActBtn="grid-incell-cancel"
            />
        );
    };

    const setNewSelectedRow = (newState, isReset) => {
        const keys = Object.keys(newState).filter(
            (key) => newState[key] === true
        );

        setSelectedState(newState);

        let tempArray = [];

        restProps.setSelectedRowId(selectedState);

        keys.map((item) => {
            let obj = {};
            obj.id = Number(item);

            // obj.text = item.event_group_name;
            tempArray.push(obj);
            return 0;
        });

        setDeleteSelectedIds(tempArray);
        restProps.setSelectedArray(tempArray);
        if (isReset) {
            originalData.map((item) => {
                item.inEdit = false;
                item.selected =
                    Number(item.result_name_mstr_key) === Number(keys[0]);
                return 0;
            });
            setData(originalData);
            restProps.setDataList(originalData);
        } else {
            restProps.setDataList(data);
        }
    };

    const onSelectionChange = (event) => {
        if (!restProps.addNew) {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            // let newKeys = Object.keys(newSelectedState);
            // let oldKeys = Object.keys(selectedState);
            // const isSame = isSameRow(oldKeys, newKeys);

            // if (unsavedPopup.isUnSavedChange && !isSame) {
            //   dispatch(setUnSavedChangesPopup({
            //     ...unsavedPopup, isVisible: true, callbackFn: () => {
            //       setNewSelectedRow(newSelectedState, true);
            //       setErrorObj({});
            //       changeCancelBtnState();
            //       changeUpdateBtnState();
            //     }
            //   }))
            //   return;
            // }
            setNewSelectedRow(newSelectedState, false);
        }
    };
    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_page_row_offset: 0,
            in_page_rows: dataCount,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };
        let res = await Action.exportDataAsync(dataVal);
        if (res) {
            const exportRes = res.map((item) => ({
                ...item,
                report_hyperlink: item.report_hyperlink === 1,
                display_report: item.display_report_label,
            }));
            setExportData(exportRes);
        }
    };
    const onClearFilter = async () => {
        setAPICallingState(true);
        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getGridData(dataVal);
    };
    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    const prepareColumnString = (colData) => {
        return executeGetColumnOrderInfo(colData);
    };

    const columnReorderData = async (columnData) => {
        const str = prepareColumnString(
            columnData.filter((x) => x.field !== "expand")
        );
        let reqData = {
            in_db_name: "procare_system",
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter_logical_cond: "N",
            in_filter_label_cond: "N",
            in_column_info: str,
        };
        let res = await updateColumnReorderAsync(reqData, dispatch, history);
        if (res) {
            setColumns([]);
            let colDataList = JSON.parse(JSON.stringify(columnData));
            colDataList.forEach((item) => {
                item.hidden = false;
            });
            let colData = colDataList.sort(function (a, b) {
                return a.ariaColumnIndex - b.ariaColumnIndex;
            });
            setColumns(colData);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            chooseResult: () => {
                return selectedRowId;
            },
        }),
        [selectedRowId]
    );

    return (
        <>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 ? (
                <DataTable
                    //gridHeight={"65vh"}
                    isAPICalling={isAPICalling}
                    initialLoading={initialLoading}
                    data={data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    autoScrollIndex={autoScrollIndex}
                    filterable={false}
                    handleColumnMenu={handleColumnMenu}
                    itemChange={itemChange}
                    editField={editField}
                    pageData={pageData}
                    dataCount={dataCount}
                    sort={sort}
                    customColumn={customColumn}
                    columns={gridColumns}
                    DATA_ITEM_KEY={DATA_ITEM_KEY}
                    width={"auto"}
                    setSort={setSort}
                    customPageSizes={USER_GRID_PAGE_SIZES}
                    defaultPageSize={USER_GRID_DEFAULT_PAGE_SIZE}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    setPage={setPage}
                    handlePageChange={handlePageChange}
                    module={"calendar_period"}
                    cellRender={customCellRender}
                    rowRender={customRowRender}
                    onClearFilter={onClearFilter}
                    refreshClick={onRefreshClick}
                    noDataFound={noDataFound}
                    onSelectionChange={onSelectionChange}
                    getExportData={getExportData}
                    setExportData={restProps.setExportDataClick}
                    isExportDataClick={restProps.exportDataClick}
                    fileName={"Export_Result_Names.xlsx"}
                    exportData={exportData}
                    reorderable={true}
                    columnReorderData={columnReorderData}
                />
            ) : (
                <>{loadingPanel}</>
            )}
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
});
export default React.memo(ManageResult);

export const GridButton = (restProps) => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id="grid-incell-update"
            >
                Save
            </Button>
        </>
    );
};
export const GridButtonResultType = (restProps) => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-result-cancel">
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id="grid-result-update"
            >
                Save
            </Button>
        </>
    );
};
