// eslint-disable-next-line no-useless-concat
/* eslint-disable no-loop-func */

import * as React from "react";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getter } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
    GridColumn as Column,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnMenuProps } from "../../../components/customColumnMenu";
import { DialogComponent } from "../../../components/dialog";
import DataTable from "../../../components/dataTable";
import { CellRender, RowRender } from "../../../components/renderers";
import { DELETE_BTN_ACTIONS } from "../../../constants/constants";
import { DEFAULT_SORT } from "../../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../../lib/cookies";
import {
    removeMessage,
    setUnSavedChangesPopup,
} from "../../../redux/actions/setting-actions";
import {
    getfilterDataString,
    changeCancelBtnState,
    changeUpdateBtnState,
    microTime,
    applyFormat,
    formatDateOnlyMoment,
} from "../../../utils/utils";
import useApi from "./service";
import useCustomTableApi from "../service";
import {
    GetCompPlanDataApi,
    GetGroupNameAPI,
    GetPositionDataAPI,
    GetSPECIALTYAPI,
    GetTeamNameApi,
} from "../../../lib/common-service";
import { useHistory } from "react-router-dom";
import { DropDownCell, NodeNameDropdownCell } from "./dropdownCell";
import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { ToggleButton } from "@progress/kendo-react-dateinputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { transformDateTime } from "src/lib/date-time.util";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { LoadingPanel } from "src/components/loadingPanel";
import moment from "moment";

const editFieldTier = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "custom_data_order";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

const BOOLEAN_DRP_DATA = [
    {
        text: "Select All",
        value: "0",
    },
    {
        text: "True",
        value: 1,
    },
    {
        text: "False",
        value: 0,
    },
];
export const TableDataRows = (restProps) => {
    const history = useHistory();
    const effVersionDetails = restProps.versionData.effVersionDetails;
    const tierData = effVersionDetails?.tierData;
    const dataCounts = tierData?.rateTierData?.dataCount || 0;
    const { unsavedPopup } = useSelector(
        unSaveChangesPopupSelector,
        shallowEqual
    );

    const selectedVersion = effVersionDetails.data.filter(
        (x) => x.isSelected === 1
    )[0];

    const dispatch = useDispatch();
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [list, Action] = useApi();
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);

    const [, CustomTableAction] = useCustomTableApi();
    const {
        data,
        columns,
        setData,
        originalData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setAPICallingState,
        initialLoading,
        setRefreshLoading,
        isRefreshLoading,
    } = list;

    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });

    const [pageSize, setPageSize] = React.useState(defaultPageSize);

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });

    const [exportData, setExportData] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState({});
    const [errorObj, setErrorObj] = React.useState({});
    const [gridColumns, setGridColumns] = React.useState([]);
    // const [nodeNameList, setNodeNameList] = React.useState([]);
    const [customTableColumns, setCustomTableColumns] = React.useState([]);
    const [customTableColumnsDropDown, setCustomTableColumnsDropDown] =
        React.useState([]);
    const [isSearchBtnClick, setIsSearchBtnClick] = React.useState(false);

    const apiCallData = {
        ...restProps.tierApiCallData,
        in_custom_table_mstr_key: restProps.versionData.custom_table_mstr_key,
        in_date_version: selectedVersion.dateVersion,
        in_load_type: "data-reload",
        in_init: 1,
        selectedVersion: selectedVersion,
    };

    if (
        document.getElementById(
            "grid-incell-cancel-" + restProps.versionData.custom_table_mstr_key
        )
    ) {
        document.getElementById(
            "grid-incell-cancel-" + restProps.versionData.custom_table_mstr_key
        ).onclick = function (e) {
            changeCancelBtnState(
                "disable",
                "grid-incell-cancel-" +
                    restProps.versionData.custom_table_mstr_key
            );
            changeUpdateBtnState(
                "disable",
                "grid-incell-update-" +
                    restProps.versionData.custom_table_mstr_key
            );
            let tempData = [...originalData];
            tempData.map((item) => {
                item.inEdit = false;
                item.selected = false;
                return 0;
            });
            const freshData = tempData.filter((x) => x.isAdd === true);
            if (freshData.length > 0) {
                tempData.shift();
            }
            setData(tempData);
            setErrorObj({});
            restProps.setDataList(tempData);
            // const dataVal = {
            //   ...apiCallData,
            //   in_filter: getInFilter(),
            //   in_sort_number: getInSortNumber(),
            //   in_sort_order: getInSortOrder(),
            //   in_page_row_offset: pageData.skip,
            //   in_page_rows: pageData.take,
            // };
            // getGridData(dataVal);
            dispatch(
                removeMessage({
                    message: "",
                    type: "Error",
                    show: false,
                })
            );

            restProps.setSelectedRowId("");
            setSelectedState({});
            //disable in-cell update and cancel button
            e.target.disableKendoBtn();
            // TODO : UNSAVED CHANGES
            // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
        };
    }

    if (
        document.getElementById(
            "grid-incell-update-" + restProps.versionData.custom_table_mstr_key
        )
    ) {
        document.getElementById(
            "grid-incell-update-" + restProps.versionData.custom_table_mstr_key
        ).onclick = function (e) {
            handleUpdate();
        };
    }

    React.useEffect(() => {
        if (restProps.changeVersion) {
            setFilterData({
                ...filterData,
                isSort: false,
                isFilter: false,
                data: "",
            });
            restProps.setSelectedRowId("");
            setSelectedState({});
            restProps.setChangeVersion(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.changeVersion]);

    const getNodeNames = async (nodeName, index, dataItem, field) => {
        if (index > -1) {
            let editIndex = index;
            let newData = data.map((item) => ({
                ...item,
                inEdit:
                    item.custom_data_order === dataItem.custom_data_order
                        ? field
                        : undefined,
            }));
            let dataList = newData.slice();
            switch (nodeName) {
                case "position":
                    let dataObject = {
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_filter: null,
                        in_user_key: cookiesData.out_user_id,
                        in_sort_number: 2,
                        in_sort_order: "ASC",
                        in_page_row_offset: 0,
                        in_page_rows: 9999,
                        in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
                        in_user_role_filter: null,
                        in_export_flag: 0,
                        in_is_current_flag: 1,
                    };
                    let resDataObject = await GetPositionDataAPI(
                        dataObject,
                        history,
                        dispatch
                    );
                    if (resDataObject && resDataObject.length) {
                        const tempData = resDataObject
                            .filter((x) => x.position_name !== "")
                            .map((item) => ({
                                text: item.position_name,
                                value: item.org_position_key,
                            }));
                        // setNodeNameList([...tempData]);
                        dataList[editIndex].nodeNameList.data = tempData;
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    } else {
                        dataList[editIndex].nodeNameList.data = [];
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    }
                    break;
                case "team":
                    let reqData = {
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_eff_end_date: selectedVersion.endDate,
                    };
                    let res = await GetTeamNameApi(reqData, history, dispatch);
                    if (res && res.length) {
                        const tempData = res.map((item) => ({
                            text: item.team_name,
                            value: item.team_name,
                        }));
                        // setNodeNameList([...tempData]);
                        dataList[editIndex].nodeNameList.data = tempData;
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    } else {
                        dataList[editIndex].nodeNameList.data = [];
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    }
                    break;
                case "group":
                    let dataValue = {
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_filter: null,
                        in_user_role_filter: null,
                        in_sort_number: 3,
                        in_sort_order: null,
                        in_page_row_offset: 0,
                        in_page_rows: 9999,
                    };
                    let resData = await GetGroupNameAPI(
                        dataValue,
                        history,
                        dispatch
                    );
                    if (resData && resData.length) {
                        const tempData = resData.map((item) => ({
                            text: item.title_name,
                            value: item.org_title_key,
                        }));
                        // setNodeNameList([...tempData]);
                        dataList[editIndex].nodeNameList.data = tempData;
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    } else {
                        dataList[editIndex].nodeNameList.data = [];
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    }
                    break;
                case "specialty":
                    let dataObjValue = {
                        in_user_key: cookiesData.out_user_id,
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_filter: null,
                        in_sort_number: 3,
                        in_sort_order: "DESC",
                        in_page_row_offset: 0,
                        in_page_rows: 9999,
                    };
                    let resDataObjValue = await GetSPECIALTYAPI(
                        dataObjValue,
                        history,
                        dispatch
                    );
                    if (resDataObjValue && resDataObjValue.length) {
                        const tempData = resDataObjValue.map((item) => ({
                            text: item.specialty_name,
                            value: item.specialty_mstr_key,
                        }));
                        // setNodeNameList([...tempData]);
                        dataList[editIndex].nodeNameList.data = tempData;
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    } else {
                        dataList[editIndex].nodeNameList.data = [];
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    }
                    break;
                case "plan":
                    let dataObj = {
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_filter: null,
                        in_sort_number: null,
                        in_sort_order: null,
                        in_user_key: cookiesData.out_user_id,
                        in_multi_sort_order: null,
                    };
                    let resDataObj = await GetCompPlanDataApi(
                        dataObj,
                        history,
                        dispatch
                    );
                    if (resDataObj && resDataObj.length) {
                        const tempData = resDataObj.map((item) => ({
                            text: item.comp_plan_name,
                            value: item.comp_plan_mstr_key,
                        }));
                        // setNodeNameList([...tempData]);
                        dataList[editIndex].nodeNameList.data = tempData;
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    } else {
                        dataList[editIndex].nodeNameList.data = [];
                        dataList[editIndex].nodeNameList.loadingState = true;
                        setData([...dataList]);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    React.useEffect(() => {
        if (customTableColumns.length === 0) {
            getCustomTableData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customTableColumns]);

    const getCustomTableData = async () => {
        let reData = {
            in_filter: null,
            in_custom_table_mstr_key:
                restProps.versionData.custom_table_mstr_key,
            in_sort_number: 1,
            in_sort_order: "DESC",
            in_multi_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: getPageTake(),
        };
        let res = await CustomTableAction.getCustomTableColumns(reData);
        let findIndex = res.findIndex(
            (x) =>
                x.custom_col_is_key === 1 &&
                x.data_type_name.toLowerCase() === "boolean"
        );
        if (findIndex > -1) {
            res[findIndex].valueData = BOOLEAN_DRP_DATA[0];
            setCustomTableColumns([...res]);
            setCustomTableColumnsDropDown([...res]);
        } else {
            setCustomTableColumns([...res]);
            setCustomTableColumnsDropDown([...res]);
        }
    };

    React.useEffect(() => {
        tierData?.rateTierData &&
            Action.getTierPromiseData(
                tierData?.rateTierData,
                tierData?.rateTierColumn,
                dataCounts
            );
        setErrorObj({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedVersion.dateVersion,
        restProps.effVersionLoadingState,
        dataCounts,
    ]);

    React.useEffect(() => {
        if (tierData?.rateTierColumn) {
            restProps.setDataList(data);
            restProps.setColumnsData(tierData?.rateTierColumn);
            setGridColumns(tierData?.rateTierColumn || columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tierData?.rateTierColumn]);

    React.useEffect(() => {
        restProps.setDataList(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (restProps.isAddNewConfig) {
            let promise = new Promise(function (resolve, reject) {
                if (handleValidation()) {
                    if (handleUniqueValueValidation()) {
                        addRow();
                    }
                    restProps.setNewConfig(false);
                    resolve("done");
                }
            });

            promise.then((result) => {
                const elements = document.getElementsByClassName("table-input");
                if (elements.length) {
                    elements[0].focus();
                }
            }, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAddNewConfig]);

    React.useEffect(() => {
        if (list.loadingState.isDeleteData) {
            list.setLoadState({ ...list.loadingState, isDeleteData: false });
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isDeleteData]);

    React.useEffect(() => {
        if (restProps.isReloadData) {
            restProps.setReloadData(false);
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isReloadData]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isShow.deleteRows]);

    React.useEffect(() => {
        if (restProps.isCheckColumn) {
            setGridColumns(restProps.columnsData);
            restProps.setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isCheckColumn]);

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    const addRow = () => {
        const freshKeyLen = data.filter(
            (x) => x.custom_table_data_key === 0
        ).length;

        let filterColumnsData = columns.filter(
            (item) => item.filterable === "1"
        );
        let initFields = {
            custom_table_mstr_key: restProps.versionData.custom_table_mstr_key,
            custom_data_order: microTime(),
            date_version: selectedVersion.dateVersion,
            inIteamEdit: true,
            isAdd: true,
            rowId: data.length + 1,
            nodeNameList: { data: [], loadingState: false },
        };
        filterColumnsData.map((item) => {
            initFields[item.field] = "";
            return 0;
        });
        if (freshKeyLen === 0) {
            let initialData = JSON.parse(JSON.stringify(initFields));
            // TODO : UNSAVED CHANGES
            // dispatch(
            //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
            // );
            setSelectedState({ [initFields.custom_data_order]: true });
            data.unshift(initialData);
            setData([...data]);
            restProps.setDataList(data);
            setDeleteSelectedIds([{ id: initFields.custom_data_order }]);
        }
        enterEdit(initFields, filterColumnsData[0].field);

        updateEnableButtonState();
    };

    const handleValidation = () => {
        let isValid = true;
        let dataList = data.slice();
        let errorObj = {};
        let obj = {};
        dataList
            .filter((x) => x.inIteamEdit === true)
            .map((cItem, i) => {
                customTableColumns
                    .filter(
                        (x) =>
                            x.custom_col_is_key === 1 &&
                            x.data_type_name.toLowerCase() !== "boolean"
                    )
                    .map((item) => {
                        const { rowId } = cItem;
                        if (
                            item.data_type_name.toLowerCase() === "date" ||
                            item.data_type_name.toLowerCase() === "number"
                        ) {
                            if (
                                cItem[item.custom_col_name] === "" ||
                                cItem[item.custom_col_name] === null
                            ) {
                                errorObj[item.custom_col_name] = `${[
                                    item.origin_custom_col_name,
                                ]} is required`;
                                obj[`${item.custom_col_name + rowId}`] = `${[
                                    item.origin_custom_col_name,
                                ]} is required`;
                            }
                        } else {
                            if (
                                cItem[item.custom_col_name] === undefined ||
                                cItem[item.custom_col_name].trim() === "" ||
                                cItem[item.custom_col_name].trim() === null
                            ) {
                                let key = item.custom_col_name + "_msg";
                                if (
                                    restProps.versionData.custom_table_type ===
                                        "H" &&
                                    (item.custom_col_name === "Node" ||
                                        item.custom_col_name === "Node_Name")
                                ) {
                                    if (cItem.Node.toLowerCase() !== "plan") {
                                        if (
                                            item.custom_col_name === "Node_Name"
                                        ) {
                                            if (
                                                cItem["node_key"]?.value ===
                                                    undefined ||
                                                cItem["node_key"]?.value === ""
                                            ) {
                                                errorObj[
                                                    item.custom_col_name
                                                ] = `${[
                                                    item.origin_custom_col_name,
                                                ]} is required`;
                                                cItem[key] = `${[
                                                    item.origin_custom_col_name,
                                                ]} is required`;
                                                obj[
                                                    `${
                                                        item.custom_col_name +
                                                        rowId
                                                    }`
                                                ] = `${[
                                                    item.origin_custom_col_name,
                                                ]} is required`;
                                            }
                                        } else {
                                            errorObj[
                                                item.custom_col_name
                                            ] = `${[
                                                item.origin_custom_col_name,
                                            ]} is required`;
                                            cItem[key] = `${[
                                                item.origin_custom_col_name,
                                            ]} is required`;
                                            obj[
                                                `${
                                                    item.custom_col_name + rowId
                                                }`
                                            ] = `${[
                                                item.origin_custom_col_name,
                                            ]} is required`;
                                        }
                                    }
                                } else {
                                    errorObj[item.custom_col_name] = `${[
                                        item.origin_custom_col_name,
                                    ]} is required`;
                                    cItem[key] = `${[
                                        item.origin_custom_col_name,
                                    ]} is required`;
                                    obj[
                                        `${item.custom_col_name + rowId}`
                                    ] = `${[
                                        item.origin_custom_col_name,
                                    ]} is required`;
                                }
                            }
                        }
                        setErrorObj(obj);
                        for (const [, value] of Object.entries(obj)) {
                            if (value.trim() !== "") {
                                isValid = false;
                            }
                        }
                        return 0;
                    });
                return 0;
            });
        setData([...dataList]);
        return isValid;
    };

    const arraysAreEqual = (array1, array2) => {
        // Check if the lengths are the same
        if (array1.length !== array2.length) {
            return false;
        }

        // Function to compare two objects
        const areObjectsEqual = (obj1, obj2) => {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            // Check if the number of keys is the same
            if (keys1.length !== keys2.length) {
                return false;
            }

            // Check if all keys and values are the same
            for (let key of keys1) {
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }

            return true;
        };

        // Compare each object in the arrays
        for (let i = 0; i < array1.length; i++) {
            if (!areObjectsEqual(array1[i], array2[i])) {
                return false;
            }
        }

        return true;
    };

    const handleUniqueValueValidation = async () => {
        let isValid = true;
        let dataList = [...data];
        let errObj = {};
        let newData = dataList.map(
            ({ Node_Name, custom_table_mstr_key, Node, ...rest }) => {
                return {
                    Node_Name,
                    custom_table_mstr_key,
                    Node,
                    ...rest,
                };
            }
        );
        const resultArray = newData.map((dataItem) => {
            const tempNewArray = customTableColumns
                .filter((customCol) => customCol.custom_col_is_key === 1)
                .map((customCol, i) => {
                    const colName = customCol.custom_col_name;
                    const dataType = customCol.data_type_name.toLowerCase();
                    const dataValue = dataItem[colName];
                    const transformedValue =
                        dataType === "date"
                            ? transformDateTime({
                                  date: dataValue,
                                  format: "MM/DD/YYYY",
                              })
                            : dataType === "string"
                            ? String(dataValue).toLowerCase().trim()
                            : dataType === "boolean"
                            ? dataValue || false
                            : dataValue;
                    return {
                        in_custom_col_mstr_key: customCol.custom_col_mstr_key,
                        in_data_value: transformedValue,
                        in_custom_col_name: colName,
                        in_origin_custom_col_name:
                            customCol.origin_custom_col_name,
                        index: i,
                        dataType: customCol.data_type_name.toLowerCase(),
                    };
                });

            return tempNewArray;
        });
        for (let i = 0; i < resultArray.length; i++) {
            for (let j = i + 1; j < resultArray.length; j++) {
                const array1 = resultArray[i];
                const array2 = resultArray[j];
                if (arraysAreEqual(array1, array2)) {
                    let matchedArray = array1.filter((item1) =>
                        array2.some(
                            (item2) =>
                                item1.in_custom_col_mstr_key ===
                                    item2.in_custom_col_mstr_key &&
                                item1.in_custom_col_name ===
                                    item2.in_custom_col_name &&
                                item1.in_data_value === item2.in_data_value
                        )
                    );
                    let filterArray = [...matchedArray];
                    if (restProps.versionData.custom_table_type === "H") {
                        filterArray = matchedArray.filter(
                            (x) => x.in_origin_custom_col_name !== "Node"
                        );
                    }

                    if (filterArray.length > 0) {
                        let keySet = filterArray
                            .map((item) => {
                                return item.in_origin_custom_col_name;
                            })
                            .toString();
                        if (dataList.find((x) => x.inIteamEdit === true)) {
                            let key = "";
                            dataList
                                .filter((x) => x.inIteamEdit === true)
                                .map((dItem) => {
                                    let findIndex = dataList.findIndex(
                                        (x) => x.rowId === dItem.rowId
                                    );
                                    filterArray.map((item, i) => {
                                        let colName = item.in_custom_col_name;
                                        let transformedValue =
                                            item.dataType === "date"
                                                ? transformDateTime({
                                                      date: dItem[colName],
                                                      format: "MM/DD/YYYY",
                                                  })
                                                : item.dataType === "string"
                                                ? String(dItem[colName])
                                                      .toLowerCase()
                                                      .trim()
                                                : item.dataType === "boolean"
                                                ? dItem[colName] || false
                                                : dItem[colName];
                                        if (
                                            item.in_data_value ===
                                            transformedValue
                                        ) {
                                            let keyColumns = customTableColumns
                                                .filter(
                                                    (x) =>
                                                        x.custom_col_is_key ===
                                                        1
                                                )
                                                .map((c) => {
                                                    return c.custom_col_name;
                                                });
                                            if (
                                                keyColumns.includes(
                                                    dItem.inEdit
                                                )
                                            ) {
                                                if (
                                                    dItem.inEdit ===
                                                    item.in_custom_col_name
                                                ) {
                                                    const tableInputs =
                                                        document.getElementsByClassName(
                                                            "table-input"
                                                        );
                                                    let selectElement = null;
                                                    for (
                                                        let i = 0;
                                                        i < tableInputs.length;
                                                        i++
                                                    ) {
                                                        selectElement =
                                                            tableInputs[
                                                                i
                                                            ].querySelector(
                                                                "select"
                                                            )?.name
                                                                ? tableInputs[
                                                                      i
                                                                  ].querySelector(
                                                                      "select"
                                                                  )?.name
                                                                : tableInputs[
                                                                      i
                                                                  ].querySelector(
                                                                      "input"
                                                                  )?.name;
                                                        if (selectElement) {
                                                            break;
                                                        } else {
                                                            selectElement =
                                                                tableInputs[i]
                                                                    .name;
                                                            break;
                                                        }
                                                    }
                                                    key = `${selectElement}${dItem.rowId}`;
                                                    if (key) {
                                                        isValid = false;
                                                        errObj[
                                                            key
                                                        ] = `All the current value of key field(s) ${keySet.toString()} matches the other row.`;
                                                        dataList[findIndex][
                                                            key
                                                        ] = `All the current value of key field(s) ${keySet.toString()} matches the other row.`;
                                                    }
                                                }
                                            } else {
                                                key = `${item.in_custom_col_name}${dItem.rowId}`;
                                                if (key) {
                                                    isValid = false;
                                                    errObj[
                                                        key
                                                    ] = `All the current value of key field(s) ${keySet.toString()} matches the other row.`;
                                                    dataList[findIndex][
                                                        key
                                                    ] = `All the current value of key field(s) ${keySet.toString()} matches the other row.`;
                                                }
                                            }
                                        } else {
                                            errObj[key] = "";
                                            dataList[findIndex][key] = "";
                                        }
                                        return 0;
                                    });
                                    return 0;
                                });
                        }
                    }
                }
            }
        }
        setData([...dataList]);
        setErrorObj({ ...errObj });
        return isValid;
    };

    const handleUpdate = async () => {
        let valid = await handleValidation();
        if (valid) {
            let uniqueValueValid = await handleUniqueValueValidation();
            if (uniqueValueValid) {
                let updatedDataList = [];
                let dataList = data.filter((x) => x.inIteamEdit === true);

                dataList.map((cItem, i) => {
                    customTableColumns.map((item) => {
                        let obj = {
                            in_user_id: cookiesData.out_user_id.toString(),
                            in_tenant_id: cookiesData.out_tenant_id,
                            in_custom_col_mstr_key: item.custom_col_mstr_key,
                            in_date_version: selectedVersion.dateVersion,
                            in_custom_data_order: cItem.isAdd
                                ? ""
                                : cItem.custom_data_order,
                        };

                        if (item.data_type_name.toLowerCase() === "date") {
                            obj.in_data_value =
                                dataList[i][item.custom_col_name] !==
                                "0000-00-00 00:00:00"
                                    ? transformDateTime({
                                          date: dataList[i][
                                              item.custom_col_name
                                          ],
                                          format: "YYYY-MM-DD",
                                      })
                                    : null;
                        } else if (item.custom_col_name === "Node_Name") {
                            if(dataList[i].Node === 'Team'){
                                obj.in_data_value = dataList[i].nodeNameList.data.length > 0 ?  dataList[i].nodeNameList.data.find(
                                    (x) => x.value === dataList[i]["node_key"].value
                                )?.value :  dataList[i].Node_Name
                            }else if(dataList[i].Node === 'Plan'){
                                obj.in_data_value = dataList[i].nodeNameList.data.length > 0 ?  dataList[i].nodeNameList.data.find(
                                    (x) => x.value === dataList[i]["node_key"].value
                                )?.value :  dataList[i].Node_Name || ""
                            }
                                else{
                                obj.in_data_value = dataList[i].nodeNameList.data.length > 0 ? dataList[i].nodeNameList.data.find(
                                    (x) => x.value === dataList[i]["node_key"].value
                                )?.value : dataList[i].node_key === 0 ?  dataList[i].Node_Name || "" : dataList[i].node_key;
                            }
                            
                        } else if (item.data_type_name.toLowerCase() === "number") {
                            obj.in_data_value = dataList[i][item.custom_col_name] ?? 0; // Default to 0 if null or empty
                        } else {
                            obj.in_data_value =
                                dataList[i][item.custom_col_name];
                        }
                        if (obj.in_data_value === "0000-00-00 00:00:00") {
                            obj.in_data_value = null;
                        }

                        updatedDataList.push(obj);
                        return 0;
                    });
                    return 0;
                });
                const bodyData = {
                    insertUpdateArray: [...updatedDataList],
                };

                if (updatedDataList.length > 0) {
                    setRefreshLoading(true);
                    let response = await Action.addUpdateDataAsync(bodyData);
                    if (response) {
                        setRefreshLoading(false);
                        dispatch(
                            setUnSavedChangesPopup({
                                ...unsavedPopup,
                                isUnSavedChange: false,
                            })
                        );
                        data.map((item) => {
                            item.inEdit = false;
                            return 0;
                        });

                        changeUpdateBtnState(
                            "disable",
                            "grid-incell-update-" +
                                restProps.versionData.custom_table_mstr_key
                        );

                        changeUpdateBtnState(
                            "disable",
                            "grid-incell-cancel-" +
                                restProps.versionData.custom_table_mstr_key
                        );
                        onRefreshClick();
                    }
                }
            } else {
                changeCancelBtnState(
                    "enable",
                    "grid-incell-cancel-" +
                        restProps.versionData.custom_table_mstr_key
                );
                changeUpdateBtnState(
                    "enable",
                    "grid-incell-update-" +
                        restProps.versionData.custom_table_mstr_key
                );
            }
        }
    };

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            data: [],
            action: "Delete",
        });
    };

    const onDeleteConfirm = (event) => {
        let clientDelete = false;
        const deleteArray = deleteSelectedIds.map((obj2) => {
            if (obj2.id > 0) {
                const idx = data.findIndex(
                    (elem) => elem.custom_data_order === Number(obj2.id)
                );

                // let previousTierId = 0;
                // if (idx > 0) {
                //   previousTierId = data[idx - 1].custom_data_order;
                // }
                const obj1 = data[idx];
                if (obj1) {
                    return {
                        id: obj2.id,
                        in_date_version: obj1.date_version,
                    };
                } else {
                    clientDelete = true;
                    return {};
                }
            } else {
                clientDelete = true;
                return {};
            }
        });

        var serverDeleteArray = deleteArray.filter(
            (value) => Object.keys(value).length !== 0
        );

        if (clientDelete === true) {
            originalData.shift();
            setData(originalData);
            setErrorObj({});
            restProps.setDataList(originalData);
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
            });
            restProps.setSelectedRowId("");
            setDeleteSelectedIds([]);
            changeCancelBtnState(
                "disable",
                "grid-incell-cancel-" +
                    restProps.versionData.custom_table_mstr_key
            );
            changeUpdateBtnState(
                "disable",
                "grid-incell-update-" +
                    restProps.versionData.custom_table_mstr_key
            );
        }

        if (serverDeleteArray.length > 0) {
            let deleteData = {
                in_user_id: cookiesData.out_user_id,
                in_tenant_id: cookiesData.out_tenant_id,
                in_custom_data_order: serverDeleteArray,
                in_custom_table_mstr_key:
                    restProps.versionData.custom_table_mstr_key,
            };
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
                type: "",
                actionBtn: [],
                data: {},
                action: "",
            });
            Action.deleteDataAsync(deleteData).then(function (result) {
                setErrorObj({});
                restProps.setRateTableEdit(false);

                if (result === "success") {
                    const dataVal = {
                        ...apiCallData,
                        in_filter: getInFilter(),
                        in_sort_number: getInSortNumber(),
                        in_sort_order: getInSortOrder(),
                        in_page_row_offset: getPageSkip(),
                        in_page_rows: getPageTake(),
                    };
                    restProps.setTierApiCallDataState(dataVal);
                }
            });
        }
    };

    const setNewSelectedRow = (newState, isReset) => {
        setSelectedState(newState);
        let tempArray = [];

        const keys = Object.keys(newState).filter(
            (key) => newState[key] === true
        );

        keys.map((item) => {
            let obj = {};
            obj.id = Number(item);
            // obj.text = item.event_group_name;
            tempArray.push(obj);
            return 0;
        });
        restProps.setSelectedRowId(tempArray[0]?.id);
        setDeleteSelectedIds(tempArray);
        if (isReset) {
            originalData.map((item) => {
                item.inEdit = false;
                item.selected =
                    Number(item.custom_data_order) === Number(keys[0]);
                return 0;
            });
            setData(originalData);
            restProps.setDataList(originalData);
        } else {
            restProps.setDataList(data);
        }
    };

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        // let newKeys = Object.keys(newSelectedState);
        // let oldKeys = Object.keys(selectedState);
        // const isSame = isSameRow(oldKeys, newKeys);
        // if (unsavedPopup.isUnSavedChange && !isSame) {
        //   dispatch(setUnSavedChangesPopup({...unsavedPopup, isVisible: true, callbackFn: () => {
        //     setNewSelectedRow(newSelectedState, true);
        //     setErrorObj({});
        //     changeCancelBtnState(
        //       "disable",
        //       "grid-incell-cancel-" + restProps.versionData.custom_table_mstr_key
        //     );
        //     changeUpdateBtnState(
        //       "disable",
        //       "grid-incell-update-" + restProps.versionData.custom_table_mstr_key
        //     );
        //   }}))

        //   return;
        // }
        setNewSelectedRow(newSelectedState, false);
    };

    const onCancelConfirm = (action) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        data.map((item) => {
            item.selected = false;
            return 0;
        });
        setData([...data]);

        // TODO : UNSAVED CHANGES
        // dispatch(
        //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
        // );
    };

    const onRowClick = (dataItem) => {
        let newData = data.map((item) => ({
            ...item,
            selected:
                item.custom_data_order === dataItem.custom_data_order
                    ? true
                    : false,
        }));
        setData(newData);
        restProps.setSelectedRowId(dataItem.custom_data_order);
        restProps.setDataList(newData);
    };

    const enterEdit = (dataItem, field) => {
        if (
            restProps.pageProps.writeAccess === 0 ||
            restProps.versionData.data_write_access === 0
        ) {
            return false;
        }
        changeCancelBtnState(
            "enable",
            `grid-incell-cancel-${restProps.versionData.custom_table_mstr_key}`
        );
        let newData = data.map((item) => ({
            ...item,
            inEdit:
                item.custom_data_order === dataItem.custom_data_order
                    ? field
                    : undefined,
        }));
        let findIndex = data.findIndex(
            (x) => x.custom_data_order === dataItem.custom_data_order
        );
        let listData = { data: [], loadingState: false };
        if (findIndex > -1) {
            listData.data = data[findIndex].nodeNameList.data;
        }

        if (restProps.versionData.custom_table_type === "H") {
            if (
                field === "Node_Name" &&
                listData?.data &&
                listData?.data.length === 0
            ) {
                // setNodeNameList({ data: [], loadingState: false });
                if (dataItem.Node.toLowerCase() !== "plan") {
                    getNodeNames(
                        dataItem?.Node?.toLowerCase(),
                        findIndex,
                        dataItem,
                        field
                    );
                }
            }
        }
        setData(newData);
    };

    const itemChange = React.useCallback(
        (event) => {
            const field = event.field || "";
            restProps.setRateTableEdit(true);

            let requireMsg = event.field + "_msg";
            let uniqueValueValid =
                field === "Node" &&
                restProps.versionData.custom_table_type === "H"
                    ? "Node_Name" + event.dataItem.rowId
                    : field + event.dataItem.rowId;
            let newData = [];
            if (restProps.versionData.custom_table_type === "S") {
                newData = data.map((item) =>
                    item.custom_data_order === event.dataItem.custom_data_order
                        ? {
                              ...item,
                              [field]: event.value,
                              inIteamEdit: true,
                              custom_table_mstr_key:
                                  restProps.versionData.custom_table_mstr_key,
                              [uniqueValueValid]: "",
                              [requireMsg]: event.dataItem[requireMsg],
                          }
                        : item
                );
            } else {
                newData = data.map((item) =>
                    item.custom_data_order === event.dataItem.custom_data_order
                        ? {
                              ...item,
                              [field]: event.value,
                              inIteamEdit: true,
                              Node_Name:
                                  field === "Node"
                                      ? ""
                                      : field === "Node_Name" ||
                                        field == "node_key"
                                      ? event?.value?.text
                                      : item.Node_Name,
                              [requireMsg]:
                                  field === "Node"
                                      ? event.value !== ""
                                          ? ""
                                          : event.dataItem[requireMsg]
                                      : field === "Node_Name" ||
                                        field == "node_key"
                                      ? event?.value?.text !== ""
                                          ? ""
                                          : event.dataItem[requireMsg]
                                      : event.dataItem[requireMsg],
                              nodeNameList: {
                                  data:
                                      field === "Node" &&
                                      item.Node !== event.value
                                          ? []
                                          : event.dataItem.nodeNameList.data,
                                  loadingState:
                                      field === "Node_Name"
                                          ? true
                                          : field === "Node"
                                          ? false
                                          : event.dataItem.nodeNameList
                                                .loadingState,
                              },
                              custom_table_mstr_key:
                                  restProps.versionData.custom_table_mstr_key,
                              [uniqueValueValid]: "",
                          }
                        : item
                );
            }

            let editItem = event.dataItem;
            editItem[field] = event.value;
            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const setPageMenuState = (response) => {
        if (!deleteSelectedIds.length) {
            return false;
        }

        let ids = response.map((el) => el.custom_data_order);

        let selectedKeys = [];
        let tempArray = [];
        let tempArrayData = [];

        Object.keys(selectedState).map((item) => {
            let obj = {};

            obj.id = Number(item);
            selectedKeys.push(item);
            tempArray.push(obj);

            return 0;
        });

        ids.map((item) => {
            if (selectedKeys.includes(item.toString())) {
                tempArrayData.push(item);
            }
            return 0;
        });

        let strSelectedKeys = "";

        if (tempArrayData.length > 0) {
            strSelectedKeys = parseInt(selectedKeys[0]);
        }

        restProps.setSelectedRowId(strSelectedKeys);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);

        setPage({ skip: pageData.skip, take: pageData.take });

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };

        await getGridEventData(dataVal);
        changeCancelBtnState(
            "disable",
            "grid-incell-cancel-" + restProps.versionData.custom_table_mstr_key
        );
        changeUpdateBtnState(
            "disable",
            "grid-incell-update-" + restProps.versionData.custom_table_mstr_key
        );
        restProps.setSelectedRowId("");
        setSelectedState({});
    };

    const handleColumnMenu = async (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        let sortOriginField = "";

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";

            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                sortNameField = sortKeyField.replace("_mstr_key", "_name");
            }

            sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            let sortObjKeys = obj.filter(
                (key) =>
                    key !== "inIteamEdit" &&
                    key !== "isAdd" &&
                    key !== "nodeNameList" &&
                    key !== "selected" &&
                    key !== "rowId"
            );
            index = sortObjKeys.findIndex((x) => x === sortOriginField) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };
        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        await getGridEventData(dataVal);
        changeCancelBtnState(
            "disable",
            "grid-incell-cancel-" + restProps.versionData.custom_table_mstr_key
        );
        changeUpdateBtnState(
            "disable",
            "grid-incell-update-" + restProps.versionData.custom_table_mstr_key
        );
    };

    const updateEnableButtonState = () => {
        changeCancelBtnState(
            "enable",
            "grid-incell-cancel-" + restProps.versionData.custom_table_mstr_key
        );
        changeUpdateBtnState(
            "enable",
            "grid-incell-update-" + restProps.versionData.custom_table_mstr_key
        );
    };

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    // TODO : UNSAVED CHANGES
                    // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
                    updateEnableButtonState();
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Input
                            value={dataItem[field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            className="table-input"
                            name={`${field}`}
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div
                            role="alert"
                            className="k-form-error k-text-start text-wrap custom-table-data-err-msg"
                        >
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const NumberCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    // TODO : UNSAVED CHANGES
                    // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
                    updateEnableButtonState();
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <NumericTextBox
                            value={
                                dataItem[field]
                                    ? applyFormat(dataItem[field], "")
                                    : dataItem[field]
                            }
                            format={"#.########"} // Allows up to 8 decimal places
                            onChange={(e) => {
                                changeUpdateBtnState(
                                    "enable",
                                    "grid-incell-update-" +
                                        restProps.versionData
                                            .custom_table_mstr_key
                                );
                                handleChange(e, props, value);
                            }}
                            className="table-input"
                            name={`${field}`}
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div
                            role="alert"
                            className="k-form-error k-text-start text-wrap custom-table-data-err-msg"
                        >
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const DateCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    updateEnableButtonState();
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.value,
                    });
                }
            };
            if (dataItem[field] === "0000-00-00 00:00:00") {
                dataItem[field] = null;
            } else {
                dataItem[field] =
                    typeof dataItem[field] === "string"
                        ? dataItem[field].replace("Z", "0")
                        : dataItem[field];
            }

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <DatePicker
                            value={
                                dataItem[field] !== null &&
                                dataItem[field] !== ""
                                    ? new Date(dataItem[field])
                                    : dataItem[field]
                            }
                            onChange={(e) => {
                                changeUpdateBtnState(
                                    "enable",
                                    "grid-incell-update-" +
                                        restProps.versionData
                                            .custom_table_mstr_key
                                );
                                handleChange(e, props, value);
                            }}
                            className="table-input"
                            name={`${field}`}
                            toggleButton={CustomToggleButton}
                            formatPlaceholder={{
                                month: "mm",
                                day: "dd",
                                year: "yyyy",
                            }}
                            format="MM/dd/yyyy"
                        />
                    ) : props.dataItem[props.field] === null ? (
                        ""
                    ) : (
                        <span
                            title={transformDateTime({
                                date: props.dataItem[props.field],
                                format: "MM/DD/YYYY",
                            })}
                        >
                            {transformDateTime({
                                date: props.dataItem[props.field],
                                format: "MM/DD/YYYY",
                            })}
                        </span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div
                            role="alert"
                            className="k-form-error k-text-start text-wrap custom-table-data-err-msg"
                        >
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const BooleanCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    // TODO : UNSAVED CHANGES

                    //dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
                    updateEnableButtonState();
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value ? 1 : 0,
                    });
                }
            };

            let val =
                props.dataItem[props.field] !== ""
                    ? props.dataItem[props.field] === 1
                        ? "true"
                        : "false"
                    : "";
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Checkbox
                            value={dataItem[field]}
                            checked={dataItem[props.field]}
                            onChange={(e) => {
                                changeUpdateBtnState(
                                    "enable",
                                    "grid-incell-update-" +
                                        restProps.versionData
                                            .custom_table_mstr_key
                                );
                                handleChange(e, props, value);
                            }}
                            className="table-input"
                            name={`${field}`}
                        />
                    ) : (
                        <span title={val}>{val}</span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div
                            role="alert"
                            className="k-form-error k-text-start text-wrap custom-table-data-err-msg"
                        >
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column, "custom_table_rows")}
                    key={idx}
                    cell={
                        column.type === "number"
                            ? NumberCell
                            : column.type === "date"
                            ? DateCell
                            : column.type === "boolean"
                            ? BooleanCell
                            : column.field === "Node" &&
                              restProps.versionData.custom_table_type === "H"
                            ? DropDownCell
                            : column.field === "Node_Name" &&
                              restProps.versionData.custom_table_type === "H"
                            ? NodeNameDropdownCell
                            : NameCell
                    }
                    sortable={true}
                    width={column.min_width}
                    format={column.format}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let GroupColumn = useCol(gridColumns);

    const onClearFilter = async () => {
        setAPICallingState(true);
        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getGridEventData(dataVal);
    };

    const getGridEventData = async (dataVal) => {
        setErrorObj({});
        let response = await Action.getListDataAsync(dataVal);
        restProps.setDataRowsCount(response.dataCount);
        if (response.rateTierData) {
            response = response.rateTierData;
        } else {
            return false;
        }
        restProps.setDataList(response);
        setPageMenuState(response);
        setAPICallingState(false);
    };

    const onRefreshClick = async () => {
        setAPICallingState(true);
        setErrorObj({});
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        await getGridEventData(dataVal);
        changeCancelBtnState(
            "disable",
            "grid-incell-cancel-" + restProps.versionData.custom_table_mstr_key
        );
        changeUpdateBtnState(
            "disable",
            "grid-incell-update-" + restProps.versionData.custom_table_mstr_key
        );
    };

    const exitEdit = (dataItem, index, dataIndex, e) => {
        if (dataItem.rate_table_tier_name === "") {
            handleValidation();
        }
        // let newData = data.map((item) => ({
        //   ...item,
        //   inEdit: undefined
        // }));
        // setData(newData);
    };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={editFieldTier}
            exitEdit={exitEdit}
        />
    );

    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_page_row_offset: 0,
            in_page_rows: restProps.dataRowsCount,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        let res = await Action.exportDataAsync(dataVal);
        if (res.length > 0) {
            res.map((cItem, i) => {
                customTableColumns.forEach((item) => {
                    if (item.data_type_name.toLowerCase() === "date") {
                        if (
                            cItem[item.custom_col_name] !==
                            "0000-00-00 00:00:00"
                        ) {
                            cItem[item.custom_col_name] = transformDateTime({
                                date: cItem[item.custom_col_name],
                                format: "MM/DD/YYYY",
                            });
                        } else {
                            cItem[item.custom_col_name] = "";
                        }
                    }
                    if (item.data_type_name.toLowerCase() === "boolean") {
                        cItem[item.custom_col_name] = cItem[
                            item.custom_col_name
                        ]
                            ? "true"
                            : "false";
                    }
                    return 0;
                });
                return 0;
            });
            setExportData(res);
        }
    };

    const customCellRender = (td, props) => {
        return (
            <CellRender
                navigatable={true}
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={editFieldTier}
                onRowClick={onRowClick}
            />
        );
    };

    const CustomToggleButton = (props) => {
        return (
            <Tooltip
                position="bottom"
                anchorElement="target"
                style={{
                    paddingTop: 10,
                }}
            >
                <ToggleButton {...props} title="Calendar">
                    <span
                        className="k-icon k-font-icon k-i-calendar"
                        title="Calendar"
                    />
                </ToggleButton>
            </Tooltip>
        );
    };

    const onSearchBtnClick = async () => {
        let filterCount = 0;
        let filterDataString = "";
        customTableColumnsDropDown
            .filter((x) => x.custom_col_is_key === 1)
            .map((item) => {
                if (item.valueData && typeof item.valueData === "object") {
                    if (item.valueData.text !== "Select All") {
                        filterCount++;
                    }
                } else if (item.valueData && item.valueData?.length > 0) {
                    filterCount++;
                }
            });
        let filterColumnNames = [];
        let filterColumnData = [];
        if (filterCount > 0) {
            let isCheckBoolean = customTableColumnsDropDown.filter(
                (x) =>
                    x.data_type_name.toLowerCase() === "boolean" &&
                    x.custom_col_is_key === 1
            );

            if (isCheckBoolean.length > 0) {
                filterColumnNames = customTableColumnsDropDown
                    .filter((x) => x.valueData)
                    .map((x) => {
                        return x.origin_custom_col_name;
                    });
            } else {
                filterColumnNames = customTableColumnsDropDown
                    .filter((x) => x.valueData && x.valueData.length > 0)
                    .map((x) => {
                        return x.origin_custom_col_name;
                    });
            }
            if (isCheckBoolean.length > 0) {
                let filterColumnDataTemp = customTableColumnsDropDown
                    .filter(
                        (x) =>
                            x.data_type_name.toLowerCase() === "boolean" &&
                            x.custom_col_is_key === 1
                    )
                    .map((x) => {
                        return x.valueData.value;
                    });
                filterColumnData = filterColumnDataTemp;
            } else {
                filterColumnData = customTableColumnsDropDown
                    .filter((x) => x.valueData && x.valueData.length > 0)
                    .flatMap((x) => {
                        return x.valueData.map((item) => item.data_value);
                    });
            }
            const customColName = filterColumnNames
                .map((value) => `"${value}"`)
                .join(", ");
            const formattedValues = filterColumnData
                .map((value) => `"${value}"`)
                .join(", ");

            filterDataString = `AND (a.custom_col_name IN (${customColName})) AND (a.data_value IN (${formattedValues}))`;
        }
        setAPICallingState(true);
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        if (filterColumnNames.length > 0 && filterColumnData.length > 0) {
            dataVal.in_data_filter = filterDataString;
            dataVal.in_data_filter_count = filterCount;
        }
        await Action.getListDataAsync(dataVal);
        setIsSearchBtnClick(true);
    };

    const filterDropdownChangeValue = async (e, item) => {
        let dataIndex = customTableColumnsDropDown.findIndex(
            (x) => x.custom_col_mstr_key === item.custom_col_mstr_key
        );
        let customTableColData = [...customTableColumnsDropDown];
        customTableColData[dataIndex].loading = true;
        customTableColData[dataIndex].filterValue = e.filter.value;
        setCustomTableColumnsDropDown([...customTableColData]);
        let value = e.filter.value;
        if (value) {
            let reqData = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_user_key: cookiesData.out_user_id,
                in_custom_col_mstr_key: item.custom_col_mstr_key,
                in_date_version: selectedVersion.dateVersion,
                in_filter: `AND data_value LIKE '%${value}%'`,
                in_page_row_offset: 0,
                in_page_rows: 9999,
            };
            let res = await Action.getCustomTableColumnsData(reqData);
            if (res) {
                customTableColData[dataIndex].data = res;
                customTableColData[dataIndex].loading = false;
                setCustomTableColumnsDropDown([...customTableColData]);
            }
        } else {
            customTableColData[dataIndex].loading = false;
            setCustomTableColumnsDropDown([...customTableColData]);
        }
    };

    const handleChangeDropdownValue = (e, item) => {
        let dataIndex = customTableColumnsDropDown.findIndex(
            (x) => x.custom_col_mstr_key === item.custom_col_mstr_key
        );
        let customTableColData = [...customTableColumnsDropDown];
        customTableColData[dataIndex].valueData = e.value;
        customTableColData[dataIndex].data =
            item.data_type_name.toLowerCase() === "boolean"
                ? BOOLEAN_DRP_DATA
                : item.data;

        setCustomTableColumnsDropDown([...customTableColData]);
    };

    const handleBlur = (item) => {
        let dataIndex = customTableColumnsDropDown.findIndex(
            (x) => x.custom_col_mstr_key === item.custom_col_mstr_key
        );
        let customTableColData = [...customTableColumnsDropDown];
        customTableColData[dataIndex].filterValue = "";
        customTableColData[dataIndex].data = [];
        setCustomTableColumnsDropDown([...customTableColData]);
    };

    const listNoDataRender = (element) => {
        const noData = (
            <h4
                style={{
                    fontSize: "1em",
                }}
            >
                <br />
                No data found
            </h4>
        );
        return React.cloneElement(element, { ...element.props }, noData);
    };

    const listTextRender = (element) => {
        const noData = (
            <h4
                style={{
                    fontSize: "1em",
                }}
            >
                <br />
                ENTER TEXT FOR MATCHING FILTER VALUES
            </h4>
        );
        return React.cloneElement(element, { ...element.props }, noData);
    };

    return (
        <>
            {isRefreshLoading && <>{loadingPanel}</>}
            {restProps.versionData.data_filter_access === 1 ? (
                <>
                    {customTableColumnsDropDown.length > 0 ? (
                        <>
                            <div className="custom-table-filter-dropdown">
                                {customTableColumnsDropDown
                                    .filter((x) => x.custom_col_is_key === 1)
                                    .map((item, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={
                                                    "k-form-field-wrap-popup valueDropdown width20em"
                                                }
                                            >
                                                <Label>{`${item.origin_custom_col_name}`}</Label>
                                                {item.data_type_name.toLowerCase() ===
                                                "boolean" ? (
                                                    <DropDownList
                                                        data={BOOLEAN_DRP_DATA}
                                                        textField="text"
                                                        dataItemKey="value"
                                                        value={item.valueData}
                                                        placeholder={
                                                            "-- Enter Filter --"
                                                        }
                                                        onChange={(e) => {
                                                            handleChangeDropdownValue(
                                                                e,
                                                                item
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <MultiSelect
                                                        data={item.data}
                                                        filter={
                                                            item.filterValue
                                                        }
                                                        textField="data_value"
                                                        dataItemKey="custom_data_mstr_key"
                                                        filterable
                                                        value={item.valueData}
                                                        onChange={(e) => {
                                                            handleChangeDropdownValue(
                                                                e,
                                                                item
                                                            );
                                                        }}
                                                        filterable={true}
                                                        onFilterChange={(e) => {
                                                            filterDropdownChangeValue(
                                                                e,
                                                                item
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        loading={item.loading}
                                                        placeholder={
                                                            "-- Enter Filter --"
                                                        }
                                                        disabled={false}
                                                        onBlur={() => {
                                                            handleBlur(item);
                                                        }}
                                                        listNoDataRender={
                                                            item.filterValue ===
                                                                "" ||
                                                            item.filterValue ===
                                                                undefined
                                                                ? listTextRender
                                                                : item.loading ===
                                                                      false &&
                                                                  data &&
                                                                  data.length ===
                                                                      0
                                                                ? listNoDataRender
                                                                : listNoDataRender
                                                        }
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                {customTableColumnsDropDown.length > 0 && (
                                    <Button
                                        primary={true}
                                        className="searchbtn"
                                        type={"submit"}
                                        onClick={(e) => {
                                            onSearchBtnClick();
                                        }}
                                    >
                                        <i className="fas fa-search"></i>
                                    </Button>
                                )}
                            </div>

                            {columns &&
                            columns.length > 0 &&
                            !restProps.effVersionLoadingState ? (
                                <DataTable
                                    isAPICalling={isAPICalling}
                                    initialLoading={initialLoading}
                                    data={
                                        isSearchBtnClick
                                            ? data.map((item) => ({
                                                  ...item,
                                                  [SELECTED_FIELD]:
                                                      selectedState[
                                                          idGetter(item)
                                                      ],
                                              }))
                                            : []
                                    }
                                    errorMsg={
                                        isSearchBtnClick === false ? (
                                            <>
                                                <i className="fas fa-question-circle">
                                                    &nbsp;
                                                </i>
                                                Optionally set filters and click
                                                SEARCH button to return data
                                            </>
                                        ) : (
                                            "No Data Found"
                                        )
                                    }
                                    id="custom-table-data-grid"
                                    module={"custom-table-data-grid"}
                                    editField={editFieldTier}
                                    pageData={pageData}
                                    dataCount={restProps.dataRowsCount}
                                    customColumn={GroupColumn}
                                    columns={gridColumns}
                                    dataItemKey={DATA_ITEM_KEY}
                                    // gridHeight={400}
                                    getExportData={getExportData}
                                    setExportData={restProps.setExportDataClick}
                                    isExportDataClick={
                                        restProps.exportDataClick
                                    }
                                    fileName={"Export_custom_table_data.xlsx"}
                                    exportData={exportData}
                                    rowRender={customRowRender}
                                    cellRender={customCellRender}
                                    itemChange={itemChange}
                                    defaultPageSize={defaultPageSize}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    handleColumnMenu={handleColumnMenu}
                                    handlePageChange={handlePageChange}
                                    refreshClick={onRefreshClick}
                                    onClearFilter={onClearFilter}
                                    onSelectionChange={onSelectionChange}
                                    reorderable={false}
                                />
                            ) : (
                                false
                            )}
                        </>
                    ) : (
                        <LoadingPanel />
                    )}
                </>
            ) : (
                <>
                    {columns &&
                    columns.length > 0 &&
                    !restProps.effVersionLoadingState ? (
                        <DataTable
                            isAPICalling={isAPICalling}
                            initialLoading={initialLoading}
                            data={data.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            id="custom-table-data-grid"
                            module={"custom-table-data-grid"}
                            editField={editFieldTier}
                            pageData={pageData}
                            dataCount={restProps.dataRowsCount}
                            customColumn={GroupColumn}
                            columns={gridColumns}
                            dataItemKey={DATA_ITEM_KEY}
                            getExportData={getExportData}
                            setExportData={restProps.setExportDataClick}
                            isExportDataClick={restProps.exportDataClick}
                            fileName={"Export_custom_table_data.xlsx"}
                            exportData={exportData}
                            rowRender={customRowRender}
                            cellRender={customCellRender}
                            itemChange={itemChange}
                            defaultPageSize={defaultPageSize}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            handleColumnMenu={handleColumnMenu}
                            handlePageChange={handlePageChange}
                            refreshClick={onRefreshClick}
                            onClearFilter={onClearFilter}
                            onSelectionChange={onSelectionChange}
                            reorderable={false}
                        />
                    ) : (
                        false
                    )}
                </>
            )}
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};

export const GridButton = (restProps) => {
    const args = restProps.args;

    return (
        <>
            <Button
                type="submit"
                className="cancelbtn"
                id={"grid-incell-cancel-" + args.custom_table_mstr_key}
            >
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id={"grid-incell-update-" + args.custom_table_mstr_key}
            >
                Save
            </Button>
        </>
    );
};
