import { useEffect, useRef, useState } from "react";
import CustomDropdown from "../../../../components/customDropdown";
import { GetUnitType } from "../../../../lib/common-service";
import { Label } from "@progress/kendo-react-labels";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { setResultSettingData } from "../../../../redux/actions/rule-library-actions";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
} from "../../../../utils/utils";
import {
    resultSettingSelector,
    updateRuleSelector,
} from "../../../../redux/selectors/custom-selector";
import { DialogComponent } from "../../../../components/dialog";
import { FormulaMaster } from "../../../../pages/formula-master/formula-master";
import { numericFloatRegex } from "../../../../utils/regex";
import { setUnitTypeData } from "src/redux/actions/setting-actions";

const ResultSetting = (restProps) => {
    const dispatch = useDispatch();
    const formulaRef = useRef();
    const isUpdateRuleClick = useSelector(updateRuleSelector, shallowEqual);
    const resultSettingReduxData = useSelector(
        resultSettingSelector,
        shallowEqual
    );

    const [unitTypeFields, setUnitTypeFields] = useState({
        unitDataValue: {
            system_unit_mstr_key: "",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        unitData: [],
        isUnitTypeLoading: true,
    });
    const [unitList, setUnitDataList] = useState([]);
    const [formFields, setFormFields] = useState({
        result_name: "",
        value_fx: "",
        value: "",
        value_unit_type: "",
        is_enable: false,
    });
    const [openPopup, setOpenPopup] = useState({
        isShowResultName: false,
    });
    useEffect(() => {
        getDataTypeList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resultSettingReduxData?.resultSettingData && unitList.length > 0) {
            setFormFields({
                ...formFields,
                value_fx:
                    resultSettingReduxData?.resultSettingData.formula_mstr_key >
                        0 &&
                    resultSettingReduxData?.resultSettingData.formula_name ===
                        null
                        ? resultSettingReduxData?.resultSettingData.value_fx
                        : resultSettingReduxData?.resultSettingData
                              .formula_name === null
                        ? ""
                        : resultSettingReduxData?.resultSettingData
                              .formula_name,
                value: resultSettingReduxData?.resultSettingData.value,
            });
            setUnitTypeFields({
                ...unitTypeFields,
                isUnitTypeLoading: false,
                unitDataValue:
                    resultSettingReduxData?.resultSettingData.value_unit_type >
                    0
                        ? unitList.find(
                              (x) =>
                                  x.system_unit_mstr_key ===
                                  resultSettingReduxData.resultSettingData
                                      .value_unit_type
                          )
                        : unitList[0],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSettingReduxData?.resultSettingData, unitList]);

    useEffect(() => {
        if (resultSettingReduxData?.resultSettingData) {
            const defaultUnitType =
                unitTypeFields?.unitData?.[0]?.system_unit_mstr_key;

            if (!resultSettingReduxData?.resultSettingData.value_unit_type) {
                resultSettingReduxData.resultSettingData.value_unit_type =
                    defaultUnitType;
            }

            setFormFields({
                ...formFields,
                value_fx:
                    resultSettingReduxData?.resultSettingData.formula_mstr_key >
                        0 &&
                    resultSettingReduxData?.resultSettingData.formula_name ===
                        null
                        ? resultSettingReduxData?.resultSettingData.value_fx
                        : resultSettingReduxData?.resultSettingData
                              .formula_name === null
                        ? ""
                        : resultSettingReduxData?.resultSettingData
                              .formula_name,
                value: resultSettingReduxData?.resultSettingData.value,
                value_unit_type:
                    resultSettingReduxData?.resultSettingData.value_unit_type,
                is_enable:
                    resultSettingReduxData?.resultSettingData.value !== "" &&
                    resultSettingReduxData?.resultSettingData.value !== null
                        ? true
                        : resultSettingReduxData?.resultSettingData.is_enable,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSettingReduxData?.resultSettingData]);

    const getDataTypeList = async () => {
        await getDataTypes();
    };

    /**
     * Get Periods Data Type
     */
    const getDataTypes = async () => {
        let data = {
            in_filter: "AND (a.system_unit_name NOT LIKE 'fdm_%')",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let res = await GetUnitType(data, dispatch);
        if (res.length > 0) {
            setUnitDataList(res);
            dispatch(setUnitTypeData(res));
            setUnitTypeFields({
                ...unitTypeFields,
                unitData: res,
                unitDataValue:
                    resultSettingReduxData?.resultSettingData.value_unit_type >
                    0
                        ? res.find(
                              (x) =>
                                  x.system_unit_mstr_key ===
                                  resultSettingReduxData.resultSettingData
                                      .value_unit_type
                          )
                        : res[0],
                isUnitTypeLoading: false,
            });
        } else {
            setUnitTypeFields({
                ...unitTypeFields,
                isUnitTypeLoading: false,
            });
        }
    };

    const handleCheckBoxChange = (e) => {
        if (e.target.value === true) {
            setFormFields({
                ...formFields,
                is_enable: e.target.value,
                value_fx: "",
                formula_mstr_key: "",
            });
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        value_fx: "",
                        formula_mstr_key: "",
                        formula_name: "",
                        is_enable: true,
                    },
                })
            );
        } else {
            setFormFields({
                ...formFields,
                is_enable: e.target.value,
                value: "",
            });

            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        value: "",
                        is_enable: false,
                    },
                })
            );
        }
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "value") {
            if (numericFloatRegex.test(value)) {
                setFormFields({
                    ...formFields,
                    [name]: value === -0 ? 0 : value,
                });
                dispatch(
                    setResultSettingData({
                        resultSettingData: {
                            ...resultSettingReduxData.resultSettingData,
                            [name]: value === -0 ? 0 : value,
                        },
                    })
                );
                restProps?.onDataChange?.();
                changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
                changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
            } else {
                restProps?.onDataChange?.();
                changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
                changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
                return;
            }
        } else {
            setFormFields({
                ...formFields,
                [name]: value,
            });
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        [name]: value,
                    },
                })
            );
            restProps?.onDataChange?.();
            changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
            changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
        }
    };

    const handleChangeDropdown = (e, name) => {
        setUnitTypeFields({
            ...unitTypeFields,
            [name]: e,
        });
        dispatch(
            setResultSettingData({
                resultSettingData: {
                    ...resultSettingReduxData.resultSettingData,
                    value_unit_type: e.system_unit_mstr_key,
                },
            })
        );
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const onClosePopup = () => {
        setOpenPopup({
            ...openPopup,
            isShowFormulaName: false,
        });
    };

    const FORMULA_MASTER_ACTIONS = [
        {
            name: "Cancel",
            onClick: onClosePopup,
        },
        {
            name: "Ok",
            onClick: () => {
                const selectedFormula = formulaRef.current?.chooseFormula();
                if (!selectedFormula) {
                    alert("Please select filter row");
                    return;
                }
                restProps?.onDataChange?.();

                changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
                changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
                onClosePopup();
            },
        },
    ];

    const handleFormulaClick = () => {
        setOpenPopup({
            ...openPopup,
            isShowFormulaName: true,
        });
        // TODO: UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({isUnSavedChange: false}));
    };
    const onResetFormulaClick = () => {
        setFormFields({
            ...formFields,
            value_fx: "",
            formula_mstr_key: "",
        });
        dispatch(
            setResultSettingData({
                resultSettingData: {
                    ...resultSettingReduxData.resultSettingData,
                    value_fx: "",
                    formula_mstr_key: "",
                    formula_name: "",
                },
            })
        );
        restProps?.onDataChange?.();

        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    return (
        <>
            <div className="inner-title-head">
                <h1>Result Settings</h1>
            </div>
            <div className="inner-title-cont">
                <div className="k-form-field-wrap-popup reseticon">
                    <Label className="innerlabletext" style={{ width: "100%" }}>
                        {"Value Fx*: "}
                    </Label>
                    <Input
                        value={formFields.value_fx}
                        name="value_fx"
                        disabled={
                            restProps.pageProps.writeAccess === 0
                                ? true
                                : formFields.is_enable
                        }
                        onClick={handleFormulaClick}
                        onChange={handleChange}
                        className={
                            formFields.is_enable
                                ? "reseticoninput customDisabled"
                                : "reseticoninput"
                        }
                        required={
                            formFields.is_enable ? false : isUpdateRuleClick
                        }
                    />
                    <span
                        className={
                            restProps.pageProps.writeAccess === 0 ||
                            formFields.is_enable
                                ? "k-icon k-i-x disable-class"
                                : "k-icon k-i-x"
                        }
                        onClick={onResetFormulaClick}
                    />
                </div>

                <div className="k-form-field-wrap-popup">
                    <div className="groupexecution-checkbox">
                        <Checkbox
                            id="value_fx_cb"
                            checked={formFields.is_enable}
                            // label={item.label}
                            className={"k-checkbox-label innerlabletext"}
                            style={{
                                display: "inline-block",
                            }}
                            onChange={handleCheckBoxChange}
                            disabled={
                                restProps.pageProps.writeAccess === 0
                                    ? true
                                    : false
                            }
                        />
                    </div>

                    <div className="enablevaluecont">
                        <Label className="innerlabletext rules-value">
                            {"Value*: "}
                        </Label>
                        <NumericTextBox
                            value={formFields?.value ?? null}
                            format={"#.########"} // Allows up to 8 decimal places
                            name="value"
                            onChange={handleChange}
                            disabled={
                                restProps.pageProps.writeAccess === 0
                                    ? true
                                    : !formFields.is_enable
                            }
                            className="rules-value"
                            required={
                                formFields.is_enable ? isUpdateRuleClick : false
                            }
                        />
                    </div>
                </div>
                <div className="k-form-field-wrap-popup">
                    <Label className="innerlabletext">
                        {"Value Unit Type*: "}
                    </Label>
                    <CustomDropdown
                        name={"unitDataValue"}
                        data={unitTypeFields.unitData}
                        textField="system_unit_name"
                        onOpen={(dataValue) => {
                            setUnitTypeFields({
                                ...unitTypeFields,
                                unitData: dataValue,
                            });
                        }}
                        dataItemKey="system_unit_mstr_key"
                        valueData={unitList?.find(
                            (c) =>
                                c.system_unit_name ===
                                unitTypeFields.unitDataValue.system_unit_name
                        )}
                        onChange={(e, name) => {
                            handleChangeDropdown(e, name);
                        }}
                        filterable={true}
                        onFilterChange={(dataValue) => {
                            setUnitTypeFields({
                                ...unitTypeFields,
                                unitData: dataValue,
                            });
                        }}
                        autoClose={false}
                        autoBind={true}
                        isSelectALl={true}
                        dataList={unitList}
                        loading={unitTypeFields.isUnitTypeLoading}
                        style={{
                            width: "100%",
                        }}
                        disabled={
                            restProps.pageProps.writeAccess === 0 ? true : false
                        }
                    />
                </div>
            </div>
            <DialogComponent
                desc={
                    <>
                        {openPopup.isShowFormulaName ? (
                            <FormulaMaster
                                ref={formulaRef}
                                closePopup={onClosePopup}
                                filterType="valueFx"
                                pageProps={restProps.pageProps}
                            />
                        ) : (
                            false
                        )}
                    </>
                }
                title={
                    openPopup.isShowFormulaName
                        ? "Formula Master"
                        : "Result Name"
                }
                visible={
                    openPopup.isShowResultName || openPopup.isShowFormulaName
                }
                className={
                    openPopup.isShowFormulaName ? "" : "result-name-window"
                }
                primBtnText={"Cancel"}
                width={"95%"}
                height={"95%"}
                actionBtn={FORMULA_MASTER_ACTIONS}
                onPrimButton={onClosePopup}
                onCancelConfirm={onClosePopup}
            />
        </>
    );
};

export default ResultSetting;
