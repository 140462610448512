import ChartWrapper from "src/components/chart/index";
import { LoadingPanel } from "src/components/loadingPanel";
import { dataParser } from "src/lib/widget.util";
import {
    DashboardChartRuleList,
    DashboardCompPlanConfig,
    DashboardDataset,
} from "src/service/query/dashboard-tab-list.query";
import {
    getDashboardPod,
    // mapDashboardComplanData,`
    mapDashboardDataSet,
    mapDashboardPodData,
} from "src/store/dashboard.store";
import { getUserData, mapUserData } from "src/store/user.store";

export const DashboardWidgetWrapperHook = (podDetail) => {
    const { tenantId, userKey, dbRoleTypeAbbr, acctEncryptKey } = mapUserData;
    const activeDashboardPod = getDashboardPod();
    // const { compPlanMstrKeys } = mapDashboardComplanData;
    const {
        tabId,
        dashboardTypeName,
        periodTypeMstrKey,
        reportPublishedFlag,
        dashboardPodKey,
        periodEndDate,
        periodStartDate,
        providerFilter,
        specialtyFilter,
        groupFilter,
        dashboardThemeName,
    } = mapDashboardPodData;
    const { chartType } = mapDashboardDataSet;

    const userData = getUserData();
    //let compPlanAccess = [];
    let renderBody = <LoadingPanel />;
    const DashboardCompPlanConfigData = DashboardCompPlanConfig({
        tenantId: tenantId.getData(userData) || null,
        dashboardTabMstrKey: tabId.getData(activeDashboardPod) || null,
        userKey: userKey.getData(userData) || null,
        dbRoleTypeAbbr: dbRoleTypeAbbr.getData(userData) || null,
    });
    const dashboardDataset = DashboardDataset({
        tenantId: tenantId.getData(userData) || null,
        dashboardPodKey: dashboardPodKey.getData(podDetail) || null,
        userKey: userKey.getData(userData) || null,
    });

    /*
  if (
    !DashboardCompPlanConfigData?.isLoading &&
    DashboardCompPlanConfigData?.data?.length > 0
  ) {
    compPlanAccess = DashboardCompPlanConfigData.data.map((compPlanObj) =>
      compPlanMstrKeys.getData(compPlanObj)
    );
  }
  */

    const removeDuplicatesFromString = (commaSeparatedString) => {
        const array = commaSeparatedString.split(",");
        const uniqueArray = [...new Set(array)];
        const uniqueCommaSeparatedString = uniqueArray.join(",");
        return uniqueCommaSeparatedString;
    };

    const { data, isLoading } = DashboardChartRuleList({
        tenantId: tenantId.getData(userData) || null,
        userKey: userKey.getData(userData) || null,
        ruleResultTable: dashboardTypeName.getData(podDetail) || null,
        periodStartDate: periodStartDate.getData(activeDashboardPod) || null,
        periodEndDate: periodEndDate.getData(activeDashboardPod) || null,
        periodTypeMstrKey: periodTypeMstrKey.getData(podDetail) || null,
        encryptKey: acctEncryptKey.getData(userData) || null,
        groupFilter:
            removeDuplicatesFromString(
                groupFilter.getData(activeDashboardPod)
            ) || null,
        specialtyFilter:
            removeDuplicatesFromString(
                specialtyFilter.getData(activeDashboardPod)
            ) || null,
        providerFilter:
            removeDuplicatesFromString(
                providerFilter.getData(activeDashboardPod)
            ) || null,
        dashboardTabMstrKey: tabId.getData(activeDashboardPod) || null,
        dashboardPodKey: dashboardPodKey.getData(podDetail) || null,
        reportPublishedFlag:
            reportPublishedFlag.getData(activeDashboardPod) || null,
    });

    if (data?.length > 0) {
        const chartTheme = dashboardThemeName.getData(activeDashboardPod);
        const { categoryAxisList, chartData, chartBackgroundColor } =
            dataParser({
                data,
                dataSetData: dashboardDataset?.data,
                chartTheme,
            });

        const chartConfig = {
            chartCategories: categoryAxisList,
            chartBackgroundColor: chartBackgroundColor,
            mapChartType: chartType.getData,
        };

        const isPODLoading =
            DashboardCompPlanConfigData?.isLoading ||
            dashboardDataset?.isLoading;
        const isPODLoadingError =
            DashboardCompPlanConfigData?.isError || dashboardDataset?.isError;
        renderBody = isPODLoading ? (
            <LoadingPanel />
        ) : isPODLoadingError ? (
            <h4 className="center">No Data Found</h4>
        ) : (
            <ChartWrapper chartConfig={chartConfig} data={chartData} />
        );
    } else if (!DashboardCompPlanConfigData?.isLoading && !isLoading) {
        const element = document.querySelector(
            `.pod-key_${podDetail.dashboard_pod_key}`
        );
        element.classList.add("hide-chart");
        renderBody = <h4 className="center">No Data Found</h4>;
    }
    return {
        dashboardData: data,
        dataIsLoading: isLoading,
        renderBody,
    };
};
