// eslint-disable-next-line no-useless-concat
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
    getSelectedState,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input, Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import { CellRender, RowRender } from "../../components/renderers";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { removeMessage } from "../../redux/actions/setting-actions";
import { linkedRulesSelector } from "../../redux/selectors/custom-selector";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import {
    getfilterDataString,
    changeCancelBtnState,
    changeUpdateBtnState,
    debounceForFunc,
    executeGetColumnOrderInfo,
} from "../../utils/utils";
import useApi from "./service";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "kpi_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

export const KpiData = (restProps) => {
    const dispatch = useDispatch();
    // const { unsavedPopup } = useSelector(
    //   unSaveChangesPopupSelector,
    //   shallowEqual
    // );
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const history = useHistory();

    const [list, Action] = useApi();
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const [deleteDataProcessed, setDeleteDataProcessed] = React.useState(true);
    const {
        data,
        columns,
        dataCount,
        setData,
        originalData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setAPICallingState,
        initialLoading,
        isRefreshLoading,
        KPITypeFields,
        periodsTypeFields,
        setLoadingStateSearch,
        loading,
        setColumns,
    } = list;

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
        isrestFilterFor: false,
    });

    const [exportData, setExportData] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState({});
    const [errorObj, setErrorObj] = React.useState({});
    let pageData = {
        skip: 0,
        take: defaultPageSize.recordTake,
    };

    // const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [gridColumns, setGridColumns] = React.useState([]);
    // const [isDirty, setIsDirty] = React.useState(false);
    // const [isDirtyfeildKey, setisDirtyfeildKey] = React.useState("");
    const [filterField, setFilterField] = React.useState({
        logic: "and",
        filters: [],
    });
    const [resetSort, setResetSort] = React.useState(false);

    const [sortField, setSortField] = React.useState([]);
    const { linkedRules } = useSelector(linkedRulesSelector, shallowEqual);

    const apiCallData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_user_role_filter: "> 0",
        in_user_key: cookiesData.out_user_id,
        in_rule_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_load_type: "data-reload",
    };

    if (document.getElementById("grid-incell-cancel")) {
        document.getElementById("grid-incell-cancel").onclick = function (e) {
            if (e.isTrusted) {
                changeCancelBtnState();
                changeUpdateBtnState();
                restProps.setisCancelClick(true);
                restProps.isKpiEdit === true && cancelKpiGridChanges();
                restProps.setCancelKpiElementAwaitStatus(false);
            } else {
                const inputCell = document.activeElement.closest("td");
                if (!inputCell) {
                    return false;
                }
                const cellClass = inputCell.classList[0];

                switch (cellClass) {
                    case "kpi-field":
                        restProps.setCancelKpiElementAwaitStatus(false);
                        cancelKpiGridChanges();
                        break;
                    default:
                        return false;
                }
            }
        };
    }

    if (document.getElementById("grid-incell-update")) {
        document.getElementById("grid-incell-update").onclick = function (e) {
            let valid = handleValidation();
            restProps.isKpiEdit === true && updateKPIGridChanges();
            restProps.isCreditMensurmentsEdit === true &&
                valid &&
                updateMeasurmentsRuleGridChanges();
            restProps.isIncentivesEdit === true &&
                valid &&
                updateIncentivesRuleGridChanges();
            restProps.isCreditAllocationEdit === true &&
                valid &&
                updatecompRuleGridChanges();
            restProps.isPaymentsEdit === true &&
                valid &&
                updatePaymentsRuleGridChanges();
        };
    }

    React.useEffect(() => {
        if (restProps.isRefreshBtnClick) {
            restProps.setRefreshBtnClick(false);
            changeCancelBtnState();
            changeUpdateBtnState();
            restProps.setisCancelClick(true);
            restProps.isKpiEdit === true && cancelKpiGridChanges();

            restProps.setCancelKpiElementAwaitStatus(false);
            // TODO : UNSAVED CHANGES
            // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isRefreshBtnClick]);

    const handleAddData = async () => {
        if (restProps.isAPICall) {
            restProps.setSelectedRowId("");
            setSelectedState({});
            const dataVal = {
                ...apiCallData,
                in_filter: getInFilter(),
                in_sort_number: getInSortNumber(),
                in_sort_order: getInSortOrder(),
            };
            let res = await Action.getListDataLengthAsync(dataVal);

            if (res.length === 0) {
                setResetSort(true);
                // Set filter to false
                setFilterData({
                    ...filterData,
                    data: "",
                    isFilter: false,
                });
                onDeleteRefreshClick();
            } else {
                onAddRefreshClick();
            }
        }
    };

    React.useEffect(() => {
        handleAddData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAPICall]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isShow.deleteRows]);

    // React.useEffect(() => {
    //   if (restProps.isAPICall) {
    //     restProps.setSelectedRowId("");
    //     setSelectedState({});
    //     onAddRefreshClick();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [restProps.isAPICall]);

    React.useEffect(() => {
        if (restProps.isAddNewConfig) {
            restProps.setNewConfig(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAddNewConfig]);

    React.useEffect(() => {
        restProps.setDataList(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (columns.length > 0) {
            restProps.setDataList(data);
            restProps.setColumnsData(columns);
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isShow.deleteRows]);

    React.useEffect(() => {
        if (originalData && originalData.length > 0) {
            let prevSelected = originalData.find(
                (obj) => obj.kpi_mstr_key == restProps.selectedRowId
            );
            setNewSelectedRow(
                prevSelected !== undefined
                    ? { [prevSelected.kpi_mstr_key]: true }
                    : { [originalData[0].kpi_mstr_key]: true }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalData]);

    React.useEffect(() => {
        let filter = {};
        if (linkedRules && linkedRules["kpi"]) {
            const linkedData = linkedRules["kpi"];
            if (linkedData.type === "kpi") {
                if (linkedData.rule_type_mstr_key) {
                    filter.rule_type_mstr_key = linkedData.rule_type_mstr_key;
                }
                if (linkedData.rule_config_key) {
                    filter.rule_config_key = linkedData.rule_config_key;
                }
            }
        }
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
            in_load_type: "grid-reload",
            filter: filter,
        };
        getGridData(dataVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkedRules, deleteDataProcessed]);

    React.useEffect(() => {
        const handleDeleteData = async () => {
            if (list.loadingState.isDeleteData) {
                list.setLoadState({
                    ...list.loadingState,
                    isDeleteData: false,
                });
                const dataVal = {
                    in_tenant_id: cookiesData.out_tenant_id,
                    in_filter: getInFilter(),

                    in_user_role_filter: "> 0",
                    in_user_key: cookiesData.out_user_id,
                    in_rule_filter: null,
                    in_sort_number: getInSortNumber(),
                    in_sort_order: getInSortOrder(),
                    in_page_row_offset: 0,
                    in_page_rows: defaultPageSize.recordTake,
                };
                let res = await Action.getListDataLengthAsync(dataVal);

                if (res.length === 0) {
                    setResetSort(true);
                    // Set filter to false
                    setFilterData({
                        ...filterData,
                        data: "",
                        isFilter: false,
                    });
                    onDeleteRefreshClick();
                } else {
                    onRefreshClick();
                }
            }
        };

        if (list.loadingState.isDeleteData) {
            handleDeleteData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isDeleteData]);

    React.useEffect(() => {
        if (restProps.isCheckColumn) {
            setGridColumns(restProps.columnsData);
            restProps.setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isCheckColumn]);

    const getGridData = async (inParams, isFilter, isSort) => {
        setErrorObj({});
        restProps.setisKpiEdit(false);

        let res = await Action.getListDataAsync(inParams);

        if (res.length > 0 && (isFilter || isSort)) {
            changeCancelBtnState();
            changeUpdateBtnState();
            restProps.setKpiGridRefresh(true);
        }
        await Action.getKpiTypesData();
        await Action.getPeriodTypesData();
    };
    const onRefreshAllData = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        getGridData(dataVal, true);
    };

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const cancelKpiGridChanges = () => {
        dispatch(
            removeMessage({
                message: "",
                type: "Error",
                show: false,
            })
        );
        originalData.map((item) => {
            item.inEdit = false;
            item.selected = false;
            return 0;
        });
        restProps.setisKpiEdit(false);
        setData(originalData);
        setErrorObj({});
        restProps.setDataList(originalData);
    };

    const updatecompRuleGridChanges = () => {
        restProps.setisUpdateClick(true);
    };
    const updateMeasurmentsRuleGridChanges = () => {
        restProps.setIsMeasurmentsUpdateClick(true);
    };
    const updateIncentivesRuleGridChanges = () => {
        restProps.setIsIncentivesUpdateClick(true);
    };
    const updatePaymentsRuleGridChanges = () => {
        restProps.setIsPaymentsUpdateClick(true);
    };
    const handleValidation = () => {
        let isValid = true;

        data.filter((x) => x.inEdit !== undefined).map((item) => {
            const { kpi_name, rowId } = item;

            let errorObj = {
                kpi_name: "",
            };

            if (kpi_name.trim() === "") {
                errorObj.kpi_name = "Please enter kpi name";
            } else {
                errorObj.kpi_name = "";
            }

            let obj = {};

            for (const [key, value] of Object.entries(errorObj)) {
                obj[`${key + rowId}`] = `${value}`;
            }

            setErrorObj(obj);

            for (const [, value] of Object.entries(obj)) {
                if (value !== "") {
                    isValid = false;
                }
            }

            return 0;
        });

        return isValid;
    };
    const updateKPIGridChanges = () => {
        let valid = handleValidation();

        if (valid) {
            updateKPIData();
        } else {
            const elements = document.getElementsByClassName("k-textbox");
            elements[0].focus();

            changeCancelBtnState("enable");
            changeUpdateBtnState("enable");
        }
    };
    const updateKPIData = async () => {
        let valid = handleValidation();

        if (valid) {
            let updatedKPIDataList = [];

            //update plan data
            data.filter((x) => x.inIteamEdit === true).map((item) => {
                let obj = {};
                obj.in_user_id = cookiesData.out_user_id.toString();
                obj.in_tenant_id = cookiesData.out_tenant_id;
                obj.in_kpi_mstr_key = item.kpi_mstr_key;
                obj.in_kpi_name = item.kpi_name;
                obj.in_kpi_desc = item.kpi_desc;
                obj.in_kpi_type_mstr_key = KPITypeFields.KPITypeData.find(
                    (c) => c.text === item.kpi_type_name
                ).id;
                obj.in_is_model = item.is_model;
                obj.in_period_type_mstr_key =
                    periodsTypeFields.periodTypeData.find(
                        (c) => c.text === item.period_type_name
                    ).id;
                obj.in_report_order = item.report_order || 0;

                updatedKPIDataList.push(obj);

                return 0;
            });

            const updateKPIBodyData = {
                updateKPI: [...updatedKPIDataList],
            };
            if (updatedKPIDataList.length > 0) {
                let response = await Action.updateDataAsync(updateKPIBodyData);

                if (response) {
                    // dispatch(
                    //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
                    // );
                    data.map((item) => {
                        item.inEdit = false;
                        return 0;
                    });

                    restProps.setisKpiEdit(false);
                    onRefreshAllData();
                }
            }
        } else {
            cancelKpiGridChanges();
        }
    };

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            data: [],
            action: "Delete",
        });
    };

    const onDeleteConfirm = (event) => {
        const deleteArray = deleteSelectedIds.map((obj2) => {
            const obj1 = data.find(
                (elem) => elem.kpi_mstr_key === obj2.mstr_key
            );
            return {
                mstr_key: obj2.mstr_key,
                kpiName: obj1.kpi_name,
            };
        });
        let deleteData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_kpi_mstr_key: deleteArray,
            in_tenant_id: cookiesData.out_tenant_id,
        };

        Action.deleteDataAsync(deleteData).then(function (result) {
            if (result === "success") {
                setDeleteSelectedIds([]);
                // window.location.reload(false);
            }
        });

        setErrorObj({});
        // restProps.setSelectedRowId("");
        restProps.setisKpiEdit(false);
        restProps.setSelectedTab(0);
    };

    const onCancelConfirm = (action) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        data.map((item) => {
            item.selected = false;
            return 0;
        });
        setData([...data]);
        // if (action === "outSideClick") {
        //   dispatch(
        //     setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
        //   );
        // }
    };

    const onRowClick = (dataItem) => {
        //restProps.setDataList(data);
        if (restProps.isAPICall === true) {
            restProps.setAPICall(false);
            //return false;
        }
        if (originalData.length <= 0 || data.length <= 0) {
            restProps.setSelectedRowId("");
            return false;
        }
        //default select clicked row
        restProps.setSelectedRowId(dataItem.kpi_mstr_key);
        // if (!isRowSelected()) {
        //   let newSelectedState = {};
        //   let newData = data.map((item) => ({
        //     ...item,
        //     selected: item.job_mstr_key === dataItem.job_mstr_key ? true : false,
        //   }));
        //   newSelectedState[dataItem.job_mstr_key] = true;
        //   setData(newData);
        //   setSelectedState(newSelectedState);
        //   setDeleteSelectedIds([{ mstr_key: dataItem.job_mstr_key }]);
        // } else {
        //   const newSelectedStateKeys = Object.keys(selectedState);
        //   const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
        //     return selectedState[x] === true;
        //   });
        //   //default selected latest row from all selection
        //   !selectedKeyArr.includes(dataItem.job_mstr_key.toString()) &&
        //     restProps.setSelectedRowId(selectedKeyArr[selectedKeyArr.length - 1]);
        // }
    };
    const setNewSelectedRow = (newState, isReset) => {
        const keys = Object.keys(newState).filter(
            (key) => newState[key] === true
        );

        setSelectedState(newState);
        let tempArray = [];
        keys.map((item) => {
            let obj = {};
            obj.mstr_key = Number(item);
            tempArray.push(obj);
            return 0;
        });
        setDeleteSelectedIds(tempArray);

        const selectedKeyArr = keys.filter(function (x) {
            return newState[x] !== false;
        });

        if (isReset) {
            originalData.map((item) => {
                item.inEdit = false;
                item.selected = selectedKeyArr.includes(
                    item.kpi_mstr_key.toString()
                );
                return 0;
            });
            const item = originalData.find((it) => it.selected === true);
            setData(originalData);
            restProps.setDataList(originalData);
            debounceForFunc(() => onRowClick(item), 100);
        } else {
            let newData = data.map((item) => ({
                ...item,
                selected: selectedKeyArr.includes(item.kpi_mstr_key.toString()),
            }));
            const item = newData.find((it) => it.selected === true);
            setData(newData);
            restProps.setDataList(newData);
            debounceForFunc(() => onRowClick(item), 100);
        }
    };

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        // let newKeys = Object.keys(newSelectedState);
        // let oldKeys = Object.keys(selectedState);
        // const isSame = isSameRow(oldKeys, newKeys);

        // if (unsavedPopup.isUnSavedChange && !isSame) {
        //   dispatch(
        //     setUnSavedChangesPopup({
        //       ...unsavedPopup,
        //       isVisible: true,
        //       callbackFn: () => {
        //         setNewSelectedRow(newSelectedState, true);
        //         setErrorObj({});
        //         changeUpdateBtnState("disable");
        //         changeCancelBtnState("disable");
        //       },
        //     })
        //   );
        //   return;
        // }

        setNewSelectedRow(newSelectedState, false);
    };

    const enterEdit = (dataItem, field) => {
        Action.getKpiTypesData();
        if (restProps.pageProps.writeAccess === 0) {
            return false;
        }

        let newData = data.map((item) => ({
            ...item,
            inEdit:
                item.kpi_mstr_key === dataItem.kpi_mstr_key ? field : undefined,
        }));

        setData(newData);
        restProps.setisKpiEdit(true);
    };

    const exitEdit = (dataItem, index, dataIndex, e) => {
        if (dataItem.kpi_name === "") {
            handleValidation();
        }
    };

    const itemChange = React.useCallback(
        (event) => {
            const field = event.field || "";
            const newData = data.map((item) =>
                item.kpi_mstr_key === event.dataItem.kpi_mstr_key
                    ? { ...item, [field]: event.value, inIteamEdit: true }
                    : item
            );
            let editItem = event.dataItem;
            editItem[field] = event.value;
            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
        if (isSort) {
            setSortField(columnMenuData);
        } else {
            setFilterField(filterObj);
        }
        let index = 1;

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";

            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                sortNameField = sortKeyField.replace("_mstr_key", "_name");
            }

            const sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            index = obj.findIndex((x) => x === sortOriginField) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);
        getGridData(dataVal, isFilter, isSort);
    };

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={
                        column.field === "kpi_type_mstr_key" ||
                        column.field === "period_type_mstr_key"
                            ? DropDownCell
                            : column.field === "is_model"
                            ? checkboxCell
                            : NameCell
                    }
                    sortable={true}
                    width={"180px"}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const checkboxCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let valueData =
                props.dataItem[props.field] !== ""
                    ? props.dataItem[props.field] === 1
                        ? "true"
                        : "false"
                    : "";
            let value = props.field + dataItem.rowId;

            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value === true ? 1 : 0,
                    });
                }
            };
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                    className="kpi-field"
                >
                    {isInEdit ? (
                        <Checkbox
                            value={dataItem[props.field]}
                            checked={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                        />
                    ) : (
                        <span title={valueData}>{valueData}</span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;
            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };
            // const className =
            //   isDirty && isDirtyfeildKey == dataItem.kpi_mstr_key
            //     ? "kpi-field k-dirty-cell"
            //     : "kpi-field";
            // const classNameSpan =
            //   isDirty && isDirtyfeildKey == dataItem.kpi_mstr_key ? "k-dirty" : "";
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        field === "report_order" ? (
                            <NumericTextBox
                                value={dataItem[field] || 0}
                                format={"#"} // no decimal places allowed
                                onChange={(e) => {
                                    handleChange(e, props, value);
                                }}
                                className="table-input"
                                name={`${field}`}
                            />
                        ) : (
                            <Input
                                value={dataItem[field]}
                                onChange={(e) => {
                                    handleChange(e, props, value);
                                }}
                                name={`${field}`}
                                autoComplete="off"
                                type={"text"}
                            />
                        )
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}

                    {/*isInEdit ? (
                        <Input
                            // required
                            value={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                            type={
                                props.field === "report_order"
                                    ? "number"
                                    : "text"
                            }
                        />
                    ) : (
                        <span
                            title={props.dataItem[props.field || ""]}
                            // className={classNameSpan}
                        >
                            {props.dataItem[props.field || ""]}
                        </span>
                    )*/}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const DropDownCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let value = dataItem[field || ""];
            let valueData = "";
            switch (field) {
                case "kpi_type_mstr_key":
                    valueData = KPITypeFields.KPITypeData;
                    break;
                case "period_type_mstr_key":
                    valueData = periodsTypeFields.periodTypeData;
                    break;
                default:
                    break;
            }

            if (field.indexOf("_mstr_key") !== -1) {
                value = dataItem[field.replace("_mstr_key", "_name") || ""];
            }

            let isInEdit = field === dataItem.inEdit;
            const onChange = (e) => {
                if (props.onChange) {
                    changeUpdateBtnState("enable");

                    props.onChange({
                        dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field.replace("_mstr_key", "_name"),
                        syntheticEvent: e.syntheticEvent,
                        value:
                            field === "is_model"
                                ? e.target.value.value
                                : e.target.value.text,
                    });
                }
            };
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <DropDownList
                            style={{
                                width: "150px",
                            }}
                            onChange={onChange}
                            value={
                                valueData &&
                                valueData.find((c) => c.text === value)
                            }
                            data={valueData}
                            textField="text"
                            placeholder={"Select"}
                        />
                    ) : (
                        <span title={value}>{value}</span>
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [KPITypeFields.KPITypeData, periodsTypeFields.periodTypeData]
    );

    let GroupColumn = useCol(gridColumns);

    const onClearFilter = () => {
        setAPICallingState(true);
        setFilterField({ logic: "and", filters: [] });
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
            isAdd: false,
        });
        setAPICallingState(true);

        //set in_params
        const dataVal = {
            ...apiCallData,
            data: "",
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
            isAdd: false,
        };

        //set filter to false
        getGridData(dataVal, true);
    };
    const onDeleteRefreshClick = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: DEFAULT_SORT.number,
            sort_order: DEFAULT_SORT.order,
            in_load_type: "grid-reload",
        };

        setResetSort(false);

        getGridData(dataVal);
    };

    // const onRefreshClick = () => {
    const onRefreshClick = () => {
        setAPICallingState(true);
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        getGridData(dataVal);
    };

    const onAddRefreshClick = async () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        getGridData(dataVal);
    };
    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={editField}
            exitEdit={exitEdit}
        />
    );

    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        let res = await Action.exportDataAsync(dataVal);

        if (res) {
            const exportRes = res.map((item) => ({
                ...item,
                is_model: item.is_model === 1,
                kpi_type_mstr_key: KPITypeFields.KPITypeData.find(
                    (c) => c.id === item.kpi_type_mstr_key
                ).text,
                period_type_mstr_key: periodsTypeFields.periodTypeData.find(
                    (c) => c.id === item.period_type_mstr_key
                ).text,
            }));
            setExportData(exportRes);
        }
    };

    const customCellRender = (td, props) => {
        return (
            <CellRender
                navigatable={true}
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={editField}
                onRowClick={onRowClick}
                updateActBtn="grid-incell-update"
                cancelActBtn="grid-incell-cancel"
            />
        );
    };

    const prepareColumnString = (colData) => {
        return executeGetColumnOrderInfo(colData);
    };

    const columnReorderData = async (columnData) => {
        const str = prepareColumnString(columnData);

        setLoadingStateSearch({ ...loading, updateColumn: true });

        let reqData = {
            in_db_name: "procare_system",

            in_tenant_id: cookiesData.out_tenant_id,

            in_filter_logical_cond: "N",

            in_filter_label_cond: "N",

            in_column_info: str,
        };

        let res = await updateColumnReorderAsync(reqData, dispatch, history);

        if (res) {
            //  setColumns([]);
            setLoadingStateSearch({ ...loading, updateColumn: false });
            let colDataList = JSON.parse(JSON.stringify(columnData));

            colDataList.forEach((item) => {
                item.hidden = false;
            });

            let colData = colDataList.sort(function (a, b) {
                return a.ariaColumnIndex - b.ariaColumnIndex;
            });
            restProps.setColumnsData(colData);
        } else {
            setLoadingStateSearch({ ...loading, updateColumn: false });
        }
    };

    return (
        <>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 ? (
                <DataTable
                    isAPICalling={isAPICalling}
                    initialLoading={initialLoading}
                    data={data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    resetSort={resetSort}
                    id="kpi-grid"
                    editField={editField}
                    dataCount={dataCount}
                    customColumn={GroupColumn}
                    columns={gridColumns}
                    dataItemKey={DATA_ITEM_KEY}
                    width={"auto"}
                    module={"comp_plan"}
                    getExportData={getExportData}
                    setExportData={restProps.setExportDataClick}
                    isExportDataClick={restProps.exportDataClick}
                    fileName={"Export_kpi.xlsx"}
                    exportData={exportData}
                    rowRender={customRowRender}
                    cellRender={customCellRender}
                    itemChange={itemChange}
                    isPagination={false}
                    handleColumnMenu={handleColumnMenu}
                    onClearFilter={onClearFilter}
                    onSelectionChange={onSelectionChange}
                    reorderable={
                        restProps.pageProps.writeAccess === 0 ? false : true
                    }
                    columnReorderData={columnReorderData}
                    initialFilter={filterField}
                    initialSort={sortField}
                />
            ) : (
                <>{loadingPanel}</>
            )}
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};

export const GridButton = (restProps) => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id="grid-incell-update"
            >
                Save
            </Button>
        </>
    );
};
export const GridButtonKpiType = (restProps) => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-kpi-cancel">
                Cancel
            </Button>
            <Button type="submit" className="primarybtn" id="grid-kpi-update">
                Save
            </Button>
        </>
    );
};
